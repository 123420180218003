const Cloud = () => (
  <>
    <g>
      <g data-name="Group 29891" transform="translate(6 11.735)">
        <g data-name="Group 29867" transform="translate(0)">
          <circle
            data-name="Ellipse 364"
            cx="26.853"
            cy="26.853"
            r="26.853"
            transform="translate(81.586 5.967)"
          />
          <circle
            data-name="Ellipse 365"
            cx="7.553"
            cy="7.553"
            r="7.553"
            transform="translate(72.262 97.157)"
          />
          <circle
            data-name="Ellipse 366"
            cx="7.553"
            cy="7.553"
            r="7.553"
            transform="translate(42.052 128.207)"
          />
          <circle
            data-name="Ellipse 367"
            cx="7.553"
            cy="7.553"
            r="7.553"
            transform="translate(1.678 151.703)"
          />
          <circle
            data-name="Ellipse 368"
            cx="26.853"
            cy="26.853"
            r="26.853"
            transform="translate(105.456)"
          />
          <circle
            data-name="Ellipse 369"
            cx="7.553"
            cy="7.553"
            r="7.553"
            transform="translate(78.975 95.479)"
          />
          <circle
            data-name="Ellipse 370"
            cx="7.553"
            cy="7.553"
            r="7.553"
            transform="translate(48.765 126.528)"
          />
          <circle
            data-name="Ellipse 371"
            cx="7.553"
            cy="7.553"
            r="7.553"
            transform="translate(8.392 150.025)"
          />
          <circle
            data-name="Ellipse 372"
            cx="26.853"
            cy="26.853"
            r="26.853"
            transform="translate(135.293 5.967)"
          />
          <circle
            data-name="Ellipse 373"
            cx="7.553"
            cy="7.553"
            r="7.553"
            transform="translate(87.367 97.157)"
          />
          <circle
            data-name="Ellipse 374"
            cx="7.553"
            cy="7.553"
            r="7.553"
            transform="translate(57.157 128.207)"
          />
          <circle
            data-name="Ellipse 375"
            cx="7.553"
            cy="7.553"
            r="7.553"
            transform="translate(16.783 151.703)"
          />
          <circle
            data-name="Ellipse 376"
            cx="26.853"
            cy="26.853"
            r="26.853"
            transform="translate(135.293 29.837)"
          />
          <circle
            data-name="Ellipse 377"
            cx="7.553"
            cy="7.553"
            r="7.553"
            transform="translate(87.367 103.871)"
          />
          <circle
            data-name="Ellipse 378"
            cx="7.553"
            cy="7.553"
            r="7.553"
            transform="translate(57.157 134.92)"
          />
          <circle
            data-name="Ellipse 379"
            cx="7.553"
            cy="7.553"
            r="7.553"
            transform="translate(16.783 158.417)"
          />
          <circle
            data-name="Ellipse 380"
            cx="26.853"
            cy="26.853"
            r="26.853"
            transform="translate(99.488 41.772)"
          />
          <circle
            data-name="Ellipse 381"
            cx="7.553"
            cy="7.553"
            r="7.553"
            transform="translate(77.297 107.227)"
          />
          <circle
            data-name="Ellipse 382"
            cx="7.553"
            cy="7.553"
            r="7.553"
            transform="translate(47.087 138.277)"
          />
          <circle
            data-name="Ellipse 383"
            cx="7.553"
            cy="7.553"
            r="7.553"
            transform="translate(6.713 161.773)"
          />
          <circle
            data-name="Ellipse 384"
            cx="26.853"
            cy="26.853"
            r="26.853"
            transform="translate(75.619 32.821)"
          />
          <circle
            data-name="Ellipse 385"
            cx="7.553"
            cy="7.553"
            r="7.553"
            transform="translate(70.584 104.71)"
          />
          <circle
            data-name="Ellipse 386"
            cx="7.553"
            cy="7.553"
            r="7.553"
            transform="translate(40.373 135.759)"
          />
          <circle
            data-name="Ellipse 387"
            cx="7.553"
            cy="7.553"
            r="7.553"
            transform="translate(0 159.256)"
          />
        </g>
      </g>
    </g>
  </>
);

export default Cloud;
