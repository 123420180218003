import classNames from "classnames";

import { FutureCard } from "@/ui";

import CardHeader from "../Card/CardHeader";
import CardSummary from "../Card/CardSummary";
import CardContent from "../Card/CardContent";

import useStyles from "./styles";

const CardUser = ({
  title = "",
  titleProps = {},
  description = "",
  userProfile = {},
  children = [],
  className = "",
  classes: rootClasses,
}) => {
  const classes = useStyles({ classes: rootClasses });

  return (
    <FutureCard className={classNames(className, classes.root)}>
      <CardHeader
        userProfile={userProfile}
        classes={{
          header: classes.header,
          userWrapper: classes.userWrapper,
          userLink: classes.userLink,
          userName: classes.userName,
          avatar: classes.avatar,
        }}
      />

      <div className={classes.container}>
        <CardSummary
          title={title}
          titleProps={titleProps}
          description={description}
          classes={{
            summary: classes.summary,
            title: classes.title,
            description: classes.description,
          }}
        />

        <CardContent className={classes.content}>{children}</CardContent>
      </div>
    </FutureCard>
  );
};

export default CardUser;
