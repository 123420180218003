import { FutureCard } from "@/ui";

import CardContent from "../Card/CardContent";

import useStyles from "./styles";

const CardBox = ({
  className = "",
  children,
  classes: rootClasses,
  ...props
}) => {
  const classes = useStyles({ classes: rootClasses });

  return (
    <FutureCard className={`${classes.root} ${className}`} {...props}>
      <CardContent className={classes.content}>{children}</CardContent>
    </FutureCard>
  );
};

export default CardBox;
