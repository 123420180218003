const SquareChecked = () => (
  <>
    <g>
      <g transform="translate(3.287 3.287)" fill="none">
        <rect width="184.713" height="184.713" />
        <rect x="1" y="1" width="182.713" height="182.713" strokeWidth="15" />
      </g>
      <path
        data-name="Path 17540"
        d="M166.849,0,168.3,23.341c-54.454,40.428-82.322,83.775-108.822,136.81C40.735,139.424,16.506,126.705,0,116.195c4.4-5.049,8.778-9.9,13.273-14.738,17.875,12.01,32.766,25.155,45.945,41.571C83.828,93.543,124.668,33.571,166.849,0Z"
        transform="translate(31.893 0)"
        strokeWidth="5"
      />
    </g>
  </>
);

export default SquareChecked;
