import { useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import Input from "./Input";
import { loadGoogleMaps } from "../../lib/maps";

function LocationInput({
  locationType,
  onLocationSelect,
  forceValid, // allow only a valid google place in the input
  onError,
  defaultValue,
  ...inputProps
}) {
  const inputRef = useRef(null);
  const autoComplete = useRef(null);

  useEffect(() => {
    inputRef.current.value = defaultValue;
  }, [defaultValue]);

  // const handleBlur = useCallback(
  //   (event) => {
  //     if (forceValid) {
  //       const value = inputRef?.current?.value;
  //       if (!value) {
  //         onError(); // trigger error if left empty
  //       } else if (selected !== value) {
  //         inputRef.current.value = ""; // reset value
  //         onError(); // trigger error if doesn't match last selected google place
  //       }
  //       onLocationSelect(inputRef.current);
  //     }
  //     if (inputProps.onBlur) {
  //       inputProps.onBlur(event);
  //     }
  //   },
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [forceValid, selected]
  // );

  const onMapLoaded = useCallback(() => {
    autoComplete.current = new google.maps.places.Autocomplete(
      inputRef.current,
      {
        locationTypes: Array.isArray(locationType)
          ? locationType
          : [locationType],
        fields: ["formatted_address"],
      }
    );
    autoComplete.current.addListener("place_changed", () => {
      const place = autoComplete.current.getPlace();
      // keep track of selected location
      if (forceValid && !place?.formatted_address) {
        onError();
        return;
      }
      onLocationSelect(inputRef.current);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onLocationSelect]);

  useEffect(() => {
    loadGoogleMaps(onMapLoaded);
    return () => {
      google.maps.event.clearInstanceListeners(autoComplete.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Input
      autoComplete="off"
      inputRef={inputRef}
      // onBlur={handleBlur}
      defaultValue={defaultValue}
      {...inputProps}
    />
  );
}

LocationInput.defaultProps = {
  onLocationSelect: () => {},
  onError: () => {},
  forceValid: false,
};

LocationInput.propTypes = {
  onLocationSelect: PropTypes.func,
  onError: PropTypes.func,
  forceValid: PropTypes.bool,
  locationType: PropTypes.oneOfType([PropTypes.array, PropTypes.string])
    .isRequired,
};

export default LocationInput;
