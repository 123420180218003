const Instagram = () => (
  <>
    <g>
      <path
        d="M123.575,52.517c-6.15-.283-8-.333-23.575-.333s-17.417.058-23.567.333c-15.825.725-23.192,8.217-23.917,23.917-.275,6.15-.342,7.992-.342,23.567s.067,17.417.342,23.575c.725,15.658,8.058,23.192,23.917,23.917,6.142.275,7.992.342,23.567.342s17.425-.058,23.575-.342c15.825-.717,23.183-8.233,23.917-23.917.275-6.15.333-8,.333-23.575s-.058-17.417-.333-23.567c-.733-15.692-8.108-23.192-23.917-23.917ZM100,129.958A29.954,29.954,0,1,1,129.958,100,29.958,29.958,0,0,1,100,129.958Zm31.142-54.092a7,7,0,1,1,7-7A7,7,0,0,1,131.142,75.867ZM119.442,100A19.442,19.442,0,1,1,100,80.558,19.441,19.441,0,0,1,119.442,100ZM100,0A100,100,0,1,0,200,100,100,100,0,0,0,100,0Zm57.983,124.05c-.958,21.208-12.767,32.958-33.925,33.933-6.225.283-8.217.35-24.058.35s-17.825-.067-24.05-.35c-21.2-.975-32.958-12.742-33.933-33.933-.283-6.217-.35-8.208-.35-24.05s.067-17.825.35-24.05c.975-21.2,12.742-32.958,33.933-33.925,6.225-.292,8.208-.358,24.05-.358s17.833.067,24.058.358c21.208.975,32.975,12.767,33.925,33.925.283,6.225.35,8.208.35,24.05S158.267,117.833,157.983,124.05Z"
        transform="translate(0)"
      />
    </g>
  </>
);

export default Instagram;
