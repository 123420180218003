const styles = (theme) => ({
  root: {},

  gridList: {
    overflowY: "unset",
    width: "100%",
  },

  gridListItemRoot: {
    marginBottom: "7em",
  },

  gridListItem: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    overflow: "unset",

    "&:hover $btnBox": {
      opacity: 1,
    },
  },

  figure: {
    backgroundColor: theme.palette.grey[200],
    backgroundPosition: "50% 50%",
    backgroundSize: "cover",
    height: "100%",
    margin: 0,
  },

  image: {
    opacity: 0,
    position: "absolute",
    top: 0,
    zIndex: -10,
  },

  videoPlayIcon: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-35px, -35px)",
    opacity: 0.7,

    "& svg": {
      width: 70,
      height: 70,
      fill: `${theme.palette.common.black}66`,
      transform: "scale(0.6)",
    },
  },

  figCaption: {
    ...theme.typography.caption,
    bottom: 0,
    display: "none",
    left: 0,
    position: "absolute",
    width: "100%",
  },

  // TODO: install extend jss package for .editMode, . figCaption, .formControl
  editMode: {
    alignItems: "flex-start",
    background: `linear-gradient(
      to bottom,
      ${theme.atlasGuru.primaryWithOpacityAt(7)} 10%,
      ${theme.atlasGuru.primaryWithOpacityAt(0)} 40%
    )`,
    height: "100%",
    left: 0,
    padding: "0.5em",
    position: "absolute",
    top: 0,
    width: "100%",
  },

  btnBox: {
    alignItems: "flex-end",
    display: "flex",
    flexDirection: "row-reverse",
    opacity: 0,
    transition: theme.transitions.create("opacity"),
  },

  formControl: {
    bottom: 0,
    height: "100%",
    left: 0,
    margin: 0,
    padding: 0,
    position: "absolute",
    width: "100%",
    zIndex: 0,

    "& fieldset": {
      border: "none",
      padding: "0 !important",
    },

    "& textarea": {
      // fontSize: theme.atlasGuru.fontSizes.caption2.sm,
      backgroundColor: theme.atlasGuru.primaryWithOpacityAt("01"),
      borderBottom: `1px solid ${theme.atlasGuru.primaryWithOpacityAt("04")}`,
      height: "5em",
      padding: theme.spacing(0.5),
      width: "100%",
      minHeight: "20px",
      maxHeight: "70px",
      transition: "background 0.4s ease",
      resize: "none !important",

      "&:disabled": {
        backgroundColor: theme.palette.grey[100],
      },

      "&:focus, &:hover": {
        backgroundColor: theme.atlasGuru.primaryWithOpacityAt("04"),
      },
    },
  },

  input: {
    backgroundColor: "transparent",
    left: 0,
    padding: 0,
    position: "absolute",
    top: "100%",
    width: "100%",
  },

  formControFooter: {
    // fontSize: theme.atlasGuru.fontSizes.caption2.sm,
    position: "absolute",
    top: "calc(100% + 5.5em)",
    width: "100%",
  },

  inputElem: {
    overflow: "normal",
    resize: "none",
  },

  icon: {
    paddingLeft: "0.5em",
  },

  button: {
    display: "flex",
    flexDirection: "row-reverse",
    margin: 0,
    minWidth: 0,
    padding: 0,
    position: "relative",
    zIndex: 10,

    "& .icon svg": {
      color: theme.palette.common.white,
      height: "2em",
      width: "2em",

      "& circle": {
        transition: "fill 0.4s ease",
      },
    },

    "&:hover": {
      "& .icon circle": {
        fill: theme.palette.common.highlight,
      },

      "& $checkboxChecked  .icon circle": {
        fill: theme.palette.secondary.main,
      },
    },

    "& > span": {
      fontWeight: "normal",
    },

    "& $checkboxLabel": {
      margin: "0 0.4em 0 0",
      textAlign: "right",
    },
  },

  checkbox: {
    "& .icon circle": {
      fill: theme.atlasGuru.primaryWithOpacityAt(2),
    },
  },

  checkboxChecked: {
    cursor: "default",
  },

  checkboxLabel: {
    ...theme.typography.caption,
    color: theme.palette.common.white,
    fontStyle: "normal",
    margin: 0,
    padding: 0,
    textDecoration: "none",
    textTransform: "none",
    lineHeight: 1,
  },
});

export default styles;
