import Editor from "./Editor";

import { useController } from "react-hook-form";
import { forwardRef, memo } from "react";

const EditorControlled = forwardRef(({ control, name, ...rest }, ref) => {
  const { field } = useController({
    name,
    control,
  });

  const { onChange, value, ...others } = field;

  return (
    <Editor
      {...rest}
      onChange={(e, editor) => onChange(editor.getData())}
      data={value}
      {...others}
      ref={ref}
    />
  );
});

EditorControlled.displayName = "EditorControlled";

export default memo(EditorControlled);
