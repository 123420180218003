export function fromLocalStorage(name) {
  if (process.browser) {
    return JSON.parse(window.localStorage.getItem(name)) || null;
  }
}

export function toLocalStorage(name, value) {
  if (process.browser) {
    window.localStorage.setItem(name, JSON.stringify(value));
  }
}

export function deleteLocalStorage(name) {
  if (process.browser) {
    window.localStorage.removeItem(name);
  }
}
