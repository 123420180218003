const { makeStyles } = require("@material-ui/core");

const styles = (theme) => ({
  root: {
    margin: 0,
  },

  legend: {},

  error: {},

  formGroup: {
    marginTop: theme.spacing(1),
  },

  checkbox: {
    margin: `0 ${theme.spacing(0.5)}px ${theme.spacing(0.5)}px 0`,

    [theme.breakpoints.up("sm")]: {
      margin: `0 ${theme.spacing(1)}px ${theme.spacing(1)}px 0`,
    },
  },

  errorMsg: {
    ...theme.typography.caption,
    margin: 0,

    "&:first-letter": {
      textTransform: "capitalize",
    },
  },
});

export default makeStyles(styles);
