const Delete = () => (
  <>
    <g>
      <g>
        <g fill="none" strokeWidth="6">
          <circle cx="100" cy="100" r="100" stroke="none" />
          <circle cx="100" cy="100" r="99" fill="none" />
        </g>
        <g transform="translate(50 40)">
          <path
            d="M20.493,15.095a.969.969,0,0,0-.927,1.009l2.784,66.568a.968.968,0,0,0,.967.929h.042a.969.969,0,0,0,.927-1.009L21.5,16.024A1,1,0,0,0,20.493,15.095Z"
            transform="translate(11.52 13.186)"
            strokeWidth="2"
          />
          <path
            d="M30.469,15.095a.968.968,0,0,0-.969.969V82.632a.969.969,0,0,0,1.938,0V16.064A.968.968,0,0,0,30.469,15.095Z"
            transform="translate(20.837 13.186)"
            strokeWidth="2"
          />
          <path
            d="M40.783,16.024,38,82.592a.969.969,0,0,0,.927,1.009h.042a.968.968,0,0,0,.967-.929L42.719,16.1a.969.969,0,0,0-.927-1.009A1.011,1.011,0,0,0,40.783,16.024Z"
            transform="translate(28.806 13.186)"
            strokeWidth="2"
          />
          <path
            d="M105.165,11.241H75.049V5.617A5.123,5.123,0,0,0,69.932.5H39.725a5.123,5.123,0,0,0-5.117,5.117v5.624H4.492a.969.969,0,1,0,0,1.938H15.409l5.148,94a8.087,8.087,0,0,0,8.073,7.643h52.4a8.087,8.087,0,0,0,8.073-7.643l5.148-94h10.917a.969.969,0,0,0,0-1.938ZM36.546,5.617a3.183,3.183,0,0,1,3.179-3.179H69.932a3.183,3.183,0,0,1,3.179,3.179v5.624H36.546ZM87.166,107.077a6.15,6.15,0,0,1-6.139,5.811h-52.4a6.15,6.15,0,0,1-6.139-5.811l-5.142-93.9h74.96Z"
            transform="translate(-3.523 -0.5)"
            strokeWidth="2"
          />
        </g>
      </g>
    </g>
  </>
);

export default Delete;
