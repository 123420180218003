import { makeStyles } from "@material-ui/core";

const styles = () => ({
  root: {
    display: "block",
    position: "unset",
  },

  image: {
    width: "100%",
    height: "auto",
    aspectRatio: "attr(width) / attr(height)",
  },
});

export default makeStyles(styles);
