import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Checkbox as MuiCheckbox, FormControlLabel } from "@material-ui/core";
import { IconCheck, IconCircle, IconSquareChecked, IconSquare } from "../icons";

import styles from "./styles";

const Checkbox = ({
  className,
  classes,
  error,
  label,
  name,
  onFocus,
  required,
  value,
  variant,
  ...rest
}) => {
  let customProps = rest;
  const errorClass = error ? classes.error : "";

  if (variant === "check") {
    customProps = {
      ...customProps,
      icon: <IconCircle />,
      checkedIcon: <IconCheck />,
    };
  }

  if (variant === "square") {
    customProps = {
      ...customProps,
      icon: <IconSquare />,
      checkedIcon: <IconSquareChecked />,
    };
  }

  const renderCheckbox = () => (
    <MuiCheckbox
      aria-describedby={`checkbox-info-${name}`}
      classes={{
        root: classes.checkbox,
        checked: classes.checked,
      }}
      // id={`checkbox-${label.toLowerCase().replace(" ", "-")}`}
      name={name}
      onFocus={onFocus}
      required={required}
      value={value}
      {...customProps}
    />
  );

  return (
    <FormControlLabel
      classes={{
        label: classes.label,
        root: `${className} ${classes.root} ${classes[variant]} ${errorClass}`,
      }}
      control={renderCheckbox()}
      // error={error}
      label={label}
      required={required}
    />
  );
};

Checkbox.defaultProps = {
  className: "",
  classes: {},
  error: "",
  label: "",
  name: "",
  onFocus: () => {},
  required: false,
  variant: "default",
};

Checkbox.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  error: PropTypes.string,
  label: PropTypes.any,
  name: PropTypes.string,
  onFocus: PropTypes.func,
  required: PropTypes.bool,
  value: PropTypes.any,
  variant: PropTypes.string,
};

export default withStyles(styles)(Checkbox);
