const List = () => (
  <>
    <g data-name="Group 29928" transform="translate(847 134)">
      <g data-name="Group 29925" transform="translate(53 -72)">
        <g data-name="Rectangle 23296" transform="translate(-900 -62)">
          <rect width="184" height="18.776" stroke="none" />
          <rect x="0.5" y="0.5" width="183" height="17.776" fill="none" />
        </g>
      </g>
      <g data-name="Group 29926" transform="translate(53 -18)">
        <g data-name="Rectangle 23296" transform="translate(-900 -62)">
          <rect width="184" height="18.776" stroke="none" />
          <rect x="0.5" y="0.5" width="183" height="17.776" fill="none" />
        </g>
      </g>
      <g data-name="Group 29927" transform="translate(53 36)">
        <g data-name="Rectangle 23296" transform="translate(-900 -62)">
          <rect width="184" height="18.776" stroke="none" />
          <rect x="0.5" y="0.5" width="183" height="17.776" fill="none" />
        </g>
      </g>
    </g>
  </>
);

export default List;
