import { useState, useRef } from "react";

import { IconMenuBurger } from "@/icons";
import { IconButton, Popper, MenuList, MenuItem, Link, Tag } from "@/ui";

import { headerNav } from "./constants";

import useStyles from "./styles";

const ShrinkNav = ({ shrink }) => {
  const classes = useStyles();
  const menuButtonRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const onToggleNav = () => setIsOpen(!isOpen);

  return (
    <div
      className={`${classes.shrinkNav} ${
        shrink ? classes.shrinkNavShrinked : ""
      }`}
    >
      <IconButton
        aria-label="main navigation"
        ref={menuButtonRef}
        className={classes.shrinkNavButton}
        onClick={onToggleNav}
      >
        <IconMenuBurger />
      </IconButton>

      <Popper
        disablePortal
        onClose={onToggleNav}
        placement="bottom-start"
        anchorEl={menuButtonRef.current}
        open={shrink && isOpen}
        classes={{
          root: classes.shrinkNavPopper,
          closeBtn: classes.shrinkNavPopperCloseBtn,
          popperWrapper: classes.shrinkNavPopperWrapper,
          popperContent: classes.shrinkNavPopperContent,
        }}
      >
        <MenuList className={classes.shrinkNavMenuList}>
          {headerNav.map((item) => (
            <MenuItem className={classes.shrinkNavMenuItem} key={item.link}>
              <Link href={item.link} className={classes.shrinkNavMenuLink}>
                {item.text}
                {item.tag && (
                  <Tag
                    className={classes.shrinkNavMenuTag}
                    label={item.tag}
                    color="secondary"
                  />
                )}
              </Link>
            </MenuItem>
          ))}
        </MenuList>
      </Popper>
    </div>
  );
};

export default ShrinkNav;
