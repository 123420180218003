import MuiCard from "@material-ui/core/Card";

import useStyles from "./styles";

const Card = ({ rounded, classes: rootClasses, ...props }) => {
  const classes = useStyles({ classes: rootClasses });

  return (
    <MuiCard
      classes={{
        root: `${classes.root} ${rounded ? classes.rounded : ""}`,
      }}
      {...props}
    />
  );
};

export default Card;
