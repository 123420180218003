import { removeEmpty } from "@/lib/utils";
import {
  ITINERARY_PREVIEW_NO_CACHE,
  ITINERARY_PREVIEW,
  ITINERARY_UPSERT,
  ITINERARY_QUERY,
  LOCATION_UPSERT,
  LOCATIONS_ORDER_UPSERT,
  LOCATION_DELETE,
  ITINERARY_PUBLISH,
  ITINERARY_UNPUBLISH,
  PLACE_UPSERT,
  PLACE_DELETE,
  LOCATIONS_BATCH_UPSERT,
  ITINERARY_QUERY_NO_CACHE,
} from "./gql";

export const fetchItineraryPreview = async ({
  client,
  options = {},
  cached = true,
  dataOnly = true,
}) => {
  const itinerary = await client.query({
    query: cached ? ITINERARY_PREVIEW : ITINERARY_PREVIEW_NO_CACHE,
    ...options,
  });
  return dataOnly ? removeEmpty(itinerary?.data?.ItineraryPreview) : itinerary;
};

export const fetchItinerary = async ({
  client,
  options = {},
  cached = true,
}) => {
  const itinerary = await client.query({
    query: cached ? ITINERARY_QUERY : ITINERARY_QUERY_NO_CACHE,
    ...options,
  });
  return removeEmpty(itinerary?.data?.Itinerary);
};

export const upsertItinerary = async ({ client, options = {} }) => {
  const itinerary = await client.mutate({
    mutation: ITINERARY_UPSERT, // default mutation can be overriden
    ...options,
    // replace the local apollo cache data with the latest values so the user sees the most recent version of the itinerary without reloading the page
    update: (cache) => {
      const input = options?.variables?.input;
      const itineraryId = input?.itineraryId;

      if (!itineraryId) {
        return;
      }

      // update apollo cache so if the user navigates to the itinerary he/she will see the latest changes
      cache.updateQuery(
        {
          query: ITINERARY_QUERY,
          variables: { id: itineraryId },
        },
        (data) => {
          const newData = {
            Itinerary: input,
          };

          return {
            ...data,
            ...newData,
          };
        }
      );

      cache.updateQuery(
        {
          query: ITINERARY_PREVIEW_NO_CACHE,
          variables: { id: itineraryId },
        },
        (data) => {
          const newData = {
            ItineraryPreview: input,
          };

          return {
            ...data,
            ...newData,
          };
        }
      );
    },
  });
  return removeEmpty(itinerary?.data?.upsertItinerary);
};

export const publishItinerary = async ({ client, options = {} }) => {
  const itinerary = await client.mutate({
    mutation: ITINERARY_PUBLISH,
    ...options,
  });
  return itinerary;
};

export const unpublishItinerary = async ({ client, options = {} }) => {
  const itinerary = await client.mutate({
    mutation: ITINERARY_UNPUBLISH,
    ...options,
  });
  return itinerary;
};

export const upsertLocation = async ({ client, options = {} }) => {
  const location = await client.mutate({
    mutation: LOCATION_UPSERT,
    ...options,
  });
  return removeEmpty(location?.data?.upsertLocation);
};

export const upsertLocationsBatch = async ({ client, options = {} }) => {
  const location = await client.mutate({
    mutation: LOCATIONS_BATCH_UPSERT,
    ...options,
  });
  return removeEmpty(location?.data?.upsertLocationsBatch);
};

export const upsertLocationsOrder = async ({ client, options = {} }) => {
  const locations = await client.mutate({
    mutation: LOCATIONS_ORDER_UPSERT,
    ...options,
  });
  return removeEmpty(locations?.data?.upsertLocationsOrder);
};

export const removeLocation = async ({ client, options = {} }) => {
  const locations = await client.mutate({
    mutation: LOCATION_DELETE,
    ...options,
  });
  return removeEmpty(locations?.data?.deleteLocation);
};

export const upsertPlace = async ({ client, options = {} }) => {
  const place = await client.mutate({
    mutation: PLACE_UPSERT,
    ...options,
  });
  return removeEmpty(place?.data?.upsertPlace);
};

export const removePlace = async ({ client, options = {} }) => {
  const place = await client.mutate({
    mutation: PLACE_DELETE,
    ...options,
  });
  return removeEmpty(place?.data?.deletePlace);
};
