import dynamic from "next/dynamic";
import { withStyles } from "@material-ui/core/styles";
import Typography from "../Typography";

import styles from "./styles";

// dynamic import needed for nextjs
// https://spectrum.chat/next-js/general/dynamic-import-ckeditor-component-and-module~c263fddf-c452-43f8-a374-7a930abd31c4
const CKEditor = dynamic(() => import("./CKEditor"), {
  ssr: false,
});

const Editor = ({
  classes = {},
  data = null,
  egText = "",
  heading = "",
  ...rest
}) => (
  <section className={classes.root} role="presentation">
    {heading && (
      <Typography variant="h4" component="h1" className={classes.heading}>
        {heading}
      </Typography>
    )}
    {<CKEditor classes={classes} data={data ?? ""} {...rest} />}
    {egText && (
      <Typography
        variant="caption"
        component="small"
        className={classes.egText}
      >
        {egText}
      </Typography>
    )}
  </section>
);

export default withStyles(styles)(Editor);
