import { withStyles, Popover as MuiPopover } from "@material-ui/core";

const styles = (theme) => ({
  paper: {
    background: theme.palette.grey[100],
  },
});

const Popover = (props) => <MuiPopover {...props} />;

export default withStyles(styles)(Popover);
