const FaceHappy = () => (
  <>
    <g>
      <path
        d="M101,1A100,100,0,1,0,201,101,100,100,0,0,0,101,1Zm0,191.667A91.667,91.667,0,1,1,192.667,101,91.667,91.667,0,0,1,101,192.667Zm44.875-57a4.167,4.167,0,0,1-.542,5.875c-16.292,13.625-31.583,18.208-44.75,18.208a66.667,66.667,0,0,1-44.292-18.542,4.17,4.17,0,1,1,6.042-5.75c1.417,1.458,34.792,35.5,77.625-.333a4.167,4.167,0,0,1,5.917.542Zm-86.542-55.5c0-9.208,5.583-16.667,12.5-16.667s12.5,7.458,12.5,16.667-5.583,16.667-12.5,16.667S59.333,89.375,59.333,80.167Zm58.333,0c0-9.208,5.583-16.667,12.5-16.667s12.5,7.458,12.5,16.667-5.583,16.667-12.5,16.667S117.667,89.375,117.667,80.167Z"
        transform="translate(-1 -1)"
      />
    </g>
  </>
);

export default FaceHappy;
