import { gql } from "@apollo/client";

// HERO ITINERARIES

export const HERO_ITINERARIES_QUERY_NO_CACHE = gql`
  query HERO_ITINERARIES_QUERY_NO_CACHE {
    GetHeroItineraries {
      heroItineraries {
        itinerary {
          itineraryId
          numOfDays
          tripTitle
          shortDescription
          countries {
            name
          }
          locationsVisited {
            tripPhotos {
              photoUrl
              isHero
              cloudId
            }
          }
          tripPhotos {
            photoUrl
            caption
            cloudId
            isHero
          }
          userProfile {
            userId
            screenName
            profilePicUrl
          }
        }
        customPhoto {
          mobile {
            photoUrl
            cloudId
            photoFilter
          }
          desktop {
            photoUrl
            cloudId
            photoFilter
          }
        }
      }
      paginationInfo {
        totalPages
        currentPage
        totalItems
      }
    }
  }
`;

export const UPSERT_HERO_ITINERARIES = gql`
  mutation ($input: [HeroItineraryInput]) {
    UpsertHeroItineraries(input: $input) {
      heroItineraries {
        itinerary {
          itineraryId
        }
      }
    }
  }
`;

export const REMOVE_HERO_ITINERARY = gql`
  mutation ($itineraryId: String!) {
    RemoveHeroItinerary(itineraryId: $itineraryId) {
      heroItineraries {
        itinerary {
          itineraryId
        }
      }
    }
  }
`;

// QUESTIONS

export const QUESTIONS_QUERY_NO_CACHE = gql`
  query QUESTIONS_QUERY_NO_CACHE {
    GetQuestions {
      egText
      question
      sortOrder
      questionId
      shortLabel
    }
  }
`;
