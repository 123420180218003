import PropTypes from "prop-types";
import Button from "./Button";
import Grid from "./Grid";
import Loading from "./Loading";
import Modal from "./Modal";
import Typography from "./Typography";

const InfoModal = ({
  classes,
  onConfirm,
  onCancel,
  open,
  message,
  confirmText,
  loading,
  disableConfirm,
  ...rest
}) => (
  <Modal
    classes={{
      row: classes.row,
    }}
    onClose={onCancel}
    open={open}
    hideBackdrop
    {...rest}
  >
    <Grid container>
      <Grid item xs={12}>
        <Typography component="p" variant="h3">
          {message}
        </Typography>
        <div className={classes.footer}>
          <Button variant="outlined" onClick={onCancel}>
            Cancel
          </Button>

          <Button
            error
            variant="contained"
            className={classes.actionButton}
            onClick={onConfirm}
            disabled={loading || disableConfirm}
          >
            {confirmText}
            {loading && <Loading className={classes.iconLoading} size={18} />}
          </Button>
        </div>
      </Grid>
    </Grid>
  </Modal>
);

InfoModal.defaultProps = {
  classes: {},
  open: false,
  onCancel: () => {},
  onConfirm: () => {},
  confirmText: "Confirm",
  message: "Are you sure?",
  loading: false,
};

InfoModal.propTypes = {
  classes: PropTypes.object,
  open: PropTypes.bool,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  confirmText: PropTypes.string,
  message: PropTypes.string,
  loading: PropTypes.bool,
  disableConfirm: PropTypes.bool,
};

export default InfoModal;
