const Play = () => (
  <>
    <path
      d="M68.5 35C68.5 53.5015 53.5015 68.5 35 68.5C16.4985 68.5 1.5 53.5015 1.5 35C1.5 16.4985 16.4985 1.5 35 1.5C53.5015 1.5 68.5 16.4985 68.5 35Z"
      stroke="white"
      strokeWidth="2"
    />
    <path
      d="M26.625 27.5612C26.6251 26.2202 27.3464 24.983 28.5133 24.3223C29.6802 23.6616 31.1123 23.6796 32.2622 24.3695L44.6601 31.8083C45.7812 32.481 46.4671 33.6926 46.4671 35C46.4671 36.3074 45.7812 37.519 44.6601 38.1917L32.2622 45.6306C31.1123 46.3205 29.6801 46.3386 28.5132 45.6778C27.3463 45.0171 26.625 43.7798 26.625 42.4388V27.5612Z"
      stroke="white"
      strokeWidth="2"
    />
  </>
);

export default Play;
