const Map = () => (
  <>
    <path
      d="M15.0002 3.00629V9.9992"
      stroke="#9A9A86"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M8.9924 4.77704V19.003"
      stroke="#9A9A86"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M20.9997 10.9996V5.61036C20.9997 5.17918 20.7246 4.79702 20.3154 4.66097L15.6295 3.09932C15.2193 2.96226 14.7751 2.96226 14.3639 3.09832L9.62695 4.67697C9.21578 4.81403 8.7726 4.81403 8.36142 4.67697L4.30874 3.32641C3.66147 3.11032 2.99219 3.59252 2.99219 4.27581V15.6195C2.99219 15.9987 3.20628 16.3448 3.54542 16.5149L8.10032 18.7919C8.66355 19.074 9.32683 19.074 9.89006 18.7919L10.9955 18.2386"
      stroke="#9A9A86"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M18.0017 13.0004C20.2116 13.0004 22.0033 14.7922 22.0033 17.0021C22.0033 19.212 20.2116 21.0038 18.0017 21.0038C15.7917 21.0038 14 19.212 14 17.0021C14 14.7922 15.7917 13.0004 18.0017 13.0004"
      stroke="#9A9A86"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M19.5586 16.0297L17.6128 17.9755L16.4453 16.808"
      stroke="#9A9A86"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </>
);

export default Map;
