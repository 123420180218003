const Warning = () => (
  <>
    <g data-name="!" transform="scale(5.71429)">
      <g data-name="Ellipse 2922" fill="none" strokeWidth="1">
        <circle cx="17.5" cy="17.5" r="17.5" stroke="none" />
        <circle cx="17.5" cy="17.5" r="17" />
      </g>
      <text
        data-name="!"
        transform="translate(14 26.8)"
        fontSize="27"
        fontFamily="ArialMT,Arial"
      >
        <tspan x="0" y="0">
          !
        </tspan>
      </text>
    </g>
  </>
);

export default Warning;
