import PropTypes from "prop-types";
import { IconArrow } from "../icons";
import Fab from "../Fab";

// Previous Arrow Component
const PreviousBtn = ({ classes, isVisible, onClick }) => (
  <Fab
    aria-label="preview"
    className={`${classes.arrow} ${classes.left} ${
      isVisible ? classes.visible : classes.hidden
    }`}
    color="secondary"
    disabled={!isVisible}
    onClick={onClick}
    variant="extended"
  >
    <IconArrow variant="left" />
  </Fab>
);

PreviousBtn.defaultProps = {
  classes: {},
  isVisible: true,
  onClick: () => {},
};

PreviousBtn.propTypes = {
  classes: PropTypes.object,
  isVisible: PropTypes.bool,
  onClick: PropTypes.func,
};

// Next Arrow Component
const NextBtn = ({ classes, isVisible, onClick }) => (
  <Fab
    aria-label="next"
    className={`${classes.arrow} ${classes.right} ${
      isVisible ? classes.visible : classes.hidden
    }`}
    color="secondary"
    disabled={!isVisible}
    onClick={onClick}
    variant="extended"
  >
    <IconArrow variant="right" />
  </Fab>
);

NextBtn.defaultProps = {
  classes: {},
  isVisible: true,
  onClick: () => {},
};

NextBtn.propTypes = {
  classes: PropTypes.object,
  isVisible: PropTypes.bool,
  onClick: PropTypes.func,
};

// Pagination Dot Component
const DotBtn = ({ classes, index, isVisible, onClick }) => {
  const activeClass = isVisible ? `${classes.active} active` : "";

  return (
    <span
      aria-hidden="true"
      className={`${classes.dotBtn} ${activeClass}`}
      onClick={onClick}
      role="button"
      key={index}
    />
  );
};

DotBtn.defaultProps = {
  classes: {},
  onClick: () => {},
  index: 0,
  isVisible: true,
};

DotBtn.propTypes = {
  classes: PropTypes.object,
  onClick: PropTypes.func,
  index: PropTypes.number,
  isVisible: PropTypes.bool,
};

// Pagination Component
const Pagination = ({ classes, count, current, onClick }) => {
  const dots = [];
  // TODO: move to a component prop: <Pagination groupAmount={number} />
  const slidePos = current + 1;
  const slidesToSkip = 1;
  const slideCountFixed = count / slidesToSkip;

  // Populate the pagination dots array
  for (let i = 1; i <= slideCountFixed; i += 1) {
    // Set active state for single or group slides
    const isCurrSlideActive = slidePos / slidesToSkip === i;
    const isVisible = isCurrSlideActive;

    dots[i] = DotBtn({
      classes,
      index: i,
      isVisible,
      onClick: () => onClick(i * slidesToSkip - slidesToSkip),
    });
  }

  return <div className={classes.pagination}>{dots}</div>;
};

Pagination.defaultProps = {
  classes: {},
  current: 0,
};

Pagination.propTypes = {
  classes: PropTypes.object,
  count: PropTypes.number.isRequired,
  current: PropTypes.number,
  onClick: PropTypes.func.isRequired,
};

export { DotBtn, NextBtn, PreviousBtn, Pagination };
