import algoliasearch from "algoliasearch/lite";

import {
  algoliaId,
  algoliaSearchOnlyAPIKey,
  algoliaIndexNameItinerary,
  algoliaIndexNameTripHelp,
  algoliaIndexNameTopics,
} from "@/config";

const indexesMap = {
  itineraries: algoliaIndexNameItinerary,
  tripHelp: algoliaIndexNameTripHelp,
  topics: algoliaIndexNameTopics,
};

const createClient = (options = {}) => {
  return algoliasearch(algoliaId, algoliaSearchOnlyAPIKey, options);
};

const search =
  (client) =>
  async (query = "", searchParameters = {}) => {
    const results = await client.search(query, searchParameters);
    return results;
  };

const searchAll =
  (client) =>
  async (searchParameters = {}) => {
    const result = await client.search("", {
      hitsPerPage: 1000,
      ...searchParameters,
    });
    return result;
  };

const searchAllByFacetValue =
  (client) =>
  async (facetFilters = null, searchParameters = {}) => {
    const result = await client.search("", {
      ...searchParameters,
      facetFilters,
    });
    return result;
  };

const algolia = (indexName, options = {}) => {
  const client = createClient(options);
  const index = client.initIndex(indexesMap[indexName]);

  return {
    search: search(index),
    searchAll: searchAll(index),
    searchAllByFacetValue: searchAllByFacetValue(index),
  };
};

export default algolia;
