const Heart = () => (
  <>
    <g transform="scale(3.07692)">
      <g
        data-name="Group 29857"
        transform="translate(17684 19410)"
        fill="none"
        strokeWidth="2"
      >
        <path
          d="m 33.911,2.969 a 9.9,9.9 0 0 0 -14.086,0 L 18.44,4.355 17.055,2.969 A 9.9603061,9.9603061 0 0 0 2.969,17.055 L 18.44,32.526 33.911,17.055 a 9.9,9.9 0 0 0 0,-14.086"
          transform="translate(-17669.807 -19393.775)"
          fillRule="evenodd"
        />
        <g data-name="Ellipse 2949" transform="translate(-17684 -19410)">
          <circle cx="32.5" cy="32.5" r="32.5" stroke="none" />
          <circle cx="32.5" cy="32.5" r="32" />
        </g>
      </g>
    </g>
  </>
);

export default Heart;
