const CounterBox = () => (
  <>
    <g transform="matrix(3.07692 0 0 3.07692 0 1.234)">
      <g data-name="Group 29860" transform="translate(0 4.799)">
        <g data-name="Rectangle 1506" fill="none" strokeWidth="2">
          <path
            d="m 6,0 h 53 a 6,6 0 0 1 6,6 v 42.6 a 6,6 0 0 1 -6,6 H 0 V 6 A 6,6 0 0 1 6,0 Z"
            stroke="none"
          />
          <path d="M 6,0.5 H 59 A 5.5,5.5 0 0 1 64.5,6 V 48.6 A 5.5,5.5 0 0 1 59,54.1 H 1 A 0.5,0.5 0 0 1 0.5,53.6 V 6 A 5.5,5.5 0 0 1 6,0.5 Z" />
        </g>
      </g>
    </g>
  </>
);

export default CounterBox;
