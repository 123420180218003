import { withStyles, Fab as MuiFab } from "@material-ui/core";

const styles = (theme) => ({
  root: {
    boxShadow: "none",
  },
  secondary: {
    "&:hover": {
      backgroundColor: theme.palette.common.highlight,
    },
  },
});

const Fab = (props) => <MuiFab {...props} />;

export default withStyles(styles)(Fab);
