import { Link, MenuItem, MenuList } from "@/ui";

import useStyles from "./styles";

const UserNav = ({ isAdmin = false }) => {
  const classes = useStyles();

  return (
    <MenuList className={classes.userNavMenu}>
      <Link href="/profile/edit">
        <MenuItem className={classes.userNavMenuItem}>Profile</MenuItem>
      </Link>

      <Link href="/profile/reports">
        <MenuItem className={classes.userNavMenuItem}>Trip Reports</MenuItem>
      </Link>

      <Link href="/profile/ai-trips/">
        <MenuItem className={classes.userNavMenuItem}>AI Generated</MenuItem>
      </Link>

      {isAdmin && (
        <Link href="/admin">
          <MenuItem className={classes.userNavMenuItem}>Admin</MenuItem>
        </Link>
      )}

      <Link href="/api/auth/logout">
        <MenuItem className={classes.userNavMenuItem}>Logout</MenuItem>
      </Link>
    </MenuList>
  );
};

export default UserNav;
