import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { disabledWipFeatures } from "@/config";
import { buildUrl } from "@/lib/useCloudinary";
import {
  DEFAULT_IMAGE_CAPTION,
  DEFAULT_TRANSFORMATIONS,
  CUSTOM_CROP_TRANSFORMATIONS,
} from "@/lib/constants";

import Avatar from "../Avatar";
import Link from "../Link";
import Image from "../Image";

import styles from "./legacyStyles";

const coverImageTransformations = {
  crop: "fill",
  gravity: "auto:subject",
  height: 280,
  width: 500,
};

const MediaCard = ({
  cardInfo,
  cardLink,
  children,
  classes,
  customClasses,
  disableUserLink,
  hideUserInfo,
  extraContent,
  fullWidth,
  gutter,
  isLoader,
  onAvatarImgError,
  partner,
  secondaryImage,
  headlineComponent = "h1",
  userInfo,
  variant,
  ...props
}) => {
  const {
    userScreenName = "",
    screenName = "",
    userLink = "",
    cloudId: avatarCloudId,
    profilePicUrl = "",
    userId = "",
  } = userInfo || {};
  const {
    cloudId,
    description,
    link,
    linkAs,
    photoUrl,
    transformations,
    caption,
    title,
    headline,
  } = cardInfo;
  const imgTransformations = {
    ...DEFAULT_TRANSFORMATIONS,
    ...coverImageTransformations,
    ...transformations,
  };
  const defaultBgImage = buildUrl(
    cloudId,
    CUSTOM_CROP_TRANSFORMATIONS,
    imgTransformations
  );
  const backgroundImage = `url(${
    secondaryImage || defaultBgImage || photoUrl
  })`;
  const imgPlainText =
    caption || (typeof title === "string" ? title : DEFAULT_IMAGE_CAPTION);
  const imgAltText = variant === "cardUser" ? userScreenName : imgPlainText;

  const renderUserName = () => {
    if (!disableUserLink && userId) {
      return (
        <span className={classes.userProfileLink}>
          by{" "}
          <Link
            href={userLink || `/user/[...params]`}
            as={userLink || `/user/${userId}/${screenName}`}
          >
            {screenName || "AtlasGuru User"}
          </Link>
        </span>
      );
    }

    return screenName;
  };

  const renderCardContent = () => (
    <>
      {(cloudId || photoUrl || isLoader) && (
        <div className={classes.loaderContainer}>
          {headline && (
            <Typography
              className={classes.headline}
              component={headlineComponent}
              variant="h1"
            >
              {headline}
            </Typography>
          )}
          <div
            className={`${classes.mediaContainer} ${
              customClasses.mediaContainer
            } ${isLoader ? classes.mediaLoader : ""}`}
            style={{ backgroundImage }}
          >
            <Image
              src={photoUrl}
              alt={imgAltText}
              cloudId={cloudId}
              className={`${classes.image} ${customClasses.image}`}
              transformations={imgTransformations}
              height={imgTransformations?.height}
              width={imgTransformations?.width}
            />
          </div>
        </div>
      )}

      <CardContent
        component={variant === "cardPhoto" ? "figcaption" : "div"}
        className={customClasses.cardContent}
        classes={{
          root: `${customClasses.content} ${classes.content} ${
            classes[`${variant}Content`]
          }`,
        }}
      >
        {(title || description) && (
          <div
            className={`${customClasses.cardInfoBox} ${
              classes[`${variant}InfoBox`]
            }`}
          >
            {title && (
              <Typography className={classes.title} variant="h3">
                {title}
              </Typography>
            )}
            {description && (
              <Typography component="p" variant="subtitle2">
                {description}
              </Typography>
            )}
          </div>
        )}
        {extraContent}
        {children}
      </CardContent>
    </>
  );

  const renderAvatar = () => {
    return (
      <Avatar
        alt={userScreenName || screenName || "AtlasGuru User"}
        className={customClasses.avatar || classes[`${variant}Avatar`]}
        onError={onAvatarImgError}
        cloudId={avatarCloudId}
        src={profilePicUrl}
      />
    );
  };

  const renderCardHeader = () => (
    <CardHeader
      avatar={
        <>
          {disableUserLink ? (
            renderAvatar()
          ) : (
            <Link
              href={userLink || `/user/[...params]`}
              as={
                userLink || `/user/${userId || "null"}/${screenName || "null"}`
              }
              className={classes.userLink}
            >
              {renderAvatar()}
            </Link>
          )}
        </>
      }
      classes={{
        root: `${customClasses.header} ${classes[`${variant}Header`]}`,
        avatar: classes[`${variant}HeaderAvatar`],
        content: `${customClasses.headerContent} ${
          classes[`${variant}HeaderContent`]
        }`,
      }}
      component="header"
      disableTypography
      title={renderUserName()}
    />
  );

  return (
    <Card
      // TODO: create a utility for conditional or multiple classes
      className={`${classes.card} ${classes[variant]} ${
        cardLink ? classes.cardLink : ""
      } ${fullWidth ? classes.cardFullWidth : ""} ${
        gutter ? classes[`${gutter}Gutter`] : ""
      } ${!disabledWipFeatures && partner ? classes.partner : ""}`}
      classes={{ root: customClasses.card }}
      component={variant === "cardPhoto" ? "figure" : "div"}
      {...props}
    >
      {!(hideUserInfo || variant === "default") && renderCardHeader()}

      {cardLink ? (
        <Link
          href={link}
          as={linkAs}
          className={`${classes.link} ${customClasses.link}`}
          role="presentation"
        >
          {renderCardContent()}
        </Link>
      ) : (
        renderCardContent()
      )}
    </Card>
  );
};

MediaCard.defaultProps = {
  cardLink: true,
  cardInfo: {
    description: null,
    link: "#",
    linkAs: "#",
    // photoUrl: "",
    cloudId: "",
    transformations: {},
    title: null,
  },
  children: null,
  classes: {},
  customClasses: {},
  disableUserLink: false,
  hideUserInfo: false,
  extraContent: null,
  fullWidth: false,
  gutter: "normal",
  isLoader: false,
  onAvatarImgError: () => {},
  partner: "",
  secondaryImage: "",
  userInfo: {},
  variant: "default",
};

MediaCard.propTypes = {
  cardLink: PropTypes.bool,
  cardInfo: PropTypes.shape({
    // photoUrl: PropTypes.string,
    cloudId: PropTypes.string,
    description: PropTypes.node,
    link: PropTypes.string,
    linkAs: PropTypes.string,
    transformations: PropTypes.object,
    title: PropTypes.node,
  }),
  children: PropTypes.node,
  classes: PropTypes.object,
  customClasses: PropTypes.object,
  disableUserLink: PropTypes.bool,
  hideUserInfo: PropTypes.bool,
  extraContent: PropTypes.node,
  fullWidth: PropTypes.bool,
  gutter: PropTypes.string,
  isLoader: PropTypes.bool,
  partner: PropTypes.string,
  onAvatarImgError: PropTypes.func,
  secondaryImage: PropTypes.string,
  userInfo: PropTypes.object,
  variant: PropTypes.string,
};

export default withStyles(styles)(MediaCard);
