import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  page: {
    ...theme.typography.h4,
    color: theme.palette.grey[450],

    "&$selected, &$selected:hover": {
      backgroundColor: "transparent",
      color: theme.palette.common.highlight,
    },

    "&$disabled": {
      opacity: 1,

      "& $icon": {
        fill: theme.palette.grey[300],
      },
    },
  },

  selected: {},

  disabled: {},

  icon: {
    fill: theme.palette.secondary.main,
    height: "1.7em",
    width: "1.7em",
  },
}));

export default useStyles;
