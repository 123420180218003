const styles = (theme) => ({
  root: {},

  // {children}
  link: {},

  listItem: {
    "&:focus": {
      outline: "none",
    },

    "&$disabled": {
      opacity: 0.1,
    },
  },

  avatar: {},

  listItemIcon: {},

  listItemText: {},

  selected: {},

  disabled: {
    cursor: "not-allowed",
    pointerEvents: "none",
  },

  title: {},

  subTitle: {
    whiteSpace: "pre-line",
  },

  // type={ordered|unordered}
  ordered: {
    "& $listItem": {
      position: "relative",

      "&:before": {
        ...theme.typography.h3,
        color: theme.palette.grey[150],
        content: "attr(tabindex)",
        left: "1em",
        position: "absolute",
        top: "calc(50% - 0.5em)",
      },

      "& $listItemText": {
        paddingLeft: "2em",
      },
    },
  },

  unordered: {},

  // variant={list|heading}
  list: {},

  heading: {
    "& $listItem": {
      paddingBottom: 0,
      paddingTop: 0,

      "&:hover, &:focus": {
        backgroundColor: theme.atlasGuru.hexToRgbA(
          theme.palette.grey[100],
          "0.03"
        ),
      },

      "&$selected": {
        backgroundColor: theme.palette.grey[100],

        "& $title, & + $listItem $title": {
          borderTopColor: "transparent",
          transition: "none",
        },
      },

      "&:last-child": {
        "& $listItemText": {
          borderBottom: `1px solid ${theme.palette.grey[200]}`,
        },
      },
    },

    "& $listItemText": {
      borderTop: `1px solid ${theme.palette.grey[200]}`,
      paddingRight: "2em",
      paddingBottom: "0.9rem",
      paddingTop: "0.9rem",
      marginBottom: 0,
      marginTop: 0,
    },

    "& $listItemIcon": {
      margin: 0,
      right: theme.spacing(-1),
      position: "absolute",
      top: "calc(50% - 0.5em)",
    },

    "& $title": {
      paddingBottom: "0.3em",
    },

    "& $subTitle": {
      color: theme.palette.primary.main,
    },
  },

  // iconPos={start|end|center}
  iconend: {
    "& $listItem": {
      flexDirection: "row",
    },
  },

  iconstart: {
    "& $listItem": {
      flexDirection: "row-reverse",
    },
  },
});

export default styles;
