import MuiAutocomplete from "@material-ui/lab/Autocomplete";
import SearchBox from "./SearchBox";

// import useStyles from "./styles";

const Autocomplete = (props) => {
  const { autoComplete = true, autoFocus, placeholder, label, ...rest } = props;
  // const classes = useStyles({ classes: rest.classes || {} });

  return (
    <MuiAutocomplete
      autoComplete={autoComplete}
      // classes={classes}
      disablePortal
      filterSelectedOptions
      fullWidth
      openOnFocus
      renderInput={(props) => (
        <SearchBox
          {...props}
          label={label}
          placeholder={placeholder}
          autoFocus={autoFocus}
        />
      )}
      {...rest}
    />
  );
};

export default Autocomplete;
