import PropTypes from "prop-types";
import Typography from "../Typography";
import SlideMedia from "./SlideMedia";

const Slide = ({
  classes = {},
  index = "",
  imgProps = {},
  heading = "",
  setBrokenImageIndex = () => {},
  currSlide = 0,
}) => (
  <figure className={classes.slide}>
    <Typography className={classes.slideTitle}>{heading}</Typography>

    <div className={classes.slideImage}>
      <div className={classes.slideImageContent}>
        <SlideMedia
          index={index}
          classes={classes}
          imgProps={imgProps}
          currSlide={currSlide}
          setBrokenImageIndex={setBrokenImageIndex}
        />
      </div>

      {imgProps.caption && (
        <figcaption className={classes.slideFooter}>
          <Typography className={classes.slideCaption}>
            {imgProps.caption}
          </Typography>
        </figcaption>
      )}
    </div>
  </figure>
);

Slide.propTypes = {
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  imgProps: PropTypes.object.isRequired,
  classes: PropTypes.object,
  heading: PropTypes.string,
  setBrokenImageIndex: PropTypes.func.isRequired,
};

export default Slide;
