import { createRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import SlickSlider from "react-slick";
import { useTheme, useMediaQuery } from "@material-ui/core";
import { Pagination, PreviousBtn, NextBtn } from "./controls";

import useStyles from "./styles";

const Slider = ({
  children,
  infinite,
  initialSlide,
  slidesToScroll,
  slidesToShow,
  speed,
  useCss,
  variant,
  withPagination,
  hideArrows,
  settings,
  focusOnSelect = false,
  ...rest
}) => {
  const slider = createRef();
  const theme = useTheme();
  const classes = useStyles({ classes: rest.classes });
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const [currSlide, setCurrSlide] = useState(initialSlide);

  const maxSlides = children ? children.length : 0;

  const renderNextArrow = () => (
    <NextBtn
      classes={classes}
      isVisible={!hideArrows && (currSlide < maxSlides - 1 || infinite)}
    />
  );

  const renderPrevArrow = () => (
    <PreviousBtn
      classes={classes}
      isVisible={!hideArrows && (currSlide !== 0 || infinite)}
    />
  );

  const beforeChange = (current, next) => {
    if (settings?.beforeChange) {
      settings.beforeChange(current, next);
    }

    setCurrSlide(next);
  };

  const mergedSettings = {
    useCss,
    speed,
    infinite,
    initialSlide,
    slidesToShow,
    slidesToScroll,
    useTransform: true,
    focusOnSelect,
    cssEase: "cubic-bezier(0.165, 0.84, 0.44, 1)",
    nextArrow: renderNextArrow(),
    prevArrow: renderPrevArrow(),
    beforeChange,
    ...settings,
  };

  const goToSlide = (index) => {
    setCurrSlide(index);
    slider.current.slickGoTo(index);
  };

  // focus slider when mounths
  useEffect(() => {
    if (focusOnSelect && slider.current?.innerSlider?.list) {
      slider.current.innerSlider.list.setAttribute("tabindex", 0);
      slider.current.innerSlider.list.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focusOnSelect]);

  return (
    <div className={`${classes.root} ${classes[variant]}`}>
      <SlickSlider ref={slider} {...mergedSettings} {...rest}>
        {children}
      </SlickSlider>

      {withPagination && (
        <Pagination
          classes={classes}
          count={maxSlides}
          current={currSlide}
          isMobile={isMobile}
          onClick={goToSlide}
        />
      )}
    </div>
  );
};

Slider.defaultProps = {
  classes: {},
  infinite: false,
  initialSlide: 0,
  slidesToShow: 1,
  slidesToScroll: 1,
  speed: 700,
  useCss: true,
  variant: "normal",
  width: "",
  withPagination: false,
  hideArrows: false,
};

Slider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  classes: PropTypes.object,
  infinite: PropTypes.bool,
  initialSlide: PropTypes.number,
  slidesToShow: PropTypes.number,
  slidesToScroll: PropTypes.number,
  speed: PropTypes.number,
  useCss: PropTypes.bool,
  variant: PropTypes.string,
  width: PropTypes.string,
  withPagination: PropTypes.bool,
  hideArrows: PropTypes.bool,
};

export default Slider;
