import { useState, useRef, forwardRef } from "react";
import { delay } from "lodash-es";
import { buildUrl } from "@/lib/useCloudinary";

import { IconButton } from "@/ui";
import { IconPlay } from "@/icons";

import useStyles from "./styles";

const videoExtension = "mp4";
const posterExtension = "webp";

const Video = (props, ref) => {
  const {
    cloudId = "",
    className = "",
    transformations = {},
    height = 480,
    width = 640,
    controls = true,
    showPlayIcon = true,
    theme = "default",
    iconViewBox = "0 0 67px 67px",
    posterStartOffset = 0,
    poster = "",
    onClick = () => {},
    src,
    ...rest
  } = props;
  const videoRef = useRef(null);
  const [showControls, setShowControls] = useState(false);
  const [isPlayButtonVisible, setIsPlayButtonVisible] = useState(false);
  const classes = useStyles({ classes: rest.classes });
  const videoTransformations = {
    width,
    height,
    ...transformations,
  };
  const defaultPoster = cloudId
    ? buildUrl(cloudId, {
        ...videoTransformations,
        format: posterExtension,
        fetchFormat: posterExtension,
        startOffset: posterStartOffset,
      })
    : src;
  const source = cloudId
    ? buildUrl(cloudId, {
        ...videoTransformations,
        format: videoExtension,
        fetchFormat: videoExtension,
      })
    : src;

  const createVideoRef = (el) => {
    videoRef.current = el;

    if (ref?.current === null) {
      ref.current = el;
    }
  };

  const togglePlay = () => {
    const video = videoRef.current;

    if (video.paused) {
      video.play();
    } else {
      video.pause();
    }

    if (!controls) {
      setIsPlayButtonVisible(false);
    }
  };

  const handleVideoClick = (e) => {
    e.preventDefault();
    togglePlay();
    onClick(e);
  };

  const handlePlayButtonClick = () => {
    const video = videoRef.current;

    delay(() => {
      if (video) {
        if (controls) {
          setShowControls(true);
        }

        video.play();
        setIsPlayButtonVisible(true);
      }
    }, 100);
  };

  if (!cloudId) null;

  return (
    <div className={`${className} ${classes.root}`}>
      <video
        ref={createVideoRef}
        poster={poster || defaultPoster}
        controls={showControls}
        className={`${classes.video} ${classes[theme]}`}
        height={videoTransformations.height}
        width={videoTransformations.width}
        controlsList="nodownload"
        onClick={handleVideoClick}
        {...rest}
      >
        <source src={source} type={`video/${videoExtension}`} />
      </video>

      {showPlayIcon && !isPlayButtonVisible && (
        <IconButton
          className={classes.playButton}
          onClick={handlePlayButtonClick}
        >
          <IconPlay viewBox={iconViewBox} />
        </IconButton>
      )}
    </div>
  );
};

export default forwardRef(Video);
