const Pinterest = () => (
  <>
    <g>
      <path
        data-name="Path 16956"
        d="M100,0C45,0,0,45,0,100c0,42.5,26.667,78.333,63.333,93.333-.833-7.5-1.667-20,0-28.333C65,157.5,75,115,75,115a47.375,47.375,0,0,1-2.5-15c0-14.167,8.333-24.167,18.333-24.167,8.333,0,12.5,6.667,12.5,14.167,0,8.333-5.833,21.667-8.333,33.333-2.5,10,5,18.333,15,18.333,17.5,0,31.667-18.333,31.667-45.833C141.667,71.667,124.167,55,100,55,71.667,55,55,76.667,55,98.333c0,8.333,3.333,17.5,7.5,22.5a3.061,3.061,0,0,1,.833,2.5c-.833,3.333-2.5,10-2.5,11.667-.833,1.667-1.667,2.5-3.333,1.667-12.5-5.833-20-24.167-20-38.333C37.5,66.667,60,37.5,103.333,37.5c35,0,61.667,25,61.667,57.5,0,34.167-21.667,62.5-51.667,62.5-10,0-20-5-23.333-11.667l-5.833,23.333A106.352,106.352,0,0,1,71.667,195c7.5,3.333,17.5,5,28.333,5,55,0,100-45,100-100C199.167,45,155,0,100,0Z"
        transform="translate(0 -0.223)"
      />
    </g>
  </>
);

export default Pinterest;
