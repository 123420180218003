const Compass = () => (
  <>
    <g>
      <g transform="translate(-199 -571)">
        <path
          d="M230,200c-55,0-100-45-100-100S175,0,230,0,330,45,330,100,285,200,230,200ZM280,50,231.25,72.5C220,77.5,207.5,90,202.5,101.25L180,150l48.75-22.5c11.25-5,23.75-17.5,28.75-28.75Z"
          transform="translate(69 571)"
        />
      </g>
    </g>
  </>
);

export default Compass;
