export const throttleTime = 1000;

export const inativityTime = 1000;

export const noResultsOption = [
  { description: "Location not found", disabled: true },
];

export const getPlaceValue = (place) => {
  if (place?.name) {
    return place.name;
  }

  const { main_text, secondary_text } = place?.structured_formatting || {};
  const formattedPlace = secondary_text
    ? `${main_text}, ${secondary_text}`
    : main_text || place?.description;

  return formattedPlace;
};
