const User = () => (
  <>
    <g>
      <g transform="translate(-342 5.563)">
        <path
          d="M76.217,115.394c26.333,0,47.667-33.334,47.667-59.557a47.667,47.667,0,1,0-95.333,0C28.55,82.149,49.883,115.394,76.217,115.394Z"
          transform="translate(365.787 0.687)"
        />
        <path
          d="M149,51.07h-2.444C135,65.893,120.311,74.07,105,74.07s-30-8.178-41.533-23H61a56,56,0,0,0-56,56V141.65H205V107.071a56,56,0,0,0-56-56Z"
          transform="translate(337 53.122)"
        />
      </g>
    </g>
  </>
);

export default User;
