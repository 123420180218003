const FaceSad = () => (
  <>
    <g>
      <path
        d="M101,1A100,100,0,1,0,201,101,100,100,0,0,0,101,1Zm0,191.667A91.667,91.667,0,1,1,192.667,101,91.667,91.667,0,0,1,101,192.667Zm44.708-39.917a4.17,4.17,0,0,1-6.042,5.75c-1.417-1.458-34.792-35.5-77.625.333a4.167,4.167,0,0,1-5.333-6.375C105.625,111.5,145.292,152.333,145.708,152.75ZM59.333,76a12.5,12.5,0,1,1,12.5,12.5A12.5,12.5,0,0,1,59.333,76Zm58.333,0a12.5,12.5,0,1,1,12.5,12.5A12.5,12.5,0,0,1,117.667,76Z"
        transform="translate(-1 -1)"
      />
    </g>
  </>
);

export default FaceSad;
