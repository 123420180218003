import { useRef, useState, useEffect } from "react";
import { useRouter } from "next/router";
import algoliasearch from "algoliasearch/lite";
import { connectHits, InstantSearch } from "react-instantsearch-dom";

import { toRenderProps, getRegionCover } from "@/lib/utils";
import { uploadRemoteSource } from "@/lib/useCloudinary";
import { algoliaId, algoliaSearchOnlyAPIKey } from "@/config";
import { Image } from "@/ui";

import { coverImageTransformations } from "./utils";
import SearchInput from "./SearchInput";
import Results from "./Results";

import useStyles from "./styles";

const ConnectHits = toRenderProps(connectHits);
const searchClient = algoliasearch(algoliaId, algoliaSearchOnlyAPIKey);

const SearchBox = (props) => {
  const { autoFocus, indexName, placeholder } = props;
  const router = useRouter();
  const searchbox = useRef();
  const classes = useStyles({ classes: props.classes });

  const [showResults, setShowResults] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [totalHits, setTotalHits] = useState(0);

  const { term = "" } = router.query;

  const toggleResultsDebounced = (value) => {
    setTimeout(() => {
      setShowResults(value);
    }, 300);
  };

  const moveDown = () => {
    if (currentIndex < totalHits) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const moveUp = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const goToResults = () => {
    router.push(searchTerm ? `/results?term=${searchTerm}&page=1` : "/results");
    setShowResults(false);
    setInputValue("");
  };

  const handleMenuKeyDown = (e) => {
    if (!showResults) return;

    switch (e.keyCode) {
      case 38: // key up
        moveUp();
        break;

      case 40: // key down
        moveDown();
        break;

      default:
        setCurrentIndex(0);
        break;
    }
  };

  const handleInputChange = ({ value }) => {
    setCurrentIndex(0);
    setInputValue(value);
    setSearchTerm(value);
    toggleResultsDebounced(value.length > 0);
  };

  const handleInputBlur = () => {
    toggleResultsDebounced(false);
    setInputValue("");
  };

  const handleInputFocus = () => {
    setSearchTerm("");
  };

  const handleInputReset = () => {
    setInputValue("");
    setSearchTerm("");
    setCurrentIndex(0);
    setShowResults(false);
    if (term) router.push("/results");
  };

  const renderImage = (hit) => {
    const { cloudId, tripTitle, tripPhoto, region } = hit;
    const imageSrc = tripPhoto || getRegionCover(region);

    return (
      <div className={classes.resultImage}>
        <Image
          alt={tripTitle || ""}
          cloudId={cloudId}
          transformations={coverImageTransformations}
          src={uploadRemoteSource(imageSrc, coverImageTransformations)}
        />
      </div>
    );
  };

  useEffect(() => {
    if (term) setSearchTerm(term);
  }, [term, setSearchTerm]);

  return (
    <div className={`${classes.root} ${inputValue ? classes.isActive : ""}`}>
      <InstantSearch searchClient={searchClient} indexName={indexName}>
        <div
          className={classes.instantSearch}
          onKeyDown={handleMenuKeyDown}
          ref={searchbox}
          role="presentation"
        >
          <SearchInput
            classes={classes}
            autoFocus={autoFocus}
            inputValue={inputValue}
            onBlur={handleInputBlur}
            onChange={handleInputChange}
            onSearch={goToResults}
            onFocus={handleInputFocus}
            onReset={handleInputReset}
            placeholder={placeholder}
            routerTerm={term}
            searchAsYouType={false}
          />
          {showResults && (
            <ConnectHits>
              {({ hits }) => (
                <Results
                  classes={classes}
                  hits={hits}
                  searchTerm={searchTerm}
                  onSearch={goToResults}
                  renderImage={renderImage}
                  selected={currentIndex}
                  setTotalHits={setTotalHits}
                />
              )}
            </ConnectHits>
          )}
        </div>
      </InstantSearch>
    </div>
  );
};

SearchBox.defaultProps = {
  placeholder: "Search Itineraries",
  searchListParser: () => ({}),
  onClickSearchResult: () => {},
  value: "",
};

export default SearchBox;
