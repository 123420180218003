const Camera = () => (
  <>
    <g>
      <g transform="translate(-0.838 9.563)">
        <g data-name="Group 5395" transform="translate(0.838 13)">
          <path
            data-name="Path 17649"
            d="M191.386,35.893V31.73a7.557,7.557,0,0,0-2.5-5.827,7.557,7.557,0,0,0-5.827-2.5H168.493a7.557,7.557,0,0,0-5.827,2.5,7.557,7.557,0,0,0-2.5,5.827v3.746H143.519L129.367,16.33a8.689,8.689,0,0,0-6.66-3.33H81.084a8.391,8.391,0,0,0-6.868,3.538L60.273,35.893H14.487a12.44,12.44,0,0,0-8.949,3.746A12.268,12.268,0,0,0,2,48.38V156.6a9.762,9.762,0,0,0,3.122,7.284,9.762,9.762,0,0,0,7.284,3.122H189.3a12.44,12.44,0,0,0,8.949-3.746A12.576,12.576,0,0,0,202,154.311V48.172A12.706,12.706,0,0,0,191.386,35.893ZM168.493,31.73h14.568v3.954H168.493Zm24.974,122.789a4.268,4.268,0,0,1-4.162,4.162H12.406a1.889,1.889,0,0,1-1.457-.624,1.889,1.889,0,0,1-.624-1.457V48.38a4.268,4.268,0,0,1,4.162-4.162H60.273A8.391,8.391,0,0,0,67.14,40.68L81.084,21.325h41.623l14.152,19.147a8.689,8.689,0,0,0,6.66,3.33l45.786.208a4.175,4.175,0,0,1,4.162,4.162Z"
            transform="translate(-2 -13)"
          />
          <path
            data-name="Path 17650"
            d="M40.055,34H17.162a4.162,4.162,0,1,0,0,8.325H40.055a4.162,4.162,0,0,0,0-8.325Z"
            transform="translate(9.893 9.704)"
          />
          <path
            data-name="Path 17651"
            d="M70.11,36.9a38.71,38.71,0,1,0,38.71,38.71A38.77,38.77,0,0,0,70.11,36.9Zm0,68.678A29.969,29.969,0,1,1,100.078,75.61,29.976,29.976,0,0,1,70.11,105.578Z"
            transform="translate(29.786 12.84)"
          />
        </g>
      </g>
    </g>
  </>
);

export default Camera;
