import { useCallback } from "react";

import useCurrentUser from "@/lib/useCurrentUser";
import { Nav, NavItem } from "@/ui";

import { headerNav } from "./constants";

import useStyles from "./styles";

const MobileMainNav = ({ toggleNav, changeSlide }) => {
  const classes = useStyles();
  const { isAuthenticated: isLoggedIn } = useCurrentUser();

  const handleClickNavItem = useCallback(
    (e, { hasSubMenu } = {}) => {
      if (hasSubMenu) {
        e.preventDefault();
        changeSlide(1);
      }
    },
    [changeSlide]
  );

  return (
    <>
      <Nav
        classes={{
          activeLink: classes.mobileArrowLink,
          itemIcon: classes.itemIcon,
          link: classes.link,
        }}
        iconSize="0 0 20 20"
        onClick={handleClickNavItem}
        iconActiveClass={classes.itemIcon}
        activeItem="megaMenu"
        navItems={headerNav}
        color="contrast"
      />

      <footer className={classes.mobileFooter}>
        {isLoggedIn ? (
          <>
            <NavItem
              classes={{
                itemIcon: classes.itemIcon,
                link: classes.link,
              }}
              text="Profile"
              link="/profile/[view]"
              as="/profile/edit"
              onClick={toggleNav}
            />
            <NavItem
              classes={{
                itemIcon: classes.itemIcon,
                link: classes.link,
              }}
              text="My Trip Reports"
              link="/profile/[view]"
              as="/profile/reports"
              onClick={toggleNav}
            />
            <NavItem
              classes={{
                itemIcon: classes.itemIcon,
                link: classes.link,
              }}
              text="Logout"
              link="/api/auth/logout"
              onClick={toggleNav}
            />
          </>
        ) : (
          <NavItem
            classes={{
              itemIcon: classes.itemIcon,
              link: classes.link,
            }}
            text="Sign In"
            link="/api/auth/login"
          />
        )}
      </footer>
    </>
  );
};

export default MobileMainNav;
