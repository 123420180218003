import { makeStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  root: {
    position: "relative",
    overflow: "hidden",

    "&:hover": {
      boxShadow: theme.atlasGuru.boxShadow({
        color: theme.atlasGuru.boxShadowHighlight,
        opacity: 0.7,
      }),
    },
  },

  header: {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 20,

    "&:before, & img": {
      transform: "scale(1)",
      transformOrigin: "50%",
      transition: theme.transitions.create("transform", {
        duration: 200,
        easing: theme.transitions.easing.easeIn,
      }),
    },

    "&:before": {
      pointerEvents: "none",
      background: `radial-gradient(closest-side,
        rgba(255,255,255,.95) 0%,
        rgba(255,255,255,.8) 40%,
        rgba(255,255,255,0) 100%
      )`,
      content: "''",
      display: "block",
      width: 380,
      height: 90,
      left: `-${theme.spacing(16.5)}px`,
      position: "absolute",
      top: `-${theme.spacing(4)}px`,
      zIndex: 0,
    },

    "&:hover": {
      "&:before": {
        transform: "scale(1.2)",
      },

      "& img": {
        transform: "scale(1.2)",
      },

      "& $userLink span": {
        color: theme.palette.common.black,
      },
    },
  },

  userWrapper: {
    display: "flex",
    alignItems: "flex-start",
    background: "transparent",
    flexDirection: "column-reverse",
    padding: 0,
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    position: "relative",
    zIndex: 10,
  },

  userLink: {},

  userName: {
    marginBottom: "0.2em",
  },

  avatar: {
    width: 50,
    height: 50,

    [theme.breakpoints.only("sm")]: {
      width: 38,
      height: 38,
    },
  },

  cardLink: {
    display: "block",

    "&:hover": {
      "& $title": {
        color: theme.palette.common.highlight,
      },
    },

    "& $hero, & $container": {
      pointerEvents: "none",
    },
  },

  hero: {
    position: "relative",
  },

  headline: {
    ...theme.typography.jumbo,
    color: theme.palette.common.white,
    textShadow: `1px 1px 5px ${theme.palette.common.black}99`,
    backgroundColor: `${theme.palette.common.black}33`,
    display: "flex",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "space-around",
    padding: "1em",
    position: "absolute",
    height: "100%",
    width: "100%",
    left: 0,
    top: 0,
  },

  picture: {
    "& img": {
      display: "block",
      aspectRatio: "16/9",
    },
  },

  container: {
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(
      4
    )}px`,
  },

  content: {
    color: theme.palette.common.black,
    padding: 0,
  },

  title: {},
});

export default makeStyles(styles);
