const Share = () => (
  <>
    <path
      d="M15.0039 2.4967L17.9993 5.49502L14.9981 8.4992M17.9979 5.49792H12.9958C10.7858 5.49792 8.9941 7.28953 8.9941 9.4996V9.9998M18.9997 10.5V14.5016C18.9997 17.2642 16.7602 19.5037 13.9976 19.5037H5.99428C3.2317 19.5037 0.992188 17.2642 0.992188 14.5016V6.4983C0.992188 3.73572 3.2317 1.49622 5.99428 1.49622H9.9959"
      stroke="#091C29"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);

export default Share;
