import { gql } from "@apollo/client";

// FRAGMENTS

export const HOMEPAGE_FRAGMENT = gql`
  fragment HomepageData on Homepage {
    heroItinerary {
      itinerary {
        itineraryId
        tripTitle
        shortDescription
        url
        regionName
        tripPhotos {
          cloudId
          isHero
          caption
        }
        locationsVisited {
          location {
            name
          }
          tripPhotos {
            photoUrl
            cloudId
            isHero
            caption
          }
        }
        userProfile {
          screenName
          profilePicUrl
          userId
          cloudId
        }
      }
      customPhoto {
        mobile {
          cloudId
          photoUrl
          photoFilter
        }
        desktop {
          cloudId
          photoUrl
          photoFilter
        }
      }
    }
    featuredItineraries {
      itineraryId
      slug
      url
      tripTitle
      numOfDays
      lowlight
      highlight
      shortDescription
      regionName
      countries {
        name
      }
      locationsVisited {
        tripPhotos {
          photoUrl
          caption
          cloudId
          isHero
        }
        location {
          name
          formatted_address
          geometry {
            location {
              lat
              lng
            }
          }
        }
      }
      userProfile {
        profilePicUrl
        cloudId
        screenName
        userId
        partner {
          id
          name
          logo
        }
      }
      tripPhotos {
        photoUrl
        caption
        cloudId
        isHero
      }
    }
    guruItineraries {
      itineraryId
      tripTitle
      slug
      regionName
      numOfDays
      partner {
        url
        linkText
        headline
      }
      countries {
        name
      }
      userProfile {
        profilePicUrl
        cloudId
        screenName
        userId
      }
      tripPhotos {
        photoUrl
        caption
        cloudId
        isHero
      }
      locationsVisited {
        tripPhotos {
          photoUrl
          caption
          cloudId
          isHero
        }
      }
    }
    qAndAPanel {
      question
      qAndAs {
        question
        questionAndAnswerId
        answer
        countries
        slug
        url
        displayOrder
        itineraryId
        userProfile {
          cloudId
          screenName
          profilePicUrl
          userId
        }
      }
    }
  }
`;

// QUERIES

export const HOMEPAGE_QUERY = gql`
  query HOMEPAGE_QUERY {
    GetHomepage {
      ...HomepageData
    }
  }
  ${HOMEPAGE_FRAGMENT}
`;

export const HOMEPAGE_QUERY_NO_CACHE = gql`
  query HOMEPAGE_QUERY_NO_CACHE {
    GetHomepage {
      ...HomepageData
    }
  }
  ${HOMEPAGE_FRAGMENT}
`;

export const HOMEPAGE_DRAFT_QUERY_NO_CACHE = gql`
  query HOMEPAGE_DRAFT_QUERY_NO_CACHE {
    GetHomepageDraft {
      unpublishedChangesExist
      ...HomepageData
    }
  }
  ${HOMEPAGE_FRAGMENT}
`;

// MUTATIONS

export const PUBLISH_HOMEPAGE = gql`
  mutation {
    PublishHomepageContentDraft {
      unpublishedChangesExist
    }
  }
`;

export const UPSERT_HOMEPAGE_DRAFT_ITINERARIES = gql`
  mutation ($input: [HomepageItineraryInput]) {
    UpsertHomepageDraftItineraries(input: $input) {
      unpublishedChangesExist
    }
  }
`;

export const UPSERT_HOMEPAGE_DRAFT_QANDA = gql`
  mutation ($input: QAndAPanelInput) {
    UpsertHomepageDraftQAndAPanel(input: $input) {
      unpublishedChangesExist
    }
  }
`;
