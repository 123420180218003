import { useCallback, useEffect, useRef, useState } from "react";

const safeDocument = typeof document !== "undefined" ? document : {};

const useScroll = ({ onScroll = null, trackScroll = null } = {}) => {
  const scrollBlocked = useRef();
  const [scrollTop, setScrollTop] = useState(0);
  const [direction, setDirection] = useState("");
  const [isTrackerActive, setIsTrackerActive] = useState(trackScroll);
  const { body } = safeDocument;
  const html = safeDocument.documentElement;

  const bounds = {
    scrollTop,
    direction,
  };

  const scrollTo = (x, y) => {
    if (window?.scrollTo) {
      window.scrollTo(x, y);
      setScrollTop(y);
    }
  };

  const resetScroll = () => {
    if (window?.history) {
      window.history.scrollRestoration = "manual";
    }
  };

  const enableScroll = () => {
    if (!body?.style || !scrollBlocked.current) return;

    body.style.overflow = "";
    body.style.paddingRight = "";
    scrollBlocked.current = false;

    scrollTo(0, scrollTop);
  };

  const disableScroll = () => {
    if (!body?.style || scrollBlocked.current) return;

    const y = window.pageYOffset || html.scrollTop;
    const scrollBarWidth = window.innerWidth - html.clientWidth;
    const bodyPaddingRight =
      parseInt(
        window.getComputedStyle(body).getPropertyValue("padding-right")
      ) || 0;

    body.style.overflow = "hidden";
    body.style.paddingRight = `${bodyPaddingRight + scrollBarWidth}px`;
    body.style.backgroundColor = "#F0F0EC"; // TODO: update body css property to this color
    scrollBlocked.current = true;

    scrollTo(0, y);
  };

  const observeScroll = useCallback(() => {
    if (window?.addEventListener && !isTrackerActive) {
      setIsTrackerActive(true);
      // console.log("useScroll::observeScroll:: ");
    }
  }, [isTrackerActive]);

  const unobserveScroll = useCallback(() => {
    if (window?.removeEventListener && isTrackerActive) {
      setIsTrackerActive(false);
      setDirection("");
      // console.log("useScroll::unobserveScroll:: ");
    }
  }, [isTrackerActive]);

  const handleScrollChange = useCallback(
    (e) => {
      const st = window.pageYOffset || safeDocument.documentElement.scrollTop;
      const direction = st > scrollTop ? "down" : "up";

      setScrollTop(st <= 0 ? 0 : st);

      if (scrollTop > 0) {
        setDirection(direction);
      }

      if (typeof onScroll === "function") {
        onScroll(e);
      }

      // console.log("useScroll::handleScrollChange:: ", st, scrollTop);
    },
    [onScroll, scrollTop]
  );

  // start tracking the scroll when there is a callback function defined
  useEffect(() => {
    if (typeof onScroll === "function") {
      observeScroll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // active or desactive the scroll depends of the isTrackerActive flag
  useEffect(() => {
    if (isTrackerActive !== null) {
      const scrollEventHandler = (e) => {
        handleScrollChange(e);
      };

      if (isTrackerActive) {
        window.addEventListener("scroll", scrollEventHandler);
      }

      return () => {
        window.removeEventListener("scroll", scrollEventHandler);
      };
    }
  }, [handleScrollChange, scrollTop, isTrackerActive]);

  return {
    scrollTo,
    resetScroll,
    disableScroll,
    enableScroll,
    observeScroll,
    unobserveScroll,
    isTrackerActive,
    bounds,
  };
};

export default useScroll;
