const Calendar = () => (
  <>
    <g>
      <path
        d="M25 62.502V175h150V62.502ZM162.502 25h25A11.809 11.809 0 0 1 200 37.502v150A11.809 11.809 0 0 1 187.502 200H12.502A11.809 11.809 0 0 1 0 187.502V37.502A11.809 11.809 0 0 1 12.502 25h25V12.502A11.809 11.809 0 0 1 50 0a11.809 11.809 0 0 1 12.502 12.502V25H137.502V12.502a12.502 12.502 0 0 1 25 0ZM150 150H125V125H150Zm-37.498 0H87.502V125h25ZM150 112.502H125V87.502H150Zm-37.498 0H87.502V87.502h25ZM75 150H50V125h25Z"
        fillRule="evenodd"
      />
    </g>
  </>
);

export default Calendar;
