import { useCallback, useState, useEffect } from "react";

import { Link } from "@/ui";
import { IconArrowThin } from "@/icons";

import { megaNav } from "./constants";

import useStyles from "./styles";

const subNavItems = {
  ...megaNav.locations.items,
  others: {
    items: {
      ...megaNav.categories.items,
      ...megaNav.topics.items,
    },
  },
};

const MobileMegaSubNav = ({ regionToShow, changeSlide, toggleNav }) => {
  const classes = useStyles();
  const [megaMenuItems, setMegaMenuItems] = useState();

  const handleNavItemChange = useCallback(
    (slideIndex) => (e) => {
      e.preventDefault();
      changeSlide(slideIndex);
    },
    [changeSlide]
  );

  useEffect(() => {
    const regionItems = subNavItems[regionToShow]?.items || {};

    const items = Object.keys(regionItems).map((key) => (
      <Link
        key={key}
        className={classes.link}
        href={regionItems[key].url}
        onClick={toggleNav}
      >
        {regionItems[key].label}
      </Link>
    ));

    setMegaMenuItems(items);
  }, [classes.link, regionToShow, toggleNav]);

  return (
    <nav>
      <span
        onClick={handleNavItemChange(1)}
        className={`${classes.link} ${classes.backBtn}`}
      >
        <IconArrowThin variant="down" viewBox="0 0 20 20" />
        Back
      </span>

      {megaMenuItems}
    </nav>
  );
};

export default MobileMegaSubNav;
