import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "../Checkbox";

import useStyles from "./styles.js";

const CheckboxGroup = ({
  classes = {},
  className = "",
  error = "",
  items = [],
  keyName = "label",
  legend = "",
  name = "",
  onChange = () => {},
  onFocus = () => {},
  row = true,
  variant = "default",
  value = [],
  ...rest
}) => {
  const styles = useStyles(classes);
  const hasError = !!error;

  return (
    <FormControl
      component="fieldset"
      className={`${className} ${styles.root}`}
      error={hasError}
    >
      {legend && (
        <FormLabel className={styles.legend} component="legend">
          {legend}
        </FormLabel>
      )}
      <FormGroup row={row} className={styles.formGroup}>
        {items.map((item, i) => (
          <Checkbox
            className={styles.checkbox}
            checked={value.includes(item.value)}
            error={error}
            key={i}
            label={item[keyName]}
            name={name}
            value={String(item.value)}
            onChange={onChange}
            onFocus={onFocus}
            variant={variant}
            {...rest}
          />
        ))}
      </FormGroup>
      {hasError && (
        <FormHelperText
          className={styles.errorMsg}
          component="small"
          id={`field-error-${name}`}
        >
          {error}
        </FormHelperText>
      )}
    </FormControl>
  );
};

CheckboxGroup.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  error: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object])
  ),
  keyName: PropTypes.string,
  legend: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.element,
  ]),
  name: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  row: PropTypes.bool,
  variant: PropTypes.string,
  value: PropTypes.array,
};

export default CheckboxGroup;
