import { Pagination as MuiPagination, PaginationItem } from "@material-ui/lab";

import useStyles from "./styles";

const Pagination = (props) => {
  const classes = useStyles();

  return (
    <MuiPagination
      renderItem={({ disabled, selected, ...item }) => (
        <PaginationItem
          classes={{
            icon: classes.icon,
            page: `${classes.page} ${selected ? classes.selected : ""} ${
              disabled ? classes.disabled : ""
            }`,
          }}
          disabled={disabled}
          selected={selected}
          {...item}
        />
      )}
      {...props}
    />
  );
};

export default Pagination;
