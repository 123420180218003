const RatingStarHalf = () => (
  <g transform="translate(0, 2)">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.99983 10.325L8.87976 11.9044C9.43995 12.2122 10.0952 11.715 9.98844 11.0639L9.43849 7.71587L11.7685 5.34531C12.2219 4.88351 11.9718 4.07844 11.3443 3.98301L8.1265 3.49504L6.6858 0.445635C6.40498 -0.148545 5.59467 -0.148545 5.31531 0.445635L3.87607 3.49504L0.655343 3.98455C0.0293331 4.07998 -0.222241 4.88505 0.231177 5.34685L2.56116 7.71741L2.01121 11.0654C1.90444 11.7166 2.5597 12.2153 3.11989 11.9059L5.99983 10.3266"
      fill="url(#paint0_linear_1445_4021)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1445_4021"
        x1="4"
        y1="6"
        x2="14"
        y2="6"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.19" stopColor="#C7AB50" />
        <stop offset="0.2375" stopColor="#E1DED3" />
      </linearGradient>
    </defs>
  </g>
);

export default RatingStarHalf;
