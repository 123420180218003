import { withStyles } from "@material-ui/core/styles";
import ImageList from "@material-ui/core/ImageList";
import ImageListItem from "@material-ui/core/ImageListItem";

import {
  DEFAULT_IMAGE_CAPTION,
  DEFAULT_TRANSFORMATIONS,
  CUSTOM_CROP_TRANSFORMATIONS,
} from "@/lib/constants";
import { buildUrl, checkIsVideoCloudId } from "@/lib/useCloudinary";
import { IconPlay } from "@/icons";

import Image from "../Image";
import Controls from "./Controls";

import styles from "./styles";

const postImageTransformations = {
  ...DEFAULT_TRANSFORMATIONS,
  crop: "fill",
  gravity: "auto:subject",
  height: 240,
  width: 320,
};

const generateUrl = (cloudId) =>
  buildUrl(
    cloudId,
    { ...CUSTOM_CROP_TRANSFORMATIONS, fetchFormat: "auto" },
    postImageTransformations
  );

const GridMedia = ({
  rowHeight = 150,
  children = null,
  classes = {},
  cols = 2,
  isEditable = false,
  items = [],
  onClick = () => {},
  onDelete = () => {},
  onEditCaption = () => {},
  onMainPhotoChange = () => {},
  controlFields = ["mainPhoto", "edit", "caption", "delete"],
  disableEdit = false,
}) => (
  <ImageList rowHeight={rowHeight} className={classes.gridList} cols={cols}>
    {(items || []).map((item, i) => (
      <ImageListItem
        key={item.cloudId}
        classes={{
          root: classes.gridListItemRoot,
          item: classes.gridListItem,
        }}
      >
        <figure
          className={classes.figure}
          style={{ backgroundImage: `url("${generateUrl(item.cloudId)}")` }}
          onClick={(e) => onClick(e, item.cloudId || item.photoSlot)}
          role="presentation"
        >
          <Image
            src={generateUrl(item.cloudId)}
            crossOrigin="anonymous"
            className={classes.image}
            // transformations={item.transformations}
            alt={item.alt || item.caption || DEFAULT_IMAGE_CAPTION}
          />

          {checkIsVideoCloudId(item.cloudId) && (
            <IconPlay
              className={classes.videoPlayIcon}
              viewBox="0 0 67px 67px"
            />
          )}

          {item.caption && (
            <figcaption className={classes.figCaption}>
              {item.caption}
            </figcaption>
          )}
        </figure>

        {isEditable && (
          <Controls
            classes={classes}
            index={i}
            item={item}
            onDelete={() => onDelete(i)}
            onEditCaption={onEditCaption}
            onMainPhotoChange={onMainPhotoChange}
            showFields={controlFields}
            disableEdit={disableEdit}
            isVideo={checkIsVideoCloudId(item.cloudId)}
          />
        )}
      </ImageListItem>
    ))}

    {children && (
      <ImageListItem
        classes={{
          root: classes.gridListItemRoot,
          item: classes.gridListItem,
        }}
      >
        {children}
      </ImageListItem>
    )}
  </ImageList>
);

export default withStyles(styles)(GridMedia);
