import { useState, useRef } from "react";
// app
import { trimText } from "@/lib/utils";
import { Placeholder } from "@/decorations";
import { IconArrow, IconHelp, IconUser } from "@/icons";
import { Avatar, Button, Link, Popper } from "@/ui";
import useCurrentUser from "@/lib/useCurrentUser";
// local
import UserNav from "./UserNav";
import useStyles from "./styles";
import { PLACEHOLDER_AVATAR } from "@/lib/constants";
import classNames from "classnames";

const UserMenu = ({ isNotificationShown = false }) => {
  const { user, loading } = useCurrentUser();
  const classes = useStyles();
  const anchorRef = useRef(null);
  const [menuButtonEl, setMenuButtonEl] = useState(null);
  const isMenuOpen = Boolean(menuButtonEl);

  const {
    screenName = "",
    profilePicUrl = PLACEHOLDER_AVATAR,
    cloudId,
    isAdmin,
  } = user || {};
  const username = screenName || "";
  const displayname = trimText(username, 20);

  // toggle user menu when the button is clicked
  const handleOpenMenu = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();

    const target = evt.target || evt.currentTarget;
    setMenuButtonEl(menuButtonEl ? null : target);
  };

  // close user menu
  const handleCloseMenu = () => {
    setMenuButtonEl(null);
  };

  if (loading) {
    return null;
  }

  return (
    <div className={classes.root}>
      {user ? (
        <div className={classes.userContent}>
          <div className={classes.userInfo} ref={anchorRef}>
            {isNotificationShown && (
              <IconHelp
                className={classNames(classes.icon, classes.iconHelp)}
              />
            )}

            {!screenName && (
              <span className={classNames(classes.icon, classes.iconAvatar)}>
                <Placeholder
                  size={35}
                  width={35}
                  rowSize={35}
                  borderRadius={17}
                  backgroundColor="#C4C4C4"
                />
              </span>
            )}

            {screenName && (
              <Avatar
                className={classNames(classes.icon, classes.iconAvatar)}
                transformations={{ width: 50, height: 50 }}
                onClick={handleOpenMenu}
                src={profilePicUrl}
                alt={screenName}
                cloudId={cloudId}
              />
            )}

            <Button className={classes.toggle} onClick={handleOpenMenu}>
              <span className={classes.name}>
                {displayname || (
                  <div className={classes.ellipses}>
                    <Placeholder
                      size={6}
                      width={6}
                      rowSize={6}
                      borderRadius={3}
                      backgroundColor="#BBB"
                    />
                    <Placeholder
                      size={6}
                      width={6}
                      rowSize={6}
                      borderRadius={3}
                      backgroundColor="#BBB"
                    />
                    <Placeholder
                      size={6}
                      width={6}
                      rowSize={6}
                      borderRadius={3}
                      backgroundColor="#BBB"
                    />
                    <Placeholder
                      size={6}
                      width={6}
                      rowSize={6}
                      borderRadius={3}
                      backgroundColor="#BBB"
                    />
                  </div>
                )}
              </span>
              {isMenuOpen ? (
                <IconArrow variant="up" />
              ) : (
                <IconArrow variant="down" />
              )}
            </Button>

            <Popper
              disablePortal
              anchorEl={anchorRef.current}
              onClose={handleCloseMenu}
              placement="bottom-end"
              showCloseBtn={false}
              open={isMenuOpen}
              classes={{
                root: classes.userNavMenu,
                popperContent: classes.userNavContent,
              }}
            >
              <div onClick={handleCloseMenu}>
                <UserNav isAdmin={isAdmin} />
              </div>
            </Popper>
          </div>
        </div>
      ) : (
        <div className={classes.userAccess}>
          <Link
            className={classNames(classes.link, classes.signin)}
            href="/api/auth/login"
            title="Sign In"
          >
            <IconUser className={classes.iconUser} />
            <span className={classes.loginText}>Sign In</span>
          </Link>

          <Link
            className={classes.link}
            href="/api/auth/login?screen_hint=signup"
            title="Signup"
          >
            <Button
              className={classes.button}
              color="secondary"
              variant="contained"
            >
              Sign up
            </Button>
          </Link>
        </div>
      )}
    </div>
  );
};

export default UserMenu;
