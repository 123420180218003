import { initializeApollo } from "@/lib/apolloClient";

import {
  AI_ITINERARY_UPSERT,
  AI_ITINERARY_DELETE,
  AI_ITINERARY_STATUS_NO_CACHE,
  AI_ITINERARY_QUERY_NO_CACHE,
  AI_ITINERARIES_QUERY_NO_CACHE,
} from "./gql";

// mutation that creates the generated AI itinerary
export const upsertAiItinerary = async (input) => {
  try {
    const client = initializeApollo();
    const itineraryAi = await client.mutate({
      mutation: AI_ITINERARY_UPSERT,
      variables: { input },
    });
    return itineraryAi;
  } catch (error) {
    return error;
  }
};

// mutation that helps to delete itineraries
export const deleteAiItinerary = async (itineraryId) => {
  try {
    const client = initializeApollo();
    await client.mutate({
      mutation: AI_ITINERARY_DELETE,
      variables: { itineraryId },
    });
  } catch (error) {
    return error;
  }
};

// query that returns the creation status of the AI Itinerary
export const getAiItineraryStatus = async (itineraryId = "", options = {}) => {
  // api request
  try {
    const { context = {} } = options || {};
    const client = initializeApollo({ context });

    return await client.query({
      query: AI_ITINERARY_STATUS_NO_CACHE,
      variables: { itineraryId },
      fetchPolicy: "network-only",
    });
  } catch (error) {
    return {
      notFound: true,
    };
  }
};

// query that returns the data of the AI itinerary
export const fetchAiItinerary = async (
  itineraryId = "",
  options = {},
  context
) => {
  // api request
  try {
    const client = initializeApollo({ context });

    const data = await client.query({
      query: AI_ITINERARY_QUERY_NO_CACHE,
      variables: { itineraryId },
      ...options,
    });

    return {
      data,
      client,
    };
  } catch (error) {
    return {
      notFound: true,
    };
  }
};

// query that returns a list of itineraries generated by AI
export const fetchAiItineraries = async (userId = null, options = {}) => {
  // api request
  try {
    const { context = {} } = options || {};
    const client = initializeApollo({ context });

    const response = await client.query({
      query: AI_ITINERARIES_QUERY_NO_CACHE,
      fetchPolicy: "network-only",
      variables: { userId },
    });
    return response?.data?.Itineraries;
  } catch (error) {
    return {
      notFound: true,
    };
  }
};
