const FacebookLight = () => (
  <>
    <g id="Facebook" data-name="Icon">
      <path d="M106.91,141.7V101.24h14l2-15.78h-16V75.59c0-4.44,1.5-7.89,8-7.89h8.5V53.38c-2,0-7-.49-12.5-.49-12,0-20.52,7.4-20.52,20.73V85.46h-14v15.78h14V141.7Z" />
      <path d="M100,5A95.77,95.77,0,0,0,80.84,6.94,94.8,94.8,0,0,0,63,12.47a95.16,95.16,0,0,0-30.2,20.36,94,94,0,0,0-11.6,14.06,94.42,94.42,0,0,0-14.29,34,96.17,96.17,0,0,0,0,38.29A94.8,94.8,0,0,0,12.47,137a95.78,95.78,0,0,0,8.76,16.14,94.85,94.85,0,0,0,59.62,40,96,96,0,0,0,38.3,0A94.8,94.8,0,0,0,137,187.52a95.78,95.78,0,0,0,16.14-8.76,94.85,94.85,0,0,0,39.95-59.62,96,96,0,0,0,0-38.3A94.8,94.8,0,0,0,187.53,63a95.16,95.16,0,0,0-20.36-30.2,94.67,94.67,0,0,0-48-25.89A96.68,96.68,0,0,0,100,5Zm0-5A100,100,0,1,1,0,100,100,100,0,0,1,100,0Z" />
    </g>
  </>
);

export default FacebookLight;
