import { Link } from "@/ui";

import useStyles from "./styles";

const MegaSubNav = ({ data = {}, classes: rootClasses = {} }) => {
  const classes = useStyles({ classes: rootClasses });

  const renderItems = (items) => {
    return Object.keys(items).map((key) => (
      <li key={key}>
        {items[key].label && (
          <Link href={items[key].url}>{items[key].label}</Link>
        )}
        {items[key].items && <MegaSubNav data={items[key]} />}
      </li>
    ));
  };

  return (
    <div className={classes.megaSubNav}>
      {data.title && (
        <h4>
          {data.url && <Link href={data.url}>{data.title}</Link>}

          {!data.url && <>{data.title}</>}
        </h4>
      )}

      {data.items && <ul>{renderItems(data.items)}</ul>}
    </div>
  );
};

export default MegaSubNav;
