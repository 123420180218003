import { useState, useRef, useCallback } from "react";
import Slider from "react-slick";

import { IconMenuBurger } from "@/icons";
import { Drawer, IconButton } from "@/ui";

import MobileNavHeader from "./MobileNavHeader";
import MobileMainNav from "./MobileMainNav";
import MobileMegaNav from "./MobileMegaNav";
import MobileMegaSubNav from "./MobileMegaSubNav";

import useStyles from "./styles";

const MobileNav = () => {
  const classes = useStyles();
  let sliderRef = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const [regionToShow, setRegionToShow] = useState(null);

  const changeSlide = useCallback((slideIndex) => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(slideIndex);
    }
  }, []);

  const toggleNav = useCallback(() => {
    setRegionToShow(null);
    setOpen(!isOpen);
  }, [isOpen]);

  const handleSlideChange = useCallback((index) => {
    if (index === 1) {
      setRegionToShow(null);
    }
  }, []);

  return (
    <>
      <IconButton
        aria-label="main navigation"
        className={classes.openMenu}
        onClick={toggleNav}
      >
        <IconMenuBurger />
      </IconButton>

      <Drawer
        classes={{
          root: classes.mobileDrawer,
          paper: classes.mobilePaper,
        }}
        onClose={toggleNav}
        open={isOpen}
      >
        <MobileNavHeader toggleNav={toggleNav} />

        <Slider
          dots={false}
          swipe={false}
          arrows={false}
          infinite={false}
          ref={sliderRef}
          className={classes.mobileSlider}
          afterChange={handleSlideChange}
        >
          <div className={classes.mobileNavigation}>
            <MobileMainNav toggleNav={toggleNav} changeSlide={changeSlide} />
          </div>

          <div className={classes.mobileNavigation}>
            <MobileMegaNav
              setRegionToShow={setRegionToShow}
              changeSlide={changeSlide}
            />
          </div>

          <div className={classes.mobileNavigation}>
            {regionToShow && (
              <MobileMegaSubNav
                regionToShow={regionToShow}
                changeSlide={changeSlide}
                toggleNav={toggleNav}
              />
            )}
          </div>
        </Slider>
      </Drawer>
    </>
  );
};

export default MobileNav;
