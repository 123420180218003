import { makeStyles } from "@material-ui/core";

const styles = (theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: theme.spacing(),

    [theme.breakpoints.up("md")]: {
      justifyContent: "flex-end",
      paddingBottom: 0,
    },
  },

  shrink: {
    "& $link": {
      [theme.breakpoints.down("md")]: {
        maxWidth: 100,
        minHeight: 40,
      },

      [theme.breakpoints.up("md")]: {
        maxWidth: 120,
      },
    },

    // "& $logo": {
    //   [theme.breakpoints.down("md")]: {
    //     maxHeight: 36,
    //   },
    // },
  },

  link: {
    display: "block",
    transition: theme.transitions.create("all"),
    transformOrigin: "center right",

    [theme.breakpoints.down("md")]: {
      alignItems: "center",
      display: "flex",
      maxWidth: 140,
      minHeight: 88,
    },

    [theme.breakpoints.up("md")]: {
      maxWidth: 180,
    },

    [theme.breakpoints.up("lg")]: {
      maxWidth: 220,
    },
  },

  logo: {
    display: "block",
    height: "auto",
    width: "100%",

    // [theme.breakpoints.down("md")]: {
    //   maxHeight: 52,
    //   transition: theme.transitions.create("maxHeight"),
    // },
  },
});

export default makeStyles(styles);
