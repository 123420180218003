const ExternalLink = () => (
  <>
    <path
      fill="none"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.3035 15.3033V15.3033C12.3744 18.2325 7.62604 18.2325 4.69687 15.3033V15.3033C1.76771 12.3741 1.76771 7.6258 4.69687 4.69663V4.69663C7.62604 1.76746 12.3744 1.76746 15.3035 4.69663V4.69663C18.2327 7.6258 18.2327 12.3741 15.3035 15.3033Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fill="none"
      d="M12.5 7.5L7.5 12.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fill="none"
      d="M8.75 7.5H12.5V11.25"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);

export default ExternalLink;
