import { Typography } from "@/ui";

import useStyles from "./styles";

const CardSummary = ({
  title,
  titleProps,
  description,
  classes: rootClasses,
}) => {
  const classes = useStyles({ classes: rootClasses });

  return (
    <div className={classes.summary}>
      {title && (
        <Typography
          component={titleProps?.component || "h3"}
          variant={titleProps?.variant || "h3"}
          className={classes.title}
        >
          {title}
        </Typography>
      )}

      {description && (
        <Typography
          component="p"
          variant="subtitle2"
          className={classes.description}
        >
          {description}
        </Typography>
      )}
    </div>
  );
};

export default CardSummary;
