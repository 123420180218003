import { makeStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  root: {
    position: "relative",
    display: "inline-block",

    "&:before": {
      ...theme.typography.button,
      fontSize: "10px !important",
      borderRadius: "2em",
      padding: "0 0.5em",
      color: theme.palette.common.white,
      backgroundColor: "#E863E2",
      left: "calc(100% - 1.2em)",
      position: "absolute",
      content: "'Beta'",
      display: "block",
      zIndex: 10,
      top: "-0.5em",
    },

    "@media print": {
      "&:before": {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.grey[400],
      },
    },
  },

  img: {
    filter:
      "invert(55%) sepia(34%) saturate(7385%) hue-rotate(200deg) brightness(101%) contrast(97%)",
  },
});

export default makeStyles(styles);
