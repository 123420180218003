const Info = () => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 14V14C4.686 14 2 11.314 2 8V8C2 4.686 4.686 2 8 2V2C11.314 2 14 4.686 14 8V8C14 11.314 11.314 14 8 14Z"
      stroke="#84A76B"
      fill="none"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.0026 11.3333V8H7.33594"
      stroke="#84A76B"
      fill="none"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.83267 5.33341C7.74067 5.33341 7.666 5.40808 7.66667 5.50008C7.66667 5.59208 7.74134 5.66675 7.83334 5.66675C7.92534 5.66675 8 5.59208 8 5.50008C8 5.40808 7.92534 5.33341 7.83267 5.33341"
      stroke="#84A76B"
      fill="none"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);

export default Info;
