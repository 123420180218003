import { gql } from "@apollo/client";

export const TRIP_HELP = gql`
  query TripHelp($currentUser: Boolean, $topic: String) {
    TripHelp(currentUser: $currentUser, topic: $topic) {
      userQuestions {
        tripQuestionId
        url
        slug
        datePosted
        description
        requesterId
        requesterScreenName
        question
        topics
        locations {
          originalName
        }
      }
      latestQuestions {
        tripQuestionId
        url
        slug
        datePosted
        description
        requesterId
        requesterScreenName
        question
        topics
        locations {
          originalName
        }
      }
    }
  }
`;

export const TRIP_HELP_PROFILE = gql`
  query TripHelpProfile {
    TripHelpProfile {
      userQuestions {
        tripQuestionId
        url
        slug
        datePosted
        description
        requesterId
        requesterScreenName
        question
        topics
        locations {
          originalName
        }
      }
      userCanHelpQuestions {
        tripQuestionId
        url
        slug
        datePosted
        description
        requesterId
        requesterScreenName
        question
        topics
        locations {
          originalName
          name
        }
      }
    }
  }
`;

export const TRIP_HELP_QUESTIONS = gql`
  query TripHelpQuestionsList($currentUser: Boolean) {
    TripHelpQuestionsList(currentUser: $currentUser) {
      tripQuestionId
      datePosted
      requesterId
      requesterScreenName
      question
      description
      topics
      locations {
        originalName
      }
    }
  }
`;

export const UPSERT_TRIP_HELP_QUESTION = gql`
  mutation($input: TripHelpQuestionInput) {
    upsertTripHelpQuestion(input: $input) {
      tripQuestionId
    }
  }
`;

export const ADD_TRIP_HELP_REPLY = gql`
  mutation($input: TripHelpReplyInput) {
    addTripHelpReply(input: $input) {
      tripQuestionId
    }
  }
`;

export const TRIP_HELP_QUESTION_DELETE = gql`
  mutation($tripQuestionId: String!) {
    deleteTripHelpQuestion(tripQuestionId: $tripQuestionId)
  }
`;

export const TRIP_HELP_QUESTION = gql`
  query TripHelpQuestion($tripQuestionId: ID!) {
    TripHelpQuestion(tripQuestionId: $tripQuestionId) {
      tripQuestionId
      datePosted
      description
      requesterId
      requesterScreenName
      requesterPicUrl
      question
      topics
      questionPhotos
      locations {
        originalName
      }
      replies {
        tripReplyId
        helperUserId
        helperUserScreenName
        helperUserPicUrl
        dateReplied
        dateUpdated
        dateRequesterAlertBarClosed
        reply
      }
    }
  }
`;
