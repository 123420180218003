const Help = () => (
  <>
    <g>
      <g data-name="Group 29890" transform="translate(9368.016 19749)">
        <g data-name="Group 29888" transform="translate(-9343.716 -19749)">
          <rect
            data-name="Rectangle 23197"
            width="2.542"
            height="13.98"
            transform="translate(103.928 42.345)"
          />
          <rect
            data-name="Rectangle 23198"
            width="6.778"
            height="13.98"
            transform="translate(91.22 42.345)"
          />
          <path
            data-name="Path 18603"
            d="M223.478,20.041,199.576,35.7h47.8Z"
            transform="translate(-147.931 -20.041)"
          />
          <rect
            data-name="Rectangle 23199"
            width="6.778"
            height="13.98"
            transform="translate(53.095 42.345)"
          />
          <rect
            data-name="Rectangle 23200"
            width="61.848"
            height="14.827"
            transform="translate(44.623 21.588)"
          />
          <path
            data-name="Path 18604"
            d="M186.439,212.142l29.467,37.287,29.467-37.27L242.6,167H189.211Z"
            transform="translate(-140.36 -104.745)"
          />
          <rect
            data-name="Rectangle 23201"
            width="6.778"
            height="13.98"
            transform="translate(65.803 42.345)"
          />
          <rect
            data-name="Rectangle 23202"
            width="6.778"
            height="13.98"
            transform="translate(78.512 42.345)"
          />
          <path
            data-name="Path 18605"
            d="M291.157,294.67l-26.231,33.152,30.648,38.736Z"
            transform="translate(-185.598 -178.332)"
          />
          <rect
            data-name="Rectangle 23203"
            width="2.542"
            height="13.98"
            transform="translate(44.623 42.345)"
          />
          <path
            data-name="Path 18606"
            d="M179.142,294.67l-4.417,71.888,30.648-38.736Z"
            transform="translate(-133.607 -178.332)"
          />
          <path
            data-name="Path 18607"
            d="M395.73,93H375.465a2.965,2.965,0,0,0,0,5.931H395.73a2.965,2.965,0,1,0,0-5.931Z"
            transform="translate(-247.603 -62.093)"
          />
          <path
            data-name="Path 18608"
            d="M363.093,37.808a2.954,2.954,0,0,0,1.172-.243l20.265-8.735a2.965,2.965,0,1,0-2.346-5.447l-20.266,8.735a2.965,2.965,0,0,0,1.175,5.689Z"
            transform="translate(-240.47 -21.828)"
          />
          <path
            data-name="Path 18609"
            d="M384.531,151.713l-20.264-8.735a2.965,2.965,0,0,0-2.347,5.447l20.266,8.735a2.965,2.965,0,1,0,2.347-5.446Z"
            transform="translate(-240.472 -90.759)"
          />
          <path
            data-name="Path 18610"
            d="M103.857,95.965A2.965,2.965,0,0,0,100.892,93H80.627a2.965,2.965,0,0,0,0,5.931h20.265A2.965,2.965,0,0,0,103.857,95.965Z"
            transform="translate(-77.662 -62.093)"
          />
          <path
            data-name="Path 18611"
            d="M91.825,28.83l20.264,8.735a2.965,2.965,0,0,0,2.347-5.447L94.172,23.384a2.965,2.965,0,1,0-2.347,5.446Z"
            transform="translate(-84.792 -21.828)"
          />
          <path
            data-name="Path 18612"
            d="M115.986,144.527a2.965,2.965,0,0,0-3.9-1.55l-20.264,8.735a2.965,2.965,0,0,0,2.347,5.446l20.264-8.735A2.965,2.965,0,0,0,115.986,144.527Z"
            transform="translate(-84.792 -90.759)"
          />
          <path
            data-name="Path 18613"
            d="M232.175,423.751H208.846L177.3,383.879l-31.547,39.872H122.423a2.965,2.965,0,0,0,0,5.931H232.175a2.965,2.965,0,1,0,0-5.931Z"
            transform="translate(-101.753 -229.75)"
          />
        </g>
      </g>
    </g>
  </>
);

export default Help;
