import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Stepper as MuiStepper } from "@material-ui/core";
import Step from "@material-ui/core/Step";
import StepConnector from "@material-ui/core/StepConnector";
import StepLabel from "@material-ui/core/StepLabel";
import Loading from "../Loading";

import styles from "./styles";

const Stepper = ({
  activeStep = 0,
  classes = {},
  onClick = () => {},
  loading = false,
  steps = [],
  variant = "default",
  ...rest
}) => (
  <MuiStepper
    activeStep={activeStep}
    classes={{ root: `${classes.root} ${classes[variant]}` }}
    connector={
      <StepConnector
        classes={{
          root: classes.connector,
          line: classes.connectorLine,
        }}
      />
    }
    {...rest}
  >
    {steps.map(({ name, completed }, i) => (
      <Step completed={completed} classes={{ root: classes.step }} key={name}>
        <StepLabel
          // completed={i < activeStep}
          classes={{
            active: classes.labelActive,
            completed: classes.labelCompleted,
            iconContainer: classes.iconContainer,
            label: classes.label,
            root: classes.labelRoot,
          }}
          onClick={() => onClick(i)}
          StepIconProps={{
            classes: {
              active: classes.iconActive,
              completed: classes.iconCompleted,
              root: classes.iconSvg,
              text: classes.iconText,
            },
          }}
        >
          {loading && activeStep === i ? (
            <>
              {"Saving... "}
              <Loading className={classes.iconLoading} size={18} />
            </>
          ) : (
            name
          )}
        </StepLabel>
      </Step>
    ))}
  </MuiStepper>
);

Stepper.propTypes = {
  activeStep: PropTypes.number,
  classes: PropTypes.object,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  steps: PropTypes.arrayOf(PropTypes.object),
  variant: PropTypes.string,
};

export default withStyles(styles)(Stepper);
