import { gql } from "@apollo/client";

// FRAGMENTS

export const TOPIC_FRAGMENT = gql`
  fragment TopicData on Topic {
    url
    slug
    region
    topicId
    topicTitle
    topicDescription
    topicHero {
      title
      description
      photo {
        caption
        itineraryUrl
        mobile {
          cloudId
          photoUrl
          photoFilter
        }
        desktop {
          cloudId
          photoUrl
          photoFilter
        }
      }
    }
    tripPhotos {
      photoUrl
      caption
      displayOrder
      cloudId
      photoFilter
      itineraryUrl
      itineraryId
      tripTitle
      locationCountry
      locationName
    }
    tripTips {
      title
      description
    }
    guruItineraries {
      url
      slug
      tripTitle
      regionName
      itineraryId
      itineraryOverview
      shortDescription
      heroPhoto {
        cloudId
        photoUrl
      }
      # LEGACY - we use the heroPhoto instead of the itineraries photos
      # tripPhotos {
      #   cloudId
      #   photoUrl
      # }
      # locationsVisited {
      #   tripPhotos {
      #     cloudId
      #     photoUrl
      #   }
      # }
      countries {
        name
      }
      userProfile {
        cloudId
        screenName
        profilePicUrl
        profileDescription
        userId
      }
    }
    topicItineraries {
      url
      slug
      tripTitle
      regionName
      itineraryId
      itineraryOverview
      shortDescription
      heroPhoto {
        cloudId
        photoUrl
      }
      # LEGACY - we use the heroPhoto instead of the itineraries photos
      # tripPhotos {
      #   cloudId
      #   photoUrl
      # }
      # locationsVisited {
      #   tripPhotos {
      #     cloudId
      #     photoUrl
      #   }
      # }
      countries {
        name
      }
      userProfile {
        cloudId
        screenName
        profilePicUrl
        profileDescription
        userId
      }
    }
    countries {
      place_id
      formatted_address
      name
      originalName
    }
    locations {
      slug
      url
      numOfDays
      tripTitle
      screenName
      itineraryId
      profilePicUrl
      shortDescription
      locationsVisited {
        location {
          name
          geometry {
            location {
              lat
              lng
            }
          }
        }
      }
    }
  }
`;

// QUERY

export const TOPIC_QUERY = gql`
  query TOPIC_QUERY($topicId: ID!) {
    GetTopic(topicId: $topicId) {
      ...TopicData
    }
  }
  ${TOPIC_FRAGMENT}
`;

export const TOPIC_DRAFT_QUERY_NO_CACHE = gql`
  query TOPIC_DRAFT_QUERY_NO_CACHE($topicId: ID!) {
    GetTopicPreview(topicId: $topicId) {
      ...TopicData
      dateCreated
      dateUpdated
      published
      unpublishedChangesExist
    }
  }
  ${TOPIC_FRAGMENT}
`;

export const TOPICS_LIST_QUERY_NO_CACHE = gql`
  query TOPICS_LIST_QUERY_NO_CACHE(
    $sortBy: String
    $sortDirection: String
    $offset: Int
    $max: Int
  ) {
    GetTopicsList(
      sortBy: $sortBy
      sortDirection: $sortDirection
      offset: $offset
      max: $max
    ) {
      topics {
        topicId
        topicTitle
        slug
        tripPhotos {
          cloudId
          caption
          photoUrl
        }
        dateCreated
        dateUpdated
        published
        unpublishedChangesExist
      }
      paginationInfo {
        currentPage
        totalPages
        totalItems
      }
    }
  }
`;

// MUTATIONS

export const UPSERT_TOPIC = gql`
  mutation ($input: TopicInput) {
    UpsertTopic(input: $input) {
      topicId
      topicTitle
      slug
      dateCreated
      dateUpdated
      published
      unpublishedChangesExist
      url
    }
  }
`;

export const PUBLISH_TOPIC = gql`
  mutation ($topicId: ID!) {
    PublishTopic(topicId: $topicId) {
      topicId
      topicTitle
      slug
      dateCreated
      dateUpdated
      published
      unpublishedChangesExist
      url
    }
  }
`;

export const UNPUBLISH_TOPIC = gql`
  mutation ($topicId: ID!) {
    UnpublishTopic(topicId: $topicId) {
      topicId
      topicTitle
      slug
      dateCreated
      dateUpdated
      published
      unpublishedChangesExist
      url
    }
  }
`;

export const DELETE_TOPIC = gql`
  mutation ($topicId: ID!) {
    DeleteTopic(topicId: $topicId)
  }
`;
