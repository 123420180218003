const MenuBurger = () => (
  <>
    <g transform="matrix(1 0 0 .7 0 30)">
      <g data-name="Group 29793">
        <rect
          data-name="Rectangle 23056"
          width="200"
          height="40"
          transform="translate(0 160)"
        />
        <rect
          data-name="Rectangle 23057"
          width="200"
          height="40"
          transform="translate(0 80)"
        />
        <rect data-name="Rectangle 23058" width="200" height="40" />
      </g>
    </g>
  </>
);

export default MenuBurger;
