import { useRouter } from "next/router";
import React, { useCallback, useState } from "react";
import { Button, Grid, Modal } from "@/ui";
import { withStyles } from "@material-ui/core";

import styles from "./styles";

const UnsavedChangesModal = ({ classes, showConfirmation }) => {
  const [showUnsavedChangesDialog, setShowUnsavedChangesDialog] =
    useState(false);
  const [nextRouterPath, setNextRouterPath] = useState();
  const Router = useRouter();

  const onRouteChangeStart = useCallback(
    (nextPath) => {
      if (!showConfirmation) {
        return;
      }

      setShowUnsavedChangesDialog(true);
      setNextRouterPath(nextPath);

      // Throw exception to stop route change
      Router.events.emit("routeChangeError");
      throw "cancelRouteChange";
    },
    [showConfirmation, Router.events]
  );

  // Handling browser refresh and Back, Next arrows
  const onBeforeUnload = React.useCallback(
    (e) => {
      if (showConfirmation) {
        e.preventDefault();
        e.returnValue = "";
      }
    },
    [showConfirmation]
  );

  const onRejectRouteChange = () => {
    setNextRouterPath(null);
    setShowUnsavedChangesDialog(false);
  };

  const onConfirmRouteChange = () => {
    setShowUnsavedChangesDialog(false);

    // Removing listener here so it doesn't get triggered when the new route is pushed
    removeListener();
    Router.push(nextRouterPath);
  };

  const removeListener = useCallback(() => {
    window.removeEventListener("beforeunload", onBeforeUnload);
    Router.events.off("routeChangeStart", onRouteChangeStart);
  }, [Router.events, onBeforeUnload, onRouteChangeStart]);

  React.useEffect(() => {
    Router.events.on("routeChangeStart", onRouteChangeStart);
    window.addEventListener("beforeunload", onBeforeUnload);

    return removeListener;
  }, [onRouteChangeStart, Router.events, onBeforeUnload, removeListener]);

  return (
    <Modal
      title={"Do you want to leave this page? You have unsaved changes!"}
      open={showUnsavedChangesDialog}
      onClose={() => setShowUnsavedChangesDialog(false)}
      maxWidth="xs"
      hideBackdrop
      fullWidth
    >
      <Grid item xs={12} className={classes.modalWrapper}>
        <Button
          color="secondary"
          size="small"
          variant="contained"
          onClick={onConfirmRouteChange}
        >
          Leave page
        </Button>
        <Button size="small" variant="outlined" onClick={onRejectRouteChange}>
          Cancel
        </Button>
      </Grid>
    </Modal>
  );
};

export default withStyles(styles)(UnsavedChangesModal);
