import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";

import { trimText } from "../../../lib/utils";
import { IconWarning } from "../icons";

import styles from "./styles";

const Input = ({
  className = "",
  classes = {},
  disabled = false,
  egText = "",
  endAdornment = null,
  error = "",
  fullWidth = false,
  helperText = "",
  inputRef = null,
  label = "",
  labelWidth = 0,
  margin = "normal",
  maxLabelLength = 40,
  // TODO: remove multiline and define it using textarea's cols prop
  multiline = false,
  name = "",
  onFocus = () => {},
  onBlur = () => {},
  required = false,
  rows = 3,
  startAdornment = null,
  variant = "outlined",
  rounded = false,
  onClick = () => {},
  ...rest
}) => {
  const [isFocused, focusInput] = useState(false);
  const [isEdge, setEdge] = useState(false);

  const labelFontSize = 8.6;
  const labelLength = label.length;
  const minLabelLength =
    labelLength > 14 && labelLength > 0 ? labelLength : labelLength + 1.5;
  const needsTrim = labelLength > maxLabelLength;
  const isLabelTrimmed = needsTrim && isFocused; // || value.length > 0);
  const requiredLength = required ? 1.8 : 0;
  const trimmedLabelLength = isLabelTrimmed
    ? maxLabelLength + 1
    : minLabelLength;
  const labelLengthFix =
    labelLength > 0 ? trimmedLabelLength + requiredLength : 0;
  const labelSize = labelWidth || labelLengthFix * labelFontSize;
  const hasError = !!error; // && isTouched;

  useEffect(() => {
    // Detecting browser to set different input style for Edge
    // Internet Explorer 6-11
    const isIEBrowser = /* @cc_on!@ */ false || !!document.documentMode;
    // Edge 20+
    const isEdgeBrowser = !isIEBrowser && !!window.StyleMedia;
    setEdge(isEdgeBrowser);
  }, []);

  const handleFocus = (event) => {
    focusInput(true);
    onFocus(event);
  };

  const handleBlur = (event) => {
    focusInput(false);
    onBlur(event);
  };

  const renderErroAdorment = () => (
    <InputAdornment position="end" className={classes.icon}>
      <IconWarning className={classes.icon} />
    </InputAdornment>
  );

  return (
    <FormControl
      classes={{
        root: `${className} ${classes.root} ${classes.formControl} ${
          rounded ? classes.rounded : ""
        }`,
        marginDense: classes.marginDense,
      }}
      error={hasError}
      fullWidth={fullWidth}
      margin={margin}
      required={required}
      variant={variant}
      onClick={onClick}
    >
      {label && (
        <InputLabel
          classes={{
            root: `${classes.label} ${disabled ? classes.disabled : ""}`,
            marginDense: classes.labelMarginDense,
            asterisk: classes.asterisk,
            outlined: classes.outlined,
            shrink: classes.labelShrink,
          }}
          htmlFor={`field-${name}`}
          variant={variant}
        >
          {isLabelTrimmed ? trimText(label, maxLabelLength) : label}
        </InputLabel>
      )}
      <OutlinedInput
        aria-describedby={`field-info-${name}`}
        classes={{
          root: classes.input,
          marginDense: classes.inputMarginDense,
          adornedEnd: classes.adornedEnd,
          adornedStart: classes.adornedStart,
          disabled: classes.disabled,
          error: classes.inputError,
          multiline: classes.multiline,
          input: `${classes.inputElem} ${multiline ? classes.textarea : ""}`,
          notchedOutline: !isEdge
            ? classes.notchedOutline
            : classes.notchedOutlineEdge,
        }}
        disabled={disabled}
        endAdornment={hasError ? renderErroAdorment() : endAdornment}
        // id={`field-${name}`}
        inputRef={inputRef}
        // inputProps={{ ...rest }}
        labelWidth={labelSize}
        multiline={multiline}
        name={name}
        onBlur={handleBlur}
        onFocus={handleFocus}
        required={required}
        rows={rows}
        startAdornment={startAdornment}
        title={label}
        variant={variant}
        {...rest}
      />
      <small className={classes.formControFooter}>
        <FormHelperText
          className={hasError ? classes.errorMsg : classes.errorMsg}
          component="span"
          error={hasError}
        >
          {hasError && typeof error === "string" ? error : egText}
        </FormHelperText>
        {helperText && (
          <FormHelperText
            className={classes.helperText}
            component="span"
            // id={`field-info-${name}`}
          >
            {helperText}
          </FormHelperText>
        )}
      </small>
    </FormControl>
  );
};

Input.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  disabled: PropTypes.bool,
  egText: PropTypes.node,
  endAdornment: PropTypes.element,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  fullWidth: PropTypes.bool,
  helperText: PropTypes.node,
  inputRef: PropTypes.any,
  label: PropTypes.any,
  labelWidth: PropTypes.number,
  margin: PropTypes.string,
  maxLabelLength: PropTypes.number,
  multiline: PropTypes.bool,
  name: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  rows: PropTypes.number,
  required: PropTypes.bool,
  startAdornment: PropTypes.element,
  variant: PropTypes.string,
  onClick: PropTypes.func,
};

export default withStyles(styles)(Input);
