import { makeStyles } from "@material-ui/core";

// import { staticUrl } from "@/lib/utils";

// const bgPalmTree = staticUrl(require("@/static/img/theme-bg-palm.svg"));

const styles = (theme) => ({
  root: {
    height: 0,
    transition: theme.transitions.create("height"),

    [theme.breakpoints.down("sm")]: {
      overflow: "hidden",
    },

    [theme.breakpoints.up("md")]: {
      display: "flex",
      height: `calc(1.6em + ${theme.spacing(2)}px)`,
    },
  },

  shrink: {
    height: 0,
    overflow: "hidden",
  },

  shrinkNav: {
    display: "none",

    [theme.breakpoints.up("md")]: {
      display: "flex",
      width: 0,
      overflow: "hidden",
      transition: theme.transitions.create("width"),

      "&$shrinkNavShrinked": {
        width: "3.2em",
      },
    },
  },

  shrinkNavShrinked: {},

  shrinkNavButton: {
    marginRight: theme.spacing(),
    color: theme.palette.common.white,

    "& svg": {
      width: "1.5em",
      height: "1.5em",
    },
  },

  shrinkNavPopper: {},

  shrinkNavPopperCloseBtn: {
    top: "0.4em",

    "& svg": {
      width: "1.5em",
      height: "1.5em",

      color: theme.palette.common.white,
      "&, & circle": {
        fill: "transparent",
      },
    },
  },

  shrinkNavPopperWrapper: {
    padding: "1.5em 0 0",
    backgroundColor: theme.palette.primary.main,
  },

  shrinkNavPopperContent: {
    marginRight: "5em",
  },

  shrinkNavMenuList: {
    padding: 0,
  },

  shrinkNavMenuItem: {},

  shrinkNavMenuLink: {
    ...theme.typography.button,
    color: theme.palette.common.white,
    display: "block",
    flex: 1,
    margin: "0.5em 0",
  },

  shrinkNavMenuTag: {
    fontSize: theme.atlasGuru.fontSizes.caption.xs,
    textTransform: "uppercase",
    margin: "0 0 0 0.4em",
    lineHeight: 1,
    minHeight: 0,

    "& span": {
      padding: "0.1em 0.8em",
    },
  },

  navigation: {
    [theme.breakpoints.up("md")]: {
      alignItems: "center",
      display: "flex",
      width: "100%",
      textAlign: "center",
      justifyContent: "center",
      paddingTop: theme.spacing(1.8),
      // marginBottom: theme.spacing(1.5),
    },
  },

  // mobile specific navigation
  openMenu: {
    color: theme.palette.common.white,
    display: "block",
    fontSize: 12,
    left: 5,
    padding: 0,
    position: "absolute",
    top: 5,

    "& svg": {
      width: 30,
      height: 25,
    },

    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },

  mobileDrawer: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },

  mobilePaper: {
    backgroundColor: theme.palette.primary.main,
    // backgroundImage: `url(${bgPalmTree})`,
    // backgroundPosition: "50% 100%",
    // backgroundRepeat: "no-repeat",
    // backgroundSize: "20%",
    // paddingBottom: 150,
    right: 0,
  },

  mobileHeader: {
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px 0`,
    position: "relative",
    zIndex: 40,
  },

  closeMenu: {
    color: theme.palette.common.white,
    padding: 0,
    position: "absolute",
    right: theme.gutter,
    top: theme.spacing(2),
  },

  mobileSlider: {
    position: "relative",
    overflow: "hidden",
    height: "100%",
    zIndex: 10,
    flex: 1,

    "& .slick-list": {
      paddingTop: theme.spacing(2),
      outline: "none",
      height: "100%",
    },

    "& .slick-track": {
      display: "flex",
      height: "100%",
    },

    "& .slick-slide": {
      position: "relative",
      overflow: "auto",
      height: "100%",
      zIndex: 0,
    },
  },

  mobileNavigation: {
    "& nav": {
      padding: `0 ${theme.spacing(4)}px`,
      width: "100%",
    },
  },

  mobileFooter: {
    padding: `0 ${theme.spacing(4)}px`,
  },

  mobileBrand: {
    height: 45,
    paddingBottom: 0,
  },

  mobileBrandLink: {
    display: "inline-block",
    minHeight: 45,
    maxHeight: 45,
  },

  mobileBrandLogo: {
    height: 45,
  },

  // TODO: search duplicated styles, move to SearchBox component once new design is applied
  searchBox: {
    paddingTop: theme.gutter,
  },

  isActive: {
    "& $inputWrap": {
      borderRadius: "1.3em 1.3em 0 0",
      transition: theme.transitions.create("border-radius", {
        duration: 200,
        delay: 200,
      }),
    },
  },

  inputWrap: {
    borderRadius: "1.3em",
    transition: theme.transitions.create("border-radius", {
      duration: 100,
      delay: 100,
    }),
  },

  results: {
    margin: 0,
    border: "none",
    width: "calc(100% - 64px)",
    left: theme.spacing(4),
    top: "auto",
  },

  link: {
    ...theme.typography.h5,
    color: theme.palette.primary.contrastText,
    textTransform: "none",
    cursor: "pointer",

    "&:hover, &:hover svg": {
      color: `${theme.palette.common.highlight} !important`,
    },

    [theme.breakpoints.down("sm")]: {
      padding: `${theme.spacing(2.5)}px 0`,
      borderBottom: `1px solid ${theme.atlasGuru.dividerContrast}`,
      display: "flex",
      alignItems: "center",
    },

    [theme.breakpoints.up("md")]: {
      fontSize: theme.atlasGuru.fontSizes.h5.sm - 2,
      display: "inline-flex",
      margin: `0 1.2em`,
      textAlign: "center",
      alignItems: "center",
      position: "relative",
      height: "100%",

      "&:before": {
        content: "''",
        width: 0,
        height: 0,
        position: "absolute",
        bottom: -1,
        left: "50%",
        opacity: 0,
        transform: `translate(-50%, ${theme.spacing(2)}px)`,
        border: "solid 15px transparent",
        borderBottomWidth: "13px",
        borderBottomColor: theme.palette.common.white,
        transition: theme.transitions.create("opacity", {
          duration: "200ms",
          delay: "600ms",
        }),
      },

      "&:first-child": {
        marginLeft: 0,
      },

      "&:last-child": {
        marginRight: 0,
      },

      "& > span": {
        // whiteSpace: "nowrap",
        textTransform: "uppercase",
        display: "inline-block",
        lineHeight: 1.2,
      },

      "& .icon": {
        display: "none !important",
      },
    },
  },

  backBtn: {
    width: "100%",
    justifyContent: "flex-start",

    "& .icon": {
      marginLeft: 0,
      marginRight: theme.spacing(3),
    },

    "& svg": {
      height: "1.2em",
      width: "1.2em",
    },
  },

  mobileArrowLink: {
    flexDirection: "row-reverse",
    justifyContent: "space-between",

    "& .icon": {
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(2),
    },
  },

  avatar: {
    marginRight: theme.spacing(1.42),
    marginLeft: theme.spacing(1) * -1,
  },

  itemIcon: {
    "& > svg": {
      height: "1.2em !important",
      width: "1.2em !important",
    },

    // [theme.breakpoints.up("sm")]: {
    //   marginRight: theme.spacing(1),

    //   "& > svg": {
    //     top: 2,
    //   },
    // },

    [theme.breakpoints.up("md")]: {
      display: "none !important",
    },
  },

  // mega menu specific navigation
  activeMegaMenu: {
    "&:before": {
      opacity: 1,
      transitionDelay: 0,
    },
  },

  megaNav: {
    width: "100%",
    display: "flex",
    position: "relative",
    padding: `${theme.spacing(3)}px 0 ${theme.spacing(5)}px`,
    backgroundColor: theme.palette.common.white,
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    boxShadow: theme.atlasGuru.boxShadow(),

    "& h4": {
      margin: "0 0 0.4em",

      "&, & a": {
        ...theme.typography.h4,
        color: theme.palette.primary.main,
      },

      "& a": {
        "&:hover": {
          textDecoration: "none",
        },
      },
    },

    "& a": {
      ...theme.typography.subtitle2,
      color: theme.palette.common.black,
      textDecoration: "none",

      "&:hover": {
        textDecoration: "underline",
      },
    },
  },

  megaNavContainer: {
    margin: "0 auto",
    maxWidth: 1380,
    padding: theme.spacing(),
  },

  megaNavCloseBtn: {
    height: "100%",
    position: "absolute",
    right: 0,
    top: 0,

    "& button": {
      borderRadius: "50%",
      overflow: "hidden",
      margin: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(
        2
      )}px 0`,
      position: "sticky",
      top: 0,

      "&, &:hover": {
        backgroundColor: theme.palette.common.white,
      },
    },
  },

  megaSubNav: {
    "& > ul": {
      display: "flex",
      justifyContent: "end",
      flexFlow: "column",
      listStyle: "none",
      margin: 0,
      padding: 0,
    },
  },

  megaSubNavCategories: {
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },

  megaNavColumns: {
    "& > $megaSubNav > ul": {
      display: "flex",
      flexFlow: "row",

      "& > li": {
        borderRight: `1px solid ${theme.palette.grey[100]}`,
        paddingRight: theme.spacing(3),
        marginRight: theme.spacing(4),
      },
    },
  },
});

export default makeStyles(styles);
