import { REGION_MAP } from "@/lib/constants";

export const coverImageTransformations = {
  crop: "fill",
  gravity: "auto:subject",
  height: 56,
  quality: "auto:eco",
  width: 100,
};

export const regionsLinks = Object.keys(REGION_MAP).map((key) => ({
  id: key,
  title: REGION_MAP[key].text,
  text: REGION_MAP[key].text,
  icon: REGION_MAP[key].icon,
  href: `/itineraries/${key}`,
  as: `/itineraries/${key}`,
}));

export const getHitUrl = (url) => url || "/itineraries";
