const Lodging = () => (
  <>
    <g>
      <path
        d="M53.333,171.767c0,3.333-1.667,6.667-3.333,10-3.333,3.333-5,3.333-10,3.333H13.333c-3.333,0-6.667-1.667-10-3.333S0,175.1,0,171.767V95.1c0-5,1.667-8.333,5-11.667l10-5C35,70.1,61.667,65.1,98.333,65.1c38.333,0,66.667,5,86.667,13.333,3.333,1.667,5,3.333,8.333,5C198.333,86.767,200,90.1,200,95.1v76.667c0,3.333-1.667,6.667-3.333,10s-5,3.333-10,3.333H160c-3.333,0-6.667-1.667-10-3.333s-3.333-5-3.333-10V158.433H53.333Zm5-135a69.093,69.093,0,0,0-26.667,5c-8.333,5-15,8.333-18.333,15v-30q0-7.5,5-10C35,5.1,61.667.1,100,.1h23.333q12.5,0,30,5C165,8.433,175,11.767,181.667,16.767q5,5,5,10v30c-3.333-5-10-10-18.333-13.333a69.093,69.093,0,0,0-26.667-5c-8.333,0-16.667,1.667-23.333,5C110,45.1,105,48.433,100,51.767a90.374,90.374,0,0,0-18.333-10C75,38.433,66.667,36.767,58.333,36.767Z"
        transform="translate(0 5.551)"
      />
    </g>
  </>
);

export default Lodging;
