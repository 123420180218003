const Hash = () => (
  <>
    <g>
      <g data-name="09 Topic Chat" transform="translate(12 22.305)">
        <path
          data-name="Path 18613"
          d="M149.239,4H27.637A27.667,27.667,0,0,0,0,31.637v82.911a27.667,27.667,0,0,0,27.637,27.637H69.565L84.53,157.147a5.527,5.527,0,0,0,7.816,0l14.965-14.963h41.928a27.667,27.667,0,0,0,27.637-27.637V31.637A27.667,27.667,0,0,0,149.239,4Zm16.582,110.548a16.6,16.6,0,0,1-16.582,16.582H105.02a5.527,5.527,0,0,0-3.907,1.619L88.438,145.421,75.764,132.749a5.527,5.527,0,0,0-3.908-1.62H27.637a16.6,16.6,0,0,1-16.582-16.582V31.637A16.6,16.6,0,0,1,27.637,15.055h121.6a16.6,16.6,0,0,1,16.582,16.582Z"
          transform="translate(0 -4)"
        />
        <path
          id="Path_18614"
          data-name="Path 18614"
          d="M89.856,47.164a5.527,5.527,0,1,0,0-11.055H78.8V19.527a5.527,5.527,0,1,0-11.055,0V36.11H45.637V19.527a5.527,5.527,0,1,0-11.055,0V36.11H23.527a5.527,5.527,0,0,0,0,11.055H34.582V63.746H23.527a5.527,5.527,0,0,0,0,11.055H34.582V91.383a5.527,5.527,0,1,0,11.055,0V74.8h22.11V91.383a5.527,5.527,0,1,0,11.055,0V74.8H89.856a5.527,5.527,0,1,0,0-11.055H78.8V47.164ZM67.746,63.746H45.637V47.164h22.11Z"
          transform="translate(31.746 13.637)"
        />
      </g>
    </g>
  </>
);

export default Hash;
