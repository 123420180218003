import { algoliaIndexNameItinerary } from "@/config";
import { IconButton } from "@/ui";
import { IconClose } from "@/icons";
import SearchBox from "@/components/common/SearchBox";

import Brand from "../Brand";

import useStyles from "./styles";

const MobileNavHeader = ({ toggleNav }) => {
  const classes = useStyles();

  const searchListParser = (hit) => ({
    heading: hit.locationsVisited,
    description: hit.tripTitle,
    img: hit.renderImage(hit),
    isActive: hit.index === hit.selected,
    // link: hit.url || exploreUrl
  });

  return (
    <header className={classes.mobileHeader}>
      <IconButton className={classes.closeMenu} onClick={toggleNav}>
        <IconClose size="md" viewBox="-6 -6 212 212" />
      </IconButton>

      <Brand
        classes={{
          root: classes.mobileBrand,
          link: classes.mobileBrandLink,
          logo: classes.mobileBrandLogo,
        }}
      />

      <SearchBox
        classes={{
          root: classes.searchBox,
          isActive: classes.isActive,
          searchWrapper: classes.searchWrapper,
          inputWrap: classes.inputWrap,
          results: classes.results,
        }}
        indexName={algoliaIndexNameItinerary}
        searchListParser={searchListParser}
        onClickSearchResult={toggleNav}
      />
    </header>
  );
};

export default MobileNavHeader;
