const Guide = () => (
  <g id="Guide">
    <path
      id="Vector_2"
      d="M43.721 56.3333C43.721 53.6873 42.6616 51.1498 40.7759 49.2788C38.8901 47.4078 36.3326 46.3567 33.6657 46.3567H13.5552C8.00189 46.3567 3.5 50.8233 3.5 56.3333M33.6657 30.7681C33.6657 36.2781 29.1638 40.7448 23.6105 40.7448C18.0572 40.7448 13.5552 36.2781 13.5552 30.7681M47.8978 11.75V56.3333M43.5397 29.3651H3.50071M35.5313 28.8975C35.5313 22.01 30.1535 16.4266 23.5197 16.4266C16.8857 16.4266 11.5079 22.01 11.5079 28.8975M47.8978 5L59.5 11.75L47.8978 18.5001V5Z"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </g>
);

export default Guide;
