import { useCallback, useState, useRef, useMemo, useEffect } from "react";
import { throttle } from "lodash-es";

import {
  initializeAutoCompleteService,
  getPlacePredictions,
} from "@/lib/googleplaces";

import {
  throttleTime,
  inativityTime,
  getPlaceValue,
  noResultsOption,
} from "./utils";

const useGooglePlaces = ({
  initialValue = "",
  locationType = "",
  minCharsForApiRequest = 0,
  onInputChange = () => {},
  onChange = () => {},
  clearOnSelect = false,
}) => {
  const [inputValue, setInputValue] = useState(initialValue);
  const [options, setOptions] = useState([]);
  const isFetched = useRef(false);
  const selectedOption = useRef("");

  // fetch google places data
  const fetch = useCallback(
    ({ input }) => {
      // call autocomplete service for places predictions
      getPlacePredictions({
        input,
        types: locationType?.length ? [locationType] : undefined,
        callback: (results = []) => {
          isFetched.current = true;
          setOptions(results?.length ? results : noResultsOption);
        },
      });
    },
    [locationType]
  );

  // delay fetches of google places api
  const throttleFetch = useMemo(
    () => throttle(fetch, throttleTime, { leading: false }),
    [fetch]
  );

  // handle input change
  const handleInputChange = useCallback(
    (_, newInputValue) => {
      // reset fetch state and set input value
      isFetched.current = false;
      onInputChange(newInputValue);
      setInputValue(newInputValue);

      // do not call API while deleting and clear options list
      const isDeleting = newInputValue.length < inputValue.length;
      if (isDeleting) {
        setOptions([]);
        return true;
      }

      // avoid calling the api when the input is preloaded
      const isInputPreFilled =
        !isFetched.current &&
        newInputValue.length > 0 &&
        newInputValue.length === inputValue.length;

      if (isInputPreFilled) {
        isFetched.current = true;
      }

      // create a dummy options list while typing
      if (!isFetched.current && newInputValue) {
        setOptions([
          { description: newInputValue, disabled: true, loading: true },
        ]);
      }

      // console.log(
      //   "GPLACES::handleInputChange: ",
      //   isFetched.current,
      //   newInputValue.length,
      //   inputValue.length
      // );
    },
    [inputValue.length, onInputChange]
  );

  // handle options change
  const handleSelectedChange = useCallback(
    (_, newValue) => {
      isFetched.current = false;
      selectedOption.current = getPlaceValue(newValue);
      if (clearOnSelect) {
        setInputValue("");
      }
      onChange(newValue);
      setOptions([]);
    },
    [clearOnSelect, onChange]
  );

  // initialize the api before fetch
  useEffect(() => {
    initializeAutoCompleteService();
  }, []);

  // set input value if there is a pre defined value when initialized
  useEffect(() => {
    setInputValue(initialValue);
  }, [initialValue]);

  // call google places api predictons when the user is typing
  useEffect(() => {
    if (
      !isFetched.current &&
      inputValue !== selectedOption.current &&
      inputValue.length > minCharsForApiRequest
    ) {
      // console.log("GPLACES::throttling: ", inputValue);
      throttleFetch({ input: inputValue });
    }
  }, [throttleFetch, inputValue, minCharsForApiRequest]);

  // delay calls to google places api while
  // the input value is smaller than minCharsForApiRequest
  useEffect(() => {
    let inactivityTimer;

    if (
      inputValue &&
      inputValue !== selectedOption.current &&
      inputValue.length <= minCharsForApiRequest
    ) {
      // console.log("GPLACES::waiting: ", inputValue);
      inactivityTimer = setTimeout(() => {
        fetch({ input: inputValue });
      }, inativityTime);
    }

    return () => {
      if (inactivityTimer) {
        clearTimeout(inactivityTimer);
      }
    };
  }, [fetch, inputValue, minCharsForApiRequest]);

  return {
    options,
    inputValue,
    handleInputChange,
    handleSelectedChange,
  };
};

export default useGooglePlaces;
