const NoteBook = () => (
  <g id="NoteBook">
    <path
      id="Path"
      d="M18.582 33.5833H44.4154"
      stroke="#091C29"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      id="Path_2"
      d="M18.582 43.9167H44.4154"
      stroke="#091C29"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      id="Path_3"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.332 7.75H21.1654C19.7394 7.75 18.582 8.90733 18.582 10.3333V23.25L23.7487 20.6667L28.9154 23.25V10.3333C28.9154 8.90733 27.758 7.75 26.332 7.75Z"
      stroke="#091C29"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      id="Path_4"
      d="M28.9167 10.3334H49.5833C52.4379 10.3334 54.75 12.6455 54.75 15.5V49.0834C54.75 51.938 52.4379 54.25 49.5833 54.25H13.4167C10.5621 54.25 8.25 51.938 8.25 49.0834V15.5C8.25 12.6455 10.5621 10.3334 13.4167 10.3334H18.5833"
      stroke="#091C29"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      id="Path_5"
      d="M39.25 23.25H44.4167"
      stroke="#091C29"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </g>
);

export default NoteBook;
