const Download = () => (
  <path
    d="M7.60156 9.5L10.6016 12.5M10.6016 12.5L13.6016 9.5M10.6016 12.5V1.5M14.6016 2.5H15.6016C17.8107 2.5 19.6016 4.29086 19.6016 6.5V15.5C19.6016 17.7091 17.8107 19.5 15.6016 19.5H5.60156C3.39242 19.5 1.60156 17.7091 1.60156 15.5V6.5C1.60156 4.29086 3.39242 2.5 5.60156 2.5H6.60156"
    fill="none"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
);

export default Download;
