const Travel = () => (
  <g id="Travel">
    <path
      id="Path"
      d="M40.1771 33.5833L30.6004 24.0066C30.116 23.5222 29.4589 23.25 28.7737 23.25H26.3373C25.4357 23.25 24.5994 23.72 24.1306 24.49C23.6619 25.26 23.6284 26.2188 24.0424 27.0196L27.4354 33.5833"
      stroke="#323232"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      id="Path_2"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.8496 43.9166L21.4566 50.4804C21.0427 51.2812 21.0761 52.2399 21.5449 53.01C22.0137 53.78 22.85 54.25 23.7515 54.25H26.188C26.8731 54.25 27.5302 53.9778 28.0147 53.4933L37.5914 43.9166H49.5814C52.4349 43.9166 54.748 41.6034 54.748 38.75V38.75C54.748 35.8965 52.4349 33.5833 49.5814 33.5833H17.5825L15.7132 29.8447C15.2756 28.9695 14.3811 28.4166 13.4026 28.4166H10.8334C10.003 28.4166 9.22319 28.8158 8.73765 29.4895C8.2521 30.1631 8.11999 31.0291 8.38259 31.8169L11.2382 40.3838C11.9415 42.4936 13.9159 43.9166 16.1398 43.9166H24.8496Z"
      stroke="#323232"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      id="Oval"
      cx="13.4167"
      cy="12.9167"
      r="5.16667"
      stroke="#323232"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      id="Path_3"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M52.7606 20.6667H43.0192C40.9424 20.6612 39.2617 18.9761 39.2617 16.8993C39.2617 14.8225 40.9424 13.1374 43.0192 13.1319C43.0122 10.4621 44.9685 8.193 47.6103 7.80688C50.2521 7.42076 52.776 9.03503 53.5334 11.5952C55.8748 11.9936 57.5203 14.1198 57.3187 16.4863C57.1171 18.8528 55.1357 20.6701 52.7606 20.6667Z"
      stroke="#323232"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </g>
);

export default Travel;
