import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import IconButton from "@material-ui/core/IconButton";
import { IconClose } from "./icons";

const styles = (theme) => ({
  root: {
    ...theme.typography.chip,
    backgroundColor: theme.palette.grey[100],
    height: "auto",
    marginRight: theme.spacing(0.5),
    marginTop: theme.spacing(1),
    minHeight: theme.spacing(4),
  },

  disabled: {
    opacity: 0.3,
    pointerEvents: "none",
  },

  // children
  deleteIcon: {
    color: theme.palette.secondary.main,
    marginRight: theme.spacing(1.5),
    transform: "translateX(3px)",

    "&, & line": {
      transition: theme.transitions.create("color"),
    },

    "&:hover": {
      "&, & line": {
        color: theme.palette.common.highlight,
      },
    },
  },

  icon: {
    marginLeft: theme.spacing(1),
  },

  label: {
    padding: `${theme.spacing(0.5)}px  ${theme.spacing(1.3)}px`,
    whiteSpace: "normal",
  },

  outlined: {
    borderColor: theme.palette.grey[200],
  },

  // color=primary
  colorPrimary: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },

  deletableColorPrimary: {
    "&:focus": {
      backgroundColor: theme.palette.grey[50],
    },
  },

  // color=secondary
  colorSecondary: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.common.white,
  },

  clickableColorSecondary: {
    "&:hover": {
      backgroundColor: theme.palette.common.highlight,

      "& .icon > svg": {
        color: theme.palette.common.white,
      },
    },
  },

  iconColorSecondary: {},
});

const Tag = (props) => {
  const {
    classes = {},
    clickable = false,
    onDelete = () => {},
    disabled = false,
    isActive = false,
    disableDelete = false,
    ...rest
  } = props;
  return (
    <Chip
      classes={{
        root: classes.root,
        deleteIcon: classes.deleteIcon,
        icon: classes.icon,
        label: classes.label,

        outlined: classes.outlined,

        colorPrimary: classes.colorPrimary,
        deletableColorPrimary: classes.deletableColorPrimary,

        colorSecondary: classes.colorSecondary,
        clickableColorSecondary: classes.clickableColorSecondary,
        deletableColorSecondary: classes.deletableColorSecondary,
      }}
      className={disabled ? classes.disabled : ""}
      deleteIcon={
        onDelete && (
          <IconButton disabled={disableDelete}>
            <IconClose viewBox="-5 -5 210 210" withoutSpan />
          </IconButton>
        )
      }
      color={isActive ? "secondary" : "default"}
      disabled={disabled}
      clickable={clickable}
      onDelete={onDelete}
      {...rest}
    />
  );
};

Tag.defaultProps = {
  classes: {},
  clickable: false,
  disabled: false,
  onDelete: null,
  isActive: false,
};

Tag.propTypes = {
  classes: PropTypes.object,
  clickable: PropTypes.bool,
  disabled: PropTypes.bool,
  onDelete: PropTypes.func,
  isActive: PropTypes.bool,
};

export default withStyles(styles)(Tag);
