// import dynamic from "next/dynamic";

import Header from "./common/Header";
import Footer from "./common/Footer";

import useStyles from "./globalStyles";
// import { useRouter } from "next/router";

// const MarketingCampaigns = dynamic(() => import("./MarketingCampaigns"), {
//   ssr: false,
// });

const Layout = ({
  children,
  classes: rootClasses = {},
  hideNav = false,
  hideFooter = false,
}) => {
  const classes = useStyles({ classes: rootClasses });
  // const router = useRouter();

  // const hascampaing = !!router?.query.campaign;

  return (
    <div className={classes.layout}>
      {!hideNav && <Header />}

      <main className={classes.mainContainer}>
        <div className={classes.mainContent}>{children}</div>
      </main>

      {!hideFooter && <Footer hideNav={hideNav} />}
      {/* {hascampaing && <MarketingCampaigns />} */}
    </div>
  );
};

export default Layout;
