const styles = (theme) => ({
  root: {},

  default: {},

  // variant={button}
  button: {
    position: "relative",
    margin: 0,

    "&$error": {
      "& $label": {
        borderColor: theme.palette.error.main,
        color: theme.palette.error.main,
      },

      "& $checkbox": {
        "&$checked": {
          "& + $label": {
            backgroundColor: theme.palette.error.main,
            color: theme.palette.common.white,
          },
        },
      },
    },

    "& $label": {
      ...theme.typography.subtitle2,
      border: `1px solid ${theme.palette.primary.main}`,
      lineHeight: "1.2em",
      margin: 0,
      minWidth: "4em",
      padding: "1em",
      position: "relative",
      textAlign: "center",
      transition: theme.transitions.create("all"),
      zIndex: 10,
    },

    "& $checkbox": {
      backgroundColor: theme.palette.background.paper,
      height: "100%",
      position: "absolute",
      width: "100%",

      "& + $label:hover": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
      },

      "&$checked": {
        "& + $label": {
          backgroundColor: theme.palette.common.highlight,
          color: theme.palette.common.white,
        },
      },

      "& > span": {
        opacity: 0,
      },
    },
  },

  // variant={check}
  check: {
    padding: 0,

    "& .icon > svg": {
      color: "inherit",
    },

    "& $checkbox": {
      padding: 0,
    },

    "& $checked .icon circle": {
      fill: theme.palette.secondary.main,
    },
  },

  // variant={square}
  square: {
    padding: 0,

    "&$error": {
      "& .icon > svg": {
        color: theme.palette.error.main,
      },
    },

    "& .icon > svg": {
      // TODO: move this to a prop color="highlight" when a second color is needed.
      color: theme.palette.common.highlight,
    },

    "& $label": {
      ...theme.typography.subtitle2,
    },

    "& $checkbox": {
      padding: 0,
    },

    "& $checked .icon circle": {
      fill: theme.palette.secondary.main,
    },
  },

  label: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },

  error: {},
  checkbox: {},
  checked: {},
});

export default styles;
