const Globe = () => (
  <>
    <g>
      <path
        data-name="Path 18603"
        d="M30,30Q57.5,0,100,0t70,30q30,27.5,30,70t-30,70q-27.5,30-70,30T30,170C11.667,150,0,128.333,0,100S10,48.333,30,30Zm83.333,150q10,0,25-15C145,153.333,150,141.667,150,130a27.6,27.6,0,0,0-8.333-20C135,103.333,128.333,100,120,100H103.333c-5,0-10-1.667-15-3.333C85,93.333,83.333,90,83.333,85c0-3.333,1.667-5,3.333-6.667S91.667,75,93.333,75s6.667,1.667,8.333,5c3.333,1.667,5,3.333,6.667,3.333s5,0,6.667-1.667,1.667-5,1.667-6.667c0-5-3.333-11.667-8.333-16.667,5-10,8.333-20,8.333-31.667a3.585,3.585,0,0,0-3.333-3.333c-5-1.667-10-3.333-13.333-3.333-18.333,1.667-31.667,5-43.333,13.333-10,8.333-15,20-15,33.333S46.667,90,55,98.333a45.534,45.534,0,0,0,31.667,13.333h0v6.667C86.667,125,88.333,130,93.333,135q5,7.5,15,10v30c0,1.667,0,1.667,1.667,3.333S111.667,180,113.333,180Z"
      />
    </g>
  </>
);

export default Globe;
