import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { ListItem as MuiListItem } from "@material-ui/core";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { PLACEHOLDER_AVATAR } from "../../../lib/constants";
import { createSvgIcon } from "../icons";
import Avatar from "../Avatar";
import Link from "../Link";

const ListItem = ({
  button,
  classes,
  data,
  index,
  onClickItem = () => {},
  onMouseEnterItem = () => {},
  ...rest
}) => {
  const {
    avatar,
    isActive,
    icon,
    img,
    heading,
    description,
    link,
    onClick = onClickItem,
    onMouseEnter = onMouseEnterItem,
    ...dataRest
  } = data;
  const activeClass = isActive ? classes.selected : "";
  const Icon = createSvgIcon(icon);

  const renderListItemText = () => (
    <>
      <ListItemText
        className={classes.listItemText}
        primary={
          heading && (
            <Typography
              className={classes.title}
              component="div"
              variant="subtitle2"
            >
              {heading}
            </Typography>
          )
        }
        secondary={
          description && (
            <Typography
              className={classes.subTitle}
              component="div"
              variant="subtitle2"
            >
              {description}
            </Typography>
          )
        }
      />
      {icon && (
        <ListItemIcon className={classes.listItemIcon}>
          <Icon />
        </ListItemIcon>
      )}
      {img && img}
    </>
  );

  return (
    <MuiListItem
      className={`${classes.listItem} ${activeClass}`}
      classes={{ disabled: classes.disabled }}
      button={button}
      onClick={(e) => onClick(e, index, data)}
      onMouseEnter={(e) => onMouseEnter(e, index, data)}
      selected={isActive}
      tabIndex={index + 1}
      {...rest}
      {...dataRest}
    >
      {avatar && (
        <Avatar
          className={`${classes.avatar} ${avatar.className || ""}`}
          src={avatar.src || PLACEHOLDER_AVATAR}
          alt={avatar.alt || "No user defined"}
        />
      )}

      {link && (
        <Link href={link} className={classes.link} role="presentation">
          {renderListItemText()}
        </Link>
      )}

      {!link && renderListItemText()}
    </MuiListItem>
  );
};

ListItem.defaultProps = {
  avatar: null,
  button: false,
  classes: {},
  data: {
    icon: () => {},
    img: "",
    isActive: false,
    heading: "",
    description: "",
    link: null,
    onClick: () => {},
  },
  index: 0,
  onClickItem: () => {},
};

ListItem.propTypes = {
  avatar: PropTypes.object,
  button: PropTypes.bool,
  classes: PropTypes.object,
  data: PropTypes.shape({
    icon: PropTypes.any,
    img: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    isActive: PropTypes.bool,
    heading: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    link: PropTypes.string,
    onClick: PropTypes.func,
  }),
  index: PropTypes.node,
  onClickItem: PropTypes.func,
};

export default ListItem;
