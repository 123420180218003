import { omit } from "lodash-es";

import { Input } from "@/ui";
import { IconSearch } from "@/icons";

import useStyles from "./styles";

const SearchBox = ({ InputProps, placeholder, ...rest }) => {
  const classes = useStyles({ classes: rest.classes || {} });
  // Legacy: clean up collapsed props from MUI
  const whitelistProps = omit(rest, "InputLabelProps");

  return (
    <Input
      classes={{
        root: classes.inputRoot,
        formControl: classes.formControl,
        formControFooter: classes.formControFooter,
      }}
      endAdornment={<IconSearch viewBox="0 0 28 28" />}
      fullWidth
      inputRef={InputProps.ref}
      margin="dense"
      placeholder={placeholder}
      {...whitelistProps}
    />
  );
};

export default SearchBox;
