import { useEffect, useRef } from "react";
import { Grid, IconButton } from "@/ui";
import { IconClose } from "@/icons";

import { megaNav } from "./constants";
import MegaSubNav from "./MegaSubNav";

import useStyles from "./styles";

const MegaNav = ({ openMegaMenu, toggleMegaMenu }) => {
  const classes = useStyles();
  const megaMenuRef = useRef();
  const observerRef = useRef();

  const handleDelegateItemClick = (e) => {
    if (e.target.tagName === "A") {
      toggleMegaMenu();
    }
  };

  useEffect(() => {
    if (!observerRef.current) {
      observerRef.current = new IntersectionObserver(([entry]) => {
        if (!entry.isIntersecting) {
          toggleMegaMenu();
        }
      });
    }
  }, [toggleMegaMenu]);

  useEffect(() => {
    const currMegaMenu = megaMenuRef.current;
    const observer = observerRef.current;
    const isActive = openMegaMenu && currMegaMenu && observer;

    if (isActive) {
      observer.observe(currMegaMenu);
    }

    return () => {
      if (isActive) {
        observer.unobserve(currMegaMenu);
      }
    };
  }, [megaMenuRef, openMegaMenu]);

  return (
    <nav
      ref={megaMenuRef}
      className={classes.megaNav}
      onClick={handleDelegateItemClick}
    >
      <Grid container className={classes.megaNavContainer}>
        <Grid item xs={12} sm={10} className={classes.megaNavColumns}>
          <MegaSubNav data={megaNav.locations} />
        </Grid>

        <Grid item xs={12} sm={2}>
          <MegaSubNav
            data={megaNav.categories}
            classes={{ megaSubNav: classes.megaSubNavCategories }}
          />

          <MegaSubNav data={megaNav.topics} />
        </Grid>
      </Grid>

      <div className={classes.megaNavCloseBtn}>
        <IconButton onClick={toggleMegaMenu}>
          <IconClose size="md" viewBox="-6 -6 212 212" />
        </IconButton>
      </div>
    </nav>
  );
};

export default MegaNav;
