import { omit } from "lodash-es";
import { Input as MuiInput } from "@/ui";

import useStyles from "./styles";

const Input = ({ InputProps, label, classes: rootClasses, ...params }) => {
  const classes = useStyles({ classes: rootClasses });
  const whitelistProps = omit(params, ["InputLabelProps", "egText"]); // clean up collapsed props from MUI

  return (
    <MuiInput
      label={label}
      inputRef={InputProps.ref}
      classes={{
        root: classes.inputRoot,
        inputElem: classes.inputElem,
        formControl: classes.formControl,
        formControFooter: classes.formControFooter,
        ...rootClasses,
      }}
      {...whitelistProps}
    />
  );
};

export default Input;
