const RotateRight = () => (
  <>
    <g data-name="Group 29939" transform="translate(0.399 0.467)">
      <g data-name="Group 29929">
        <path
          data-name="Path 18600"
          d="M1853.364,2522.619l-20.654-20.646a.768.768,0,0,0-1.082,0l-20.655,20.646a.771.771,0,0,0,0,1.083l20.655,20.655a.771.771,0,0,0,1.082,0l20.654-20.655A.769.769,0,0,0,1853.364,2522.619Zm-21.2-19.031,19.572,19.573-19.572,19.579-19.579-19.579Z"
          transform="translate(-1799.595 -2485.144)"
        />
        <path
          data-name="Path 18601"
          d="M5741.643,1910.246a1.206,1.206,0,0,0,2.407-.157c-1.1-16.121,8.217-22.694,23.152-24.289l-2.794,3.468a1.2,1.2,0,0,0,1.872,1.51l4.534-5.619a1.192,1.192,0,0,0-.22-1.789l-6-4.375a1.212,1.212,0,0,0-1.43,1.957l3.432,2.49C5750.479,1885.268,5740.458,1892.978,5741.643,1910.246Z"
          transform="translate(-5741.548 -1878.752)"
        />
      </g>
    </g>
  </>
);

export default RotateRight;
