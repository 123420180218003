import { Avatar, Link } from "@/ui";

import useStyles from "./styles";

const CardHeader = ({
  userProfile = {},
  classes: rootClasses = {},
  avatarTransformations = {},
}) => {
  const {
    screenName = "",
    userScreenName = "",
    userId = "",
    cloudId: userCloudId = "",
    profilePicUrl = "",
  } = userProfile || {};
  const userName = screenName || userScreenName || "AtlasGuru Contributor";
  const classes = useStyles({ classes: rootClasses });

  return (
    <header className={classes.header}>
      <Link
        href={userId ? "/user/[...params]" : "/about"}
        as={userId ? `/user/${userId}/${userName}` : ""}
        className={classes.userLink}
      >
        <div className={classes.userWrapper}>
          <Avatar
            alt={userName}
            transformations={avatarTransformations}
            className={classes.avatar}
            cloudId={userCloudId}
            src={profilePicUrl}
          />
          <strong className={classes.userName}>
            by <span>{userName}</span>
          </strong>
        </div>
      </Link>
    </header>
  );
};

export default CardHeader;
