import { useController } from "react-hook-form";
import { forwardRef } from "react";
import GooglePlace from "./GooglePlace";

import { getPlaceValue } from "./utils";

const GooglePlaceControlled = forwardRef((props, ref) => {
  const {
    control,
    name,
    placeValue = getPlaceValue,
    rules = {},
    ...rest
  } = props;
  const { field } = useController({ name, control, rules });
  const { onChange, value, ...others } = field;

  const onPlaceChange = (place) => {
    if (!place) return;
    onChange(placeValue(place));
  };

  return (
    <GooglePlace
      {...rest}
      initialValue={value}
      onChange={onPlaceChange}
      {...others}
      ref={ref}
    />
  );
});

GooglePlaceControlled.displayName = "GooglePlaceControlled";

export default GooglePlaceControlled;
