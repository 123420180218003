const InstagramLight = () => (
  <>
    <g>
      <path d="M100,200A100,100,0,1,1,200,100,100.1,100.1,0,0,1,100,200ZM100,5a95,95,0,1,0,95,95A95.08,95.08,0,0,0,100,5Z" />
      <path d="M82.82,55.66h34a23.51,23.51,0,0,1,23.49,23.49v34a23.49,23.49,0,0,1-23.49,23.48h-34a23.5,23.5,0,0,1-23.49-23.48v-34A23.49,23.49,0,0,1,82.82,55.66ZM82,63.76A14.58,14.58,0,0,0,67.43,78.34V114A14.57,14.57,0,0,0,82,128.55h35.63A14.58,14.58,0,0,0,132.22,114V78.34a14.57,14.57,0,0,0-14.58-14.58Zm39.07,6.07a5.07,5.07,0,1,1-3.58,1.49A5.06,5.06,0,0,1,121.08,69.83ZM99.82,75.91a20.25,20.25,0,1,1-14.31,5.93A20.25,20.25,0,0,1,99.82,75.91Zm0,8.1a12.15,12.15,0,1,0,8.59,3.56A12.15,12.15,0,0,0,99.82,84Z" />
    </g>
  </>
);

export default InstagramLight;
