const styles = (theme) => ({
    modalWrapper: {
      display: "flex",
      marginTop: theme.spacing(3),
      justifyContent: "center",
  
      "& > button:last-child": {
        marginLeft: theme.spacing(3),
      }
    },
  });
  
  export default styles;
  