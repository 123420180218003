import { useUser as useAuth0 } from "@auth0/nextjs-auth0";
import { useEffect, useState } from "react";
// app
import { USER_ADD_ITINERARIES, useUser } from "@/data";
import { getAuthClaim } from "./auth";
import { deleteLocalStorage, fromLocalStorage } from "./storage";
import { SESSION_ITINERARIES_STORAGE_KEY } from "./constants";
import { useMutation } from "@apollo/client";
import { useRouter } from "next/router";
import { useApollo } from "./apolloClient";

const useCurrentUser = (config) => {
  const client = useApollo();
  const { cached = true } = config || {};
  // todo: move to user.js after refactoring
  const [isCreatingUser, setIsCreatingUser] = useState(false);
  const router = useRouter();

  const [addUserItineraries] = useMutation(USER_ADD_ITINERARIES);

  const {
    user: auth0User,
    isLoading: authLoading,
    error: authError,
  } = useAuth0();

  // custom claims
  const screenName = getAuthClaim(auth0User, "screen_name");
  const identities = getAuthClaim(auth0User, "identities");
  const userId = auth0User?.sub?.split("|").pop();

  const {
    data: dbUser,
    loading: dbLoading,
    error: dbError,
    save,
    fetch,
  } = useUser({
    userId,
    cached,
    wait: authLoading, // wait for user id to fetch this query
  });

  const loading = authLoading || dbLoading;
  const error = authError || dbError;

  useEffect(() => {
    if (isCreatingUser || loading) {
      return;
    }
    // user doesn't exist in the DB
    if (auth0User && dbUser && !dbUser?.userId) {
      setIsCreatingUser(true);
      const saveDB = async () => {
        await save({
          userId: auth0User.sub.split("|").pop(),
          screenName,
          email: auth0User.email,
        });
      };
      saveDB();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth0User, dbUser, isCreatingUser, loading]);

  const [isAddingItineraries, setIsAddingItineraries] = useState(false);
  useEffect(() => {
    const runEffect = async () => {
      const sessionItineraries = fromLocalStorage(
        SESSION_ITINERARIES_STORAGE_KEY
      );
      if (
        !isAddingItineraries &&
        !loading &&
        dbUser &&
        auth0User &&
        sessionItineraries
      ) {
        setIsAddingItineraries(true);
        try {
          await addUserItineraries({
            variables: {
              input: sessionItineraries,
            },
          });
          await client.refetchQueries({
            include: "active",
          });
          deleteLocalStorage(SESSION_ITINERARIES_STORAGE_KEY);
          window.location.reload();
        } finally {
          setIsAddingItineraries(false);
        }
      }
    };
    runEffect();
  }, [
    addUserItineraries,
    auth0User,
    client,
    dbUser,
    isAddingItineraries,
    loading,
    router,
  ]);

  return {
    user: dbUser,
    identities,
    loading,
    error,
    isAuthenticated: !!auth0User,
    isAdmin: !!dbUser?.isAdmin,
    save,
    fetch,
    isAddingItineraries,
  };
};

export default useCurrentUser;
