const Question = () => (
  <>
    <g>
      <g transform="translate(0.996 0.006)">
        <g transform="translate(0.004 -0.005)">
          <g>
            <g>
              <g>
                <path
                  d="M170.623,29.285A99.778,99.778,0,1,0,200,99.995a99.908,99.908,0,0,0-29.381-70.71Zm-72.2,133.04a15.28,15.28,0,1,1,15.272-15.3,15.276,15.276,0,0,1-15.272,15.3Zm40.778-76c-2.4,4-7.476,9.16-15.052,15.12-5.617,4.44-9.075,8-10.574,10.72a21.51,21.51,0,0,0-2.279,9.42H83.571v-1.74a36.338,36.338,0,0,1,1.7-12.16,26.343,26.343,0,0,1,4.977-8.42,160.768,160.768,0,0,1,15.512-14c4.717-3.84,7-7.36,7-10.78a10.3,10.3,0,0,0-3.038-7.86,12.509,12.509,0,0,0-8.835-2.82A13.989,13.989,0,0,0,90.467,68c-2.539,2.56-4.178,6.68-5,12.58L57.1,77.085A40.669,40.669,0,0,1,69.1,51.665c6.936-6.68,17.81-10,32.323-10,11.334,0,20.549,2.38,27.445,7.06,9.415,6.36,13.992,14.6,13.992,25.16a24.009,24.009,0,0,1-3.658,12.36Z"
                  transform="translate(-0.004 0.005)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </>
);

export default Question;
