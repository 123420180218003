import { gql } from "@apollo/client";

export const AI_ITINERARY_UPSERT = gql`
  mutation ($input: ItineraryAiInput!) {
    upsertItineraryAi(input: $input) {
      itineraryId
      slug
      url
      aiMetadata {
        aiStatus
        prompt
        error
      }
    }
  }
`;

export const AI_ITINERARY_DELETE = gql`
  mutation ($itineraryId: String!) {
    deleteItinerary(itineraryId: $itineraryId)
  }
`;

export const AI_ITINERARY_STATUS_NO_CACHE = gql`
  query AI_ITINERARY_STATUS_NO_CACHE($itineraryId: ID!) {
    Itinerary(itineraryId: $itineraryId) {
      itineraryId
      slug
      url
      aiMetadata {
        aiStatus
        prompt
        error
      }
    }
  }
`;

export const AI_ITINERARY_QUERY_NO_CACHE = gql`
  query AI_ITINERARY_QUERY_NO_CACHE($itineraryId: ID!) {
    Itinerary(itineraryId: $itineraryId) {
      itineraryId
      tripTitle
      url
      slug
      userId
      published
      numOfDays
      regionName
      shortDescription
      weatherInfo {
        summary
      }
      aiMetadata {
        specialRequests
        locations
        aiStatus
        prompt
        error
      }
      countries {
        name
      }
      userProfile {
        userId
        cloudId
        screenName
        profilePicUrl
      }
      tripPhotos {
        locationName
        photoUrl
        cloudId
        # caption
        url
      }
      locationsVisited {
        locationId
        numOfDays
        experienceDescription
        location {
          name
          formatted_address
          originalName
          geometry {
            location {
              lat
              lng
            }
          }
        }
        placesVisited {
          placeId
          originalName
          experienceDescription
        }
        recommendedRestaurants {
          description
          image
          name
          rating
          url
        }
        # recommendedHotels {
        #   description
        #   image
        #   name
        #   rating
        #   url
        # }
      }
      relatedItineraries {
        url
        itineraryId
        tripTitle
        numOfDays
        photoUrl
        cloudId
        regionName
        countries {
          name
        }
        userProfile {
          screenName
          profilePicUrl
          cloudId
          userId
        }
      }
      quotes {
        screenName
        profilePicUrl
        cloudId
        userId
        quote
      }
    }
  }
`;

export const AI_ITINERARIES_QUERY_NO_CACHE = gql`
  query AI_ITINERARIES_QUERY_NO_CACHE($userId: ID) {
    Itineraries(userId: $userId) {
      url
      slug
      cloudId
      tripTitle
      numOfDays
      dateCreated
      itineraryId
      isAiGenerated
      aiMetadata {
        specialRequests
      }
      locationsVisited {
        location {
          geometry {
            location {
              lat
              lng
            }
          }
        }
      }
    }
  }
`;

export const UPSERT_ITINERARY_AI = gql`
  mutation UpsertItineraryAi($input: ItineraryAiInput) {
    upsertItineraryAi(input: $input) {
      itineraryId
    }
  }
`;
