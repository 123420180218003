import PropTypes from "prop-types";
import { createSvgIcon } from "../icons";
import Link from "../Link";
import Tag from "../Tag";

const NavItem = ({
  children,
  classes,
  link,
  text,
  icon,
  iconSize,
  onClick,
  target,
  title,
  activeItem,
  tag,
  ...rest
}) => {
  const Icon = createSvgIcon(icon);

  return (
    <Link
      className={`${classes.link} ${activeItem ? classes.activeLink : ""}`}
      role={link ? "link" : "presentation"}
      onClick={onClick}
      target={target}
      title={title}
      href={link}
      {...rest}
    >
      {icon && (
        <Icon className={classes.itemIcon} viewBox={`0 0 ${iconSize}`} />
      )}

      {children}

      {text && <span className={classes.text}>{text}</span>}

      {tag && <Tag className={classes.tag} label={tag} color="secondary" />}
    </Link>
  );
};

NavItem.defaultProps = {
  children: null,
  classes: {},
  icon: null,
  iconSize: "200 200",
  link: "",
  onClick: () => {},
  target: "_self",
  text: "",
  title: "",
};

NavItem.propTypes = {
  children: PropTypes.element,
  classes: PropTypes.object,
  icon: PropTypes.any,
  iconSize: PropTypes.string,
  link: PropTypes.string,
  onClick: PropTypes.func,
  target: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string,
};

export default NavItem;
