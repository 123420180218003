const Square = () => (
  <>
    <g>
      <g transform="translate(3.287 3.287)" fill="none">
        <rect width="184.713" height="184.713" />
        <rect x="1" y="1" width="182.713" height="182.713" strokeWidth="15" />
      </g>
    </g>
  </>
);

export default Square;
