const Close = () => (
  <>
    <g transform="matrix(3 0 0 3 3 3)" strokeWidth="3">
      <g data-name="Group 29862" transform="translate(-.314 -.389)">
        <g data-name="Group 29787" transform="translate(.315 .389)" fill="none">
          <g data-name="Ellipse 2917">
            <circle cx="32.5" cy="32.5" r="32.5" stroke="none" />
            <circle cx="32.5" cy="32.5" r="32" />
          </g>
          <line
            data-name="Line 1401"
            x2="28.116"
            y2="28.116"
            transform="translate(18.442 18.439)"
          />
          <line
            data-name="Line 1402"
            x1="28.116"
            y2="28.116"
            transform="translate(18.442 18.439)"
          />
        </g>
      </g>
    </g>
  </>
);

export default Close;
