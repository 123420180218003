import { forwardRef } from "react";
import NextLink from "next/link";
import { makeStyles } from "@material-ui/core/styles";

/* TODO:
 * Fix svg issue on animation
 * https://stackoverflow.com/questions/39600138/how-to-simultaneously-transition-colour-of-nested-children
 */

const styles = (theme) => ({
  link: {
    textDecoration: "none",

    "&, .icon > svg": {
      transition: theme.transitions.create("color"),
    },

    "&:hover": {
      "&, .icon > svg": {
        color: theme.palette.common.highlight,
      },
    },

    // Color
    "&.secondary": {
      color: theme.palette.secondary.main,
    },

    // Underline
    "&.hover:hover": {
      textDecoration: "underline",
    },

    "&$always, &.always": {
      textDecoration: "underline",
    },
  },

  always: {},
});

const useStyles = makeStyles(styles);

const Link = ({
  as = "",
  children,
  className = "",
  color = "",
  href = "/",
  staticHtml = false,
  innerRef,
  underline,
  replace = false,
  shallow = false,
  ...rest
}) => {
  const link = href || "";
  const isExternal = link.startsWith("http");
  const isAnchorTag = isExternal || staticHtml;
  const classes = useStyles();

  const linkProps = {
    ...rest,
    ref: innerRef,
    className: `${className} ${classes.link} ${color} ${underline}`,
  };

  return isAnchorTag ? (
    <a href={link} {...linkProps}>
      {children}
    </a>
  ) : (
    <NextLink
      href={link}
      as={as}
      replace={replace}
      prefetch={false}
      shallow={shallow}
      {...linkProps}
    >
      {children}
    </NextLink>
  );
};

const LinkRef = forwardRef((props, ref) => <Link innerRef={ref} {...props} />);

LinkRef.displayName = "Link";

export default LinkRef;
