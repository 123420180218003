const Search = () => (
  <>
    <g>
      <path
        d="M29 29L22.6585 22.6585M1 13.6875C1 20.6946 6.68039 26.375 13.6875 26.375C20.6946 26.375 26.375 20.6946 26.375 13.6875C26.375 6.68038 20.6946 1 13.6875 1C6.6806 1.00051 1.00051 6.68059 1 13.6875Z"
        fill="transparent"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </>
);

export default Search;
