import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Avatar as MuiAvatar } from "@material-ui/core";
// app
import { DEFAULT_TRANSFORMATIONS } from "@/lib/constants";
import { buildUrl, cloudinaryEnvPath } from "@/lib/useCloudinary";

const avatarTransformations = {
  ...DEFAULT_TRANSFORMATIONS,
  crop: "limit",
  quality: "auto:eco",
  height: 200,
  width: 200,
};

const placeholder = buildUrl(
  `${cloudinaryEnvPath}/misc/avatar-placeholder-opt.00e67e77ebfc085b8ecf3ca1ad1a1633`,
  avatarTransformations
);

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    // outline: `2px solid ${theme.palette.common.white}`,
    boxShadow: `0 0 0 2px ${theme.palette.common.white}`,
    height: 35,
    width: 35,
  },
});

const Avatar = ({
  src = "",
  cloudId = "",
  height,
  width,
  transformations = {},
  ...rest
}) => {
  const margedTransformations = {
    ...avatarTransformations,
    ...transformations,
  };
  const avatarSrc = cloudId ? buildUrl(cloudId, margedTransformations) : src;

  return (
    <MuiAvatar
      src={avatarSrc || placeholder}
      imgProps={{
        loading: "lazy",
        decoding: "async",
        height: height || margedTransformations.height,
        width: width || margedTransformations.width,
      }}
      {...rest}
    />
  );
};

Avatar.propTypes = {
  alt: PropTypes.string.isRequired,
  cloudId: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default withStyles(styles)(Avatar);
