import { useEffect } from "react";
import { useRouter } from "next/router";
import { PAGE_SIZE } from "@/lib/constants";
import AtlasAnalytics from "@/lib/AtlasAnalytics";
import { Button, Grid, List, Link, Nav, Typography } from "@/ui";

import { getHitUrl, regionsLinks } from "./utils";
import NavTopics from "../NavTopics";

const atlasAnalytics = new AtlasAnalytics();

function searchListParser(hit) {
  return {
    heading: hit.tripTitle,
    description: hit.locationsVisited,
    img: hit.renderImage(hit),
    isActive: hit.index === hit.selected,
    // link: hit.url || exploreUrl
  };
}

const Results = ({
  classes,
  hits,
  searchTerm,
  onSearch,
  renderImage,
  selected,
  setTotalHits,
}) => {
  const router = useRouter();

  const items = hits.map((hit, i) =>
    searchListParser({ index: i, renderImage, ...hit })
  );

  const hasItems = items.length > 0;

  const handleAnalytics = (hit) => {
    if (hit.itineraryId) {
      atlasAnalytics.submitItinerarySearch(hit);
    } else {
      atlasAnalytics.searchResultNotFound(hit);
    }
  };

  const handleClickResult = (hit) => {
    handleAnalytics(hit);
    const { itineraryId, slug, url } = hit;
    const queryString = slug
      ? `/itinerary/${slug}/${itineraryId}/`
      : getHitUrl();
    router.push(queryString, getHitUrl(url));
  };

  const onClickItem = (e, i) => {
    e.preventDefault();
    handleClickResult({ ...hits[i], searchTerm });
  };

  useEffect(() => {
    setTotalHits(hits.length - 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hits.length, selected]);

  return (
    <div className={classes.results}>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <div className={classes.resultsWrapper}>
            {hasItems && (
              <>
                <List
                  classes={{
                    // root: classes.results,
                    listItem: classes.resultsListItem,
                    listItemText: classes.resultsItem,
                    link: classes.resultsLink,
                    title: classes.resultsTitle,
                    subTitle: classes.resultsSubTitle,
                  }}
                  items={items.slice(0, PAGE_SIZE)}
                  onClickItem={onClickItem}
                  variant="heading"
                />
                {items.length > PAGE_SIZE && (
                  <Button
                    className={classes.seeAllReports}
                    color="secondary"
                    onClick={onSearch}
                    variant="contained"
                    fullWidth
                  >
                    See All
                  </Button>
                )}
              </>
            )}

            {!hasItems && (
              <>
                <List
                  classes={{
                    root: classes.results,
                    listItem: classes.noResultsListItem,
                    listItemText: classes.noResultsItemText,
                    link: classes.resultsLink,
                    title: classes.noResultsTitle,
                    subTitle: classes.noResultsSubTitle,
                  }}
                  items={[
                    {
                      heading: `Sorry, no trip reports were found for "${searchTerm}".`,
                      img: "",
                    },
                  ]}
                  variant="heading"
                />
                <Link href="/itineraries">
                  <Button
                    className={classes.seeAllReports}
                    color="secondary"
                    variant="contained"
                    fullWidth
                  >
                    Explore More
                  </Button>
                </Link>
              </>
            )}
          </div>
        </Grid>

        <Grid item xs={12} sm={6}>
          <div className={classes.resultsAside}>
            <Grid container>
              <Grid item xs={12}>
                <NavTopics
                  title={<span>Related Topics</span>}
                  maxTopics={20}
                  showMoreBtn={false}
                  requestQuery={searchTerm}
                  keepTreeWhileEmpty={false}
                  classes={{
                    title: classes.resultsAsideTitle,
                    navigationEllipsis: classes.resultsAsideTopicsWrapper,
                    navigation: classes.resultsAsideTopicsNav,
                    link: classes.resultsAsideTopicsLink,
                    text: classes.resultsAsideTopicsText,
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography
                  variant="subtitle1"
                  className={classes.resultsAsideTitle}
                >
                  <span>Browse By Region</span>
                </Typography>
              </Grid>

              <Grid item xs={8} sm={12}>
                <Nav
                  classes={{
                    root: classes.regionsNav,
                    link: classes.regionsNavLink,
                    itemIcon: classes.regionsItemIcon,
                  }}
                  navItems={regionsLinks}
                />
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Results;
