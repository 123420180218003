const Check = () => (
  <>
    <g>
      <g transform="translate(-0.314 -0.389)">
        <g transform="translate(0.315 0.389)">
          <g transform="translate(0 0)" strokeWidth="6">
            <circle cx="100" cy="100" r="100" stroke="none" />
            <circle cx="100" cy="100" r="99" fill="none" />
          </g>
        </g>
      </g>
      <g transform="matrix(0.985, 0.174, -0.174, 0.985, 74.704, -3.496)">
        <path
          d="M127.034,0l1.1,18.148C86.676,49.582,65.458,83.284,45.282,124.52,31.015,108.4,12.567,98.515,0,90.343c3.353-3.926,6.683-7.694,10.106-11.459a153.89,153.89,0,0,1,34.981,32.322C63.824,72.731,94.919,26.1,127.034,0Z"
          transform="translate(0 3.105)"
          fill="#fff"
          stroke="#fff"
          strokeWidth="5"
        />
      </g>
    </g>
  </>
);

export default Check;
