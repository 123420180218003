import { Avatar, Card, Grid, Link, Section, Typography } from "@/ui";
import Cover from "./Cover";

import useStyles from "./styles";

const Hero = ({
  as = "",
  alt = "",
  src = "",
  link = "",
  headline = "",
  children = [],
  photos = {},
  userProfile = {},
  headlineComponent = "h1",
  classes: rootClasses,
}) => {
  const classes = useStyles({ classes: rootClasses });
  const { mobile, desktop } = photos || {};
  const isUserAvatarVisible = !!(userProfile?.screenName && userProfile.userId);

  return (
    <Section className={classes.root}>
      <Grid item xs={12}>
        <figure className={classes.figure}>
          <div className={classes.media}>
            {headline && (
              <Typography
                className={classes.headline}
                component={headlineComponent}
                variant="h1"
              >
                {headline}
              </Typography>
            )}

            <Cover
              alt={alt}
              src={src}
              link={link}
              as={as}
              mobile={mobile}
              desktop={desktop}
            />
          </div>

          <Card
            cardLink={false}
            component="figcaption"
            classes={{
              card: classes.card,
              content: `${classes.cardContent} ${
                isUserAvatarVisible ? classes.cardContentWithUser : ""
              }`,
            }}
          >
            <div className={classes.content}>
              {isUserAvatarVisible && (
                <div className={classes.userProfile}>
                  <Link
                    className={classes.userLink}
                    as={`/user/${userProfile.userId}/${userProfile.screenName}`}
                    href="/user/[...params]"
                  >
                    <Avatar
                      className={classes.avatar}
                      alt={userProfile.screenName}
                      cloudId={userProfile?.cloudId}
                      src={userProfile?.profilePicUrl}
                    />
                    <Typography
                      component="strong"
                      variant="caption"
                      className={classes.userLinkInfo}
                    >
                      {userProfile.screenName}
                    </Typography>
                  </Link>
                </div>
              )}

              <div className={classes.info}>{children}</div>
            </div>
          </Card>
        </figure>
      </Grid>
    </Section>
  );
};

export default Hero;
