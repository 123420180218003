const SignOut = () => (
  <>
    <g>
      <path
        d="M200.043,314.755c-12.657-.039-25.337.32-37.97-.3A35.548,35.548,0,0,1,128.2,279.636c-.391-15.508-.078-30.884-.078-46.509h-.078c0-14.844.172-29.556-.039-44.306-.3-20.173,15.2-34.1,29.314-36.564a55.836,55.836,0,0,1,9.2-.781c12.219-.086,24.438-.055,36.658,0,5.3,0,6.289.992,6.524,6.344.406,9.375-.164,9.953-9.375,9.953H166.635c-13.766,0-22.306,8.461-22.329,22.2q-.078,43.095,0,86.2c0,13.782,8.516,22.228,22.259,22.267h36.658c5.594,0,6.25.664,6.477,6.2C210.1,314.177,209.543,314.786,200.043,314.755Zm124.763-75.136q-33.8,33.9-67.682,67.729c-2.7,2.7-5.844,4.133-9.539,2.641s-5.406-4.688-5.391-8.711c.047-10.454-.125-20.907-.125-31.361V265.94h-4.1c-16.727,0-33.454-.094-50.181-.109-7.977,0-10.743-2.812-10.75-10.735V211.165c0-7.891,2.789-10.743,10.774-10.75,16.727,0,33.454-.1,50.182-.1h4.18c.07-1.562.164-2.61.164-3.781,0-10.563-.055-21.095,0-31.65.047-6.25,4.016-10.07,9.422-8.985a10.611,10.611,0,0,1,5.055,2.813q34.072,33.861,67.971,67.893C329.079,230.869,329.071,235.345,324.805,239.619Z"
        transform="translate(329.005 330.633) rotate(180)"
      />
    </g>
  </>
);

export default SignOut;
