import { useCallback, useState } from "react";
import { delay } from "lodash-es";

import { algoliaIndexNameItinerary } from "@/config";
import { Grid } from "@/ui";

import SearchBox from "../SearchBox";
import Brand from "./Brand";
import Navigation from "./Navigation";
import ShrinkNav from "./Navigation/ShrinkNav";
import MegaNav from "./Navigation/MegaNav";
import UserMenu from "./UserMenu";

import useStyles from "./styles";

const Header = ({ shrink, setIsStickyActive }) => {
  const classes = useStyles();
  const [openMegaMenu, setOpenMegaMenu] = useState("");

  const toggleMegaMenu = useCallback(
    (id = "") => {
      setOpenMegaMenu(openMegaMenu ? "" : id);
      delay(() => setIsStickyActive((sticky) => !sticky), 600);
    },
    [openMegaMenu, setIsStickyActive]
  );

  const onClickNavItem = (e, { id, hasSubMenu } = {}) => {
    if (hasSubMenu) {
      e.preventDefault();
      toggleMegaMenu(id);
    }
  };

  return (
    <div className={shrink && !openMegaMenu ? classes.shrink : ""}>
      <div className={classes.headerWrapper}>
        <Grid container className={classes.container}>
          <Grid item xs={12} md={3}>
            <div className={classes.shrinkNavigation}>
              <ShrinkNav shrink={shrink} />
              <Brand shrink={shrink} />
            </div>
          </Grid>

          <Grid item xs={12} md={6}>
            <Grid className={classes.midContent}>
              <Grid container className={classes.row}>
                <Grid item xs={12}>
                  <SearchBox
                    indexName={algoliaIndexNameItinerary}
                    classes={{
                      isActive: classes.isActive,
                      searchWrapper: classes.searchWrapper,
                      inputWrap: classes.inputWrap,
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Navigation
                    shrink={shrink}
                    openMegaMenu={openMegaMenu}
                    onClickNavItem={onClickNavItem}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={3}>
            <UserMenu />
          </Grid>
        </Grid>
      </div>

      <div
        className={`${classes.megaMenuWrapper} ${
          openMegaMenu ? classes.megaMenuOpened : ""
        }`}
      >
        <div
          className={`${classes.megaMenuInner} ${
            openMegaMenu === "" ? classes.megaMenuInnerAnimated : ""
          }`}
        >
          <Grid container>
            <Grid item xs={12}>
              <MegaNav
                openMegaMenu={openMegaMenu}
                toggleMegaMenu={toggleMegaMenu}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default Header;
