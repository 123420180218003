const Twitter = () => (
  <>
    <g>
      <path
        d="M200,100A100,100,0,1,1,100,0,99.972,99.972,0,0,1,200,100ZM81.7,152.75c44.35,0,68.6-36.75,68.6-68.6,0-1.05,0-2.1-.05-3.1a49.394,49.394,0,0,0,12.05-12.5,48.936,48.936,0,0,1-13.85,3.8A24.1,24.1,0,0,0,159.05,59a48.824,48.824,0,0,1-15.3,5.85,24.124,24.124,0,0,0-41.7,16.5,22.094,22.094,0,0,0,.65,5.5A68.4,68.4,0,0,1,53,61.65,24.1,24.1,0,0,0,60.5,93.8a23.684,23.684,0,0,1-10.9-3v.3a24.136,24.136,0,0,0,19.35,23.65,24.034,24.034,0,0,1-6.35.85,23.019,23.019,0,0,1-4.55-.45,24.073,24.073,0,0,0,22.5,16.75A48.466,48.466,0,0,1,50.6,142.2a43.842,43.842,0,0,1-5.75-.35,67.173,67.173,0,0,0,36.85,10.9"
        transform="translate(-0.156)"
      />
    </g>
  </>
);

export default Twitter;
