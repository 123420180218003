const RotateLeft = () => (
  <>
    <g data-name="Group 29938" transform="translate(-22.681 0.467)">
      <g data-name="Group 29929">
        <path
          data-name="Path 18600"
          d="M1810.974,2522.619l20.654-20.646a.768.768,0,0,1,1.082,0l20.655,20.646a.771.771,0,0,1,0,1.083l-20.655,20.655a.771.771,0,0,1-1.082,0l-20.654-20.655A.769.769,0,0,1,1810.974,2522.619Zm21.2-19.031-19.572,19.573,19.572,19.579,19.579-19.579Z"
          transform="translate(-1787.669 -2485.144)"
        />
        <path
          data-name="Path 18601"
          d="M5771.026,1910.246a1.206,1.206,0,0,1-2.407-.157c1.1-16.121-8.217-22.694-23.152-24.289l2.794,3.468a1.2,1.2,0,0,1-1.872,1.51l-4.534-5.619a1.192,1.192,0,0,1,.22-1.789l6-4.375a1.212,1.212,0,0,1,1.43,1.957l-3.432,2.49C5762.19,1885.268,5772.211,1892.978,5771.026,1910.246Z"
          transform="translate(-5694.048 -1878.752)"
        />
      </g>
    </g>
  </>
);

export default RotateLeft;
