import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { List as MuiList } from "@material-ui/core";
import ListItem from "./ListItem";

import styles from "./styles";

const List = ({
  children,
  className,
  classes,
  iconPos,
  itemComponent,
  component,
  items,
  onClickItem,
  onMouseEnterItem,
  buttonList,
  type,
  variant,
  ...rest
}) => (
  <MuiList
    component={component || "ul"}
    className={`${classes.root} ${className} ${classes[variant]} ${
      classes[type]
    } ${iconPos ? classes[`icon${iconPos}`] : ""}`}
    disablePadding
    {...rest}
  >
    {items.map((item, index) => (
      <ListItem
        classes={classes}
        button={buttonList}
        data={item}
        key={index}
        index={index}
        component={itemComponent}
        onClickItem={onClickItem}
        onMouseEnterItem={onMouseEnterItem}
      />
    ))}

    {children && (
      <ListItem
        classes={classes}
        button={buttonList}
        data={{ heading: children }}
        onClickItem={onClickItem}
        onMouseEnterItem={onMouseEnterItem}
      />
    )}
  </MuiList>
);

List.defaultProps = {
  buttonList: false,
  children: null,
  classes: {},
  className: "",
  component: "ul",
  iconPos: "end",
  itemComponent: "li",
  items: [],
  onClickItem: () => {},
  type: "unordered",
  variant: "list",
};

List.propTypes = {
  buttonList: PropTypes.bool,
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  component: PropTypes.string,
  iconPos: PropTypes.string,
  itemComponent: PropTypes.string,
  items: PropTypes.array,
  onClickItem: PropTypes.func,
  type: PropTypes.string,
  variant: PropTypes.string,
};

export default withStyles(styles)(List);
