const Dragging = () => (
  <>
    <g data-name="Group 29923" transform="translate(-123.81 -1154.583)">
      <path
        data-name="Path 18605"
        d="M64.733,24.958V85.569H71.74V24.958L98.7,51.894l4.967-4.967L68.237,11.5,32.81,46.927l4.967,4.967Z"
        transform="translate(91 1143.083)"
      />
      <path
        data-name="Path 18606"
        d="M71.74,113.171V52.56H64.733v60.611L37.777,86.235,32.81,91.2l30.46,30.46,1.463,1.463,2.04,2.04,1.463,1.463,1.463-1.463,2.04-2.04,1.463-1.463,30.46-30.46L98.7,86.235Z"
        transform="translate(91 1186.644)"
      />
    </g>
  </>
);

export default Dragging;
