export default [
  { elementType: "geometry.fill", stylers: [{ lightness: 25 }] },
  {
    elementType: "labels.icon",
    stylers: [{ visibility: "on" }, { weight: 3 }]
  },
  {
    elementType: "labels.text",
    stylers: [{ color: "#1b345a" }, { visibility: "simplified" }]
  },
  { elementType: "labels.text.fill", stylers: [{ color: "#babaac" }] },
  {
    featureType: "administrative.land_parcel",
    stylers: [{ visibility: "off" }]
  },
  {
    featureType: "administrative.neighborhood",
    stylers: [{ visibility: "off" }]
  },
  {
    featureType: "landscape",
    stylers: [
      { color: "#f0f0ec" },
      { saturation: -15 },
      { visibility: "on" },
      { weight: 0.5 }
    ]
  },
  { featureType: "landscape.natural", stylers: [{ visibility: "simplified" }] },
  {
    featureType: "poi",
    elementType: "labels.text",
    stylers: [{ visibility: "off" }]
  },
  { featureType: "poi.business", stylers: [{ visibility: "off" }] },
  { featureType: "road", stylers: [{ visibility: "off" }] },
  {
    featureType: "road",
    elementType: "labels",
    stylers: [{ visibility: "off" }]
  },
  {
    featureType: "road",
    elementType: "labels",
    stylers: [{ visibility: "off" }]
  },
  {
    featureType: "road",
    elementType: "labels.icon",
    stylers: [{ visibility: "off" }]
  },
  {
    featureType: "transit",
    stylers: [{ visibility: "off" }]
  },
  {
    featureType: "water",
    elementType: "geometry.fill",
    stylers: [{ color: "#b2daeb" }, { visibility: "on" }]
  },
  {
    featureType: "water",
    elementType: "labels.text",
    stylers: [{ visibility: "off" }]
  }
];
