import { gql } from "@apollo/client";

// NOTE: these are all generic query/mutations, they return all fields by default
// If you want a custom query mutation to return specific fields only you can create a custom one
// ex:

// QUERY: (cached by CDN - by default)
//
// export const USER_ITINERARIES_PROFILE = gql`
//   query USER_ITINERARIES_CUSTOM($userId: ID!) {
//     Itineraries(userId: $userId) {
//       itineraryId
//       userProfile {
//         bio
//         screenName
//         profilePicUrl
//       }
//     }
//   }
// `;

// QUERY (non-cached):
//
// export const USER_ITINERARIES_PROFILE_NO_CACHE = gql`
//   query USER_ITINERARIES_CUSTOM_NO_CACHE($userId: ID!) {
//     Itineraries(userId: $userId) {
//       itineraryId
//       userProfile {
//         bio
//         screenName
//         profilePicUrl
//       }
//     }
//   }
// `;

// MUTATION
//
// export const ITINERARY_UPSERT_TITLE = gql`
//   mutation ($input: ItineraryInput) {
//     upsertItinerary(input: $input) {
//       itineraryId
//       tripTitle
//     }
//   }
// `;

// Itinerary fragment with all data
export const ITINERARY_FRAGMENT = gql`
  fragment ItineraryData on Itinerary {
    published
    itineraryId
    slug
    url
    regionName
    tripTitle
    monthsTravelled
    yearTravelled
    shortDescription
    highlight
    lowlight
    numOfDays
    updatedAt
    itineraryOverview
    unpublishedChangesExist
    isAiGenerated
    aiMetadata {
      specialRequests
      locations
      prompt
    }
    partner {
      url
      linkText
      headline
    }
    locationsVisited {
      locationId
      index
      numOfDays
      isDayTrip
      lodgingName
      reasonToVisit
      experienceDescription
      tripPhotos {
        isHero
        photoUrl
        caption
        cloudId
        photoSlot
      }
      location {
        name
        formatted_address
        originalName
        geometry {
          location {
            lat
            lng
          }
        }
      }
      placesVisited {
        placeId
        name
        formatted_address
        originalName
        geometry {
          location {
            lat
            lng
          }
        }
      }
    }
    userProfile {
      bio
      screenName
      profilePicUrl
      cloudId
      userId
      partner {
        id
        name
        logo
      }
    }
    countries {
      name
    }
    tripPhotos {
      isHero
      photoUrl
      caption
      cloudId
      photoSlot
    }
    questionsAndAnswers {
      questionAndAnswerId
      question
      answer
      questionId
      sortOrder
    }
  }
`;

// QUERIES
export const ITINERARY_QUERY = gql`
  query ITINERARY_QUERY($id: ID!) {
    Itinerary(itineraryId: $id) {
      ...ItineraryData
    }
  }
  ${ITINERARY_FRAGMENT}
`;

export const ITINERARY_QUERY_NO_CACHE = gql`
  query ITINERARY_QUERY_NO_CACHE($id: ID!) {
    Itinerary(itineraryId: $id) {
      ...ItineraryData
    }
  }
  ${ITINERARY_FRAGMENT}
`;

export const ITINERARY_PREVIEW = gql`
  query ITINERARY_PREVIEW($id: ID!) {
    ItineraryPreview(itineraryId: $id) {
      ...ItineraryData
    }
  }
  ${ITINERARY_FRAGMENT}
`;

export const ITINERARY_PREVIEW_NO_CACHE = gql`
  query ITINERARY_PREVIEW_NO_CACHE($id: ID!) {
    ItineraryPreview(itineraryId: $id) {
      ...ItineraryData
    }
  }
  ${ITINERARY_FRAGMENT}
`;

export const USER_ITINERARIES = gql`
  query USER_ITINERARIES($userId: ID!, $limit: Int) {
    Itineraries(userId: $userId, limit: $limit) {
      ...ItineraryData
    }
  }
  ${ITINERARY_FRAGMENT}
`;

export const USER_ITINERARIES_NO_CACHE = gql`
  query USER_ITINERARIES_NO_CACHE($userId: ID!, $limit: Int) {
    Itineraries(userId: $userId, limit: $limit) {
      ...ItineraryData
    }
  }
  ${ITINERARY_FRAGMENT}
`;

// MUTATIONS

export const ITINERARY_UPSERT = gql`
  mutation ($input: ItineraryInput) {
    upsertItinerary(input: $input) {
      ...ItineraryData
    }
  }
  ${ITINERARY_FRAGMENT}
`;

export const LOCATION_UPSERT = gql`
  mutation ($input: LocationInput) {
    upsertLocation(input: $input) {
      ...ItineraryData
    }
  }
  ${ITINERARY_FRAGMENT}
`;

export const LOCATIONS_BATCH_UPSERT = gql`
  mutation ($input: [LocationInput]) {
    upsertLocationsBatch(input: $input) {
      ...ItineraryData
    }
  }
  ${ITINERARY_FRAGMENT}
`;

export const LOCATION_DELETE = gql`
  mutation ($input: DeleteLocationInput) {
    deleteLocation(input: $input) {
      ...ItineraryData
    }
  }
  ${ITINERARY_FRAGMENT}
`;

export const LOCATIONS_ORDER_UPSERT = gql`
  mutation ($input: LocationsOrderInput) {
    upsertLocationsOrder(input: $input) {
      ...ItineraryData
    }
  }
  ${ITINERARY_FRAGMENT}
`;

export const PLACE_UPSERT = gql`
  mutation ($input: PlaceInput) {
    upsertPlace(input: $input) {
      ...ItineraryData
    }
  }
  ${ITINERARY_FRAGMENT}
`;

export const PLACE_DELETE = gql`
  mutation ($input: DeletePlaceInput) {
    deletePlace(input: $input) {
      ...ItineraryData
    }
  }
  ${ITINERARY_FRAGMENT}
`;

// mutations not returning fields (fix?)
export const ITINERARY_PUBLISH = gql`
  mutation ($itineraryId: String!) {
    publishItinerary(itineraryId: $itineraryId)
  }
`;

export const ITINERARY_UNPUBLISH = gql`
  mutation ($itineraryId: String!) {
    unpublishItinerary(itineraryId: $itineraryId)
  }
`;

export const ITINERARY_DELETE = gql`
  mutation ($itineraryId: String!) {
    deleteItinerary(itineraryId: $itineraryId)
  }
`;
