import { useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import useScroll from "@/lib/useScroll";

import Header from "./Header";

import useStyles from "./styles";

const StickyHeader = () => {
  const classes = useStyles();
  const {
    bounds: { scrollTop },
  } = useScroll({ trackScroll: true });
  const [isStickyActive, setIsStickyActive] = useState(true);
  const isHeaderShrink = scrollTop > 100;
  const position = isStickyActive ? "fixed" : "absolute";

  return (
    <div className={classes.root}>
      <AppBar color="transparent" position={position} elevation={0}>
        <Header shrink={isHeaderShrink} setIsStickyActive={setIsStickyActive} />
      </AppBar>
    </div>
  );
};

export default StickyHeader;
