import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import NavItem from "./NavItem";

import styles from "./styles";

const Nav = ({
  children,
  classes,
  className,
  color,
  iconSize,
  legend,
  iconActiveClass,
  navItems,
  activeItem,
  onClick,
  variant,
}) => {
  const renderItems = (itemProps, index) => {
    let key = `nav-item-${index}`;

    if (itemProps.title) {
      key += itemProps.title;
    } else if (itemProps.text) {
      key += itemProps.text;
    } else {
      key += index;
    }

    const itemIconClass =
      itemProps.id === activeItem ? iconActiveClass : classes.itemIcon;

    // eslint-disable-next-line no-unused-vars
    const { hasSubMenu, ...props } = itemProps;

    return (
      <NavItem
        classes={{
          link: classes.link,
          itemIcon: itemIconClass,
          activeLink: classes.activeLink,
          text: classes.text,
          tag: classes.tag,
        }}
        iconSize={iconSize}
        key={key.replace(" ", "-").toLowerCase()}
        activeItem={itemProps.id === activeItem}
        onClick={(e) => onClick(e, itemProps)}
        {...props}
      />
    );
  };

  return (
    <nav
      className={`${className} ${classes.root} ${classes[color]} ${classes[variant]}`}
    >
      {legend}

      {navItems.map(renderItems)}

      {children}
    </nav>
  );
};

Nav.defaultProps = {
  children: [],
  classes: {},
  className: "",
  color: "primary",
  iconSize: "200 200",
  legend: "",
  navItems: [],
  activeItem: "",
  iconActiveClass: "",
  onClick: () => {},
  variant: "default",
};

Nav.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  classes: PropTypes.object,
  className: PropTypes.string,
  color: PropTypes.string,
  iconSize: PropTypes.string,
  legend: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  navItems: PropTypes.arrayOf(PropTypes.object),
  activeItem: PropTypes.string,
  iconActiveClass: PropTypes.string,
  onClick: PropTypes.func,
  variant: PropTypes.string,
};

export default withStyles(styles)(Nav);
