const styles = (theme) => ({
  card: {
    boxShadow: theme.atlasGuru.boxShadow(),
    margin: `0 0 ${theme.spacing(2)}px`,
    position: "relative",
    transition: theme.transitions.create("box-shadow"),

    "& h2": {
      transition: theme.transitions.create("color"),
    },

    "&$cardLink:hover": {
      boxShadow: theme.atlasGuru.boxShadow({
        color: theme.atlasGuru.boxShadowHighlight,
        opacity: 0.7,
      }),

      "& h2": {
        color: theme.palette.common.highlight,
      },
    },

    // fullwidth card
    "&$cardFullWidth": {
      marginLeft: `-${theme.gutter}px`,
      marginRight: `-${theme.gutter}px`,
      width: `calc(100% + ${theme.gutter * 2}px)`,
    },

    // gutter size
    "&$thinGutter": {
      [theme.breakpoints.only("xs")]: {
        marginLeft: theme.gutter * -0.5,
        marginRight: theme.gutter * -0.5,
      },
    },

    "&$partner": {
      "& $content": {
        paddingBottom: theme.gutter * 2,
        position: "static",
      },
    },

    // variant={default}
    "&$default": {
      padding: "1.5em 0",

      "& $title": {
        // ...theme.typography.h5,
        marginBottom: "0.9em",
        textAlign: "center",
      },
    },
  },

  // {children}
  userLink: {
    display: "inline-block",
    "&, &:hover": {
      color: `${theme.palette.common.black} !important`,
    },
  },

  userProfileLink: {
    ...theme.typography.caption,
    color: theme.palette.grey[700],

    "& a": {
      color: theme.palette.grey[800],
    },
  },

  cardLink: {},

  content: {},

  loaderContainer: {
    ...theme.backgrounds.preloader,
    animationDuration: "8s",
    animationIterationCount: 1,
    position: "relative",
    fontSize: 100,
  },

  mediaContainer: {
    backgroundPosition: "50% 50%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    overflow: "hidden",
    paddingTop: theme.atlasGuru.aspectRatio.wide,
    width: "100%",
  },

  mediaLoader: {
    backgroundPosition: "95% 95%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "25%",
    opacity: 0.2,
  },

  image: {
    display: "none",
  },

  link: {
    color: theme.palette.common.black,
    textDecoration: "none",
  },

  headline: {
    ...theme.typography.jumbo,
    color: theme.palette.common.white,
    textShadow: `1px 1px 5px ${theme.palette.common.black}99`,
    backgroundColor: `${theme.palette.common.black}33`,
    position: "absolute",
    top: 0,
    left: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    padding: "1em 1em 2em",
    height: "100%",
    width: "100%",
  },

  title: {},

  partner: {},

  partnerSection: {
    bottom: theme.gutter,
    marginBottom: theme.spacing(1) * -1,
    marginTop: theme.spacing(2),
    position: "absolute",
    right: theme.gutter,
  },

  partnerLegend: {},

  cardPhotoAvatar: {
    width: 50,
    height: 50,
    transform: `translateY(-${theme.spacing(0.5)}px)`,

    [theme.breakpoints.only("sm")]: {
      width: 38,
      height: 38,
    },
  },

  cardPhotoHeader: {
    alignItems: "flex-start",
    background: "transparent",
    flexDirection: "column-reverse",
    padding: 0,
    paddingTop: theme.spacing(1.5),
    paddingLeft: theme.spacing(1),
    position: "absolute",
    top: 0,
    zIndex: 20,

    "&:before, & img": {
      transform: "scale(1)",
      transformOrigin: "50%",
      transition: theme.transitions.create("transform", {
        duration: 200,
        easing: theme.transitions.easing.easeIn,
      }),
    },

    "& $userProfileLink": {
      "& a:hover": {
        color: theme.palette.primary.dark,
      },
    },

    "&:before": {
      pointerEvents: "none",
      background: `radial-gradient(closest-side,
        rgba(255,255,255,.95) 0%,
        rgba(255,255,255,.9) 20%,
        rgba(255,255,255,0) 70%,
        rgba(255,255,255,0) 100%
      )`,
      content: "''",
      display: "block",
      width: 350,
      height: 100,
      left: `-${theme.spacing(16.5)}px`,
      position: "absolute",
      top: `-${theme.spacing(4.5)}px`,
    },

    "&:hover": {
      "&:before": {
        transform: "scale(1.4)",
      },

      "& img": {
        transform: "scale(1.1)",
      },

      "& $userProfileLink": {
        "& a": {
          color: theme.palette.primary.dark,
        },
      },
    },
  },

  cardPhotoHeaderAvatar: {
    margin: 0,
  },

  cardPhotoHeaderContent: {
    lineHeight: 1,
    transform: `translateY(-${theme.spacing(0.5)}px)`,
    whiteSpace: "nowrap",
  },

  cardPhotoInfoBox: {
    "& p": {
      marginTop: theme.spacing(2),
    },
  },

  cardPhotoContent: {
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    zIndex: 10,
  },

  // variant={cardUser} // TODO: OLD - update variant to use nested classes
  cardUser: {
    marginTop: "1.85rem !important",
    overflow: "visible",
    textAlign: "center",

    [theme.breakpoints.up("sm")]: {
      marginTop: "3.6rem !important",
    },
  },

  cardUserHeader: {
    ...theme.typography.caption,
    flexDirection: "column",

    "& > div": {
      marginRight: 0,
    },
  },

  cardUserAvatar: {
    width: 80,
    height: 80,
    marginTop: "-2.8rem",

    [theme.breakpoints.up("md")]: {
      width: 120,
      height: 120,
      marginTop: "-4.5rem",
    },
  },

  cardUserInfoBox: {
    "& p": {
      marginTop: theme.spacing(2),
    },
  },

  // variant references
  default: {},

  cardFullWidth: {},

  thinGutter: {},
});

export default styles;
