import PropTypes from "prop-types";

import { IconDelete } from "../icons";
import Input from "../Input";
import Checkbox from "../Checkbox";
import Button from "../Button";

const Controls = ({
  classes,
  onDelete,
  index,
  item,
  onMainPhotoChange,
  onEditCaption,
  showFields,
  disableEdit,
  // isVideo,
}) => {
  const { value, isHero, caption = "" } = item;
  const showMainPhoto = showFields.includes("mainPhoto");
  const showDelete = showFields.includes("delete");
  const showCaption = showFields.includes("caption");

  return (
    <nav className={classes.editMode}>
      <div className={classes.btnBox}>
        {showDelete && (
          <Button
            className={classes.button}
            type="button"
            onClick={() => onDelete(index)}
            disabled={disableEdit}
          >
            <IconDelete className={classes.icon} />
          </Button>
        )}
        {showMainPhoto && (
          <Checkbox
            classes={{
              root: `${classes.button} ${classes.checkbox}`,
              checked: classes.checkboxChecked,
              label: classes.checkboxLabel,
            }}
            key={index}
            label="Main Photo"
            name="check-main"
            value={String(value)}
            checked={isHero}
            disabled={disableEdit}
            onChange={() => {
              onMainPhotoChange(index);
            }}
            variant="check"
          />
        )}
      </div>
      {showCaption && (
        <Input
          classes={{
            root: classes.formControl,
            input: classes.input,
            inputElem: classes.inputElem,
            formControFooter: classes.formControFooter,
          }}
          helperText={`${caption.length} / ${250}`}
          inputProps={{ maxLength: 250 }}
          disabled={disableEdit}
          multiline
          placeholder="Say something about this photo..."
          onChange={(e) => onEditCaption(e, index)}
          type="text"
          value={caption}
          wrap="soft"
        />
      )}
    </nav>
  );
};

Controls.defaultProps = {
  classes: {},
  onDelete: () => {},
  onEditCaption: () => {},
  onMainPhotoChange: () => {},
  showFields: ["mainPhoto", "caption", "delete"],
};

Controls.propTypes = {
  classes: PropTypes.object,
  index: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired,
  onDelete: PropTypes.func,
  onEditCaption: PropTypes.func,
  onMainPhotoChange: PropTypes.func,
  showFields: PropTypes.arrayOf(PropTypes.string),
  loading: PropTypes.bool,
};

export default Controls;
