const Weather = () => (
  <>
    <path
      clipRule="evenodd"
      d="M9.781 16.7849C10.3818 16.7849 10.9477 16.9085 11.4423 17.157C11.8133 14.7117 13.9355 12.8334 16.48 12.8334C19.2555 12.8334 21.5177 15.0664 21.5888 17.8302C23.2502 18.0425 24.5405 19.46 24.5405 21.2147C24.5405 23.093 23.0203 24.6167 21.1467 24.6167H9.781C7.625 24.6167 5.875 22.862 5.875 20.7014C5.875 18.5384 7.625 16.7849 9.781 16.7849Z"
      stroke="#323232"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M12.7865 3.26667V2.33334"
      stroke="#323232"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M18.7332 5.72833L19.3866 5.06333"
      stroke="#323232"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M3.4526 11.6667H4.38594"
      stroke="#323232"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M6.19417 5.06333L6.8475 5.72833"
      stroke="#323232"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M16.0922 8.36733C15.2475 7.52267 14.0808 7 12.7917 7C10.2145 7 8.125 9.0895 8.125 11.6667"
      stroke="#323232"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </>
);

export default Weather;
