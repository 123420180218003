import { loadGoogleMaps } from "@/lib/maps";

// config
let token = null;
let isLoaded = false;
let autocompleteServiceInstance = null;

// debugging
let loadGoogleMapsCounter = 0;
let initializeCounter = 0;
let sessionTokenCounter = 0;
let placePredictionsCounter = 0;
let placePredictionsCallbackCounter = 0;

// create new autocomplete instance
const createAutoCompleteInstance = () => {
  if (!autocompleteServiceInstance && window?.google) {
    autocompleteServiceInstance =
      new window.google.maps.places.AutocompleteService();

    // eslint-disable-next-line no-console
    console.log("PLACES_API::initializeAutoCompleteService:: ", {
      timestamp: Date.now(),
      count: initializeCounter++,
      service: autocompleteServiceInstance,
    });
  }
};

// initialize autocomplete service through google api
export const initializeAutoCompleteService = () => {
  // initialize google api
  if (typeof window !== "undefined" && !isLoaded) {
    loadGoogleMaps(() => {
      // eslint-disable-next-line no-console
      console.log("PLACES_API::loadGoogleMaps:: ", {
        timestamp: Date.now(),
        count: loadGoogleMapsCounter++,
      });

      createAutoCompleteInstance();
    });
    isLoaded = true;
  }
};

// create a session per token
export const getSessionToken = () => {
  if (!token && window?.google?.maps) {
    token = new window.google.maps.places.AutocompleteSessionToken();

    // eslint-disable-next-line no-console
    console.log("PLACES_API::getSessionToken:: ", {
      timestamp: Date.now(),
      count: sessionTokenCounter++,
      token,
    });
  }

  return token;
};

// call auto complete service for places predictions
export const getPlacePredictions = ({ input, types, callback }) => {
  if (input && autocompleteServiceInstance) {
    autocompleteServiceInstance.getPlacePredictions(
      {
        input,
        sessionToken: getSessionToken(),
        types,
      },
      (params) => {
        // eslint-disable-next-line no-console
        console.log("PLACES_API::getPlacePredictions::callback:: ", {
          timestamp: Date.now(),
          count: placePredictionsCallbackCounter++,
          params,
        });

        callback(params);
      }
    );

    // eslint-disable-next-line no-console
    console.log("PLACES_API::getPlacePredictions:: ", {
      timestamp: Date.now(),
      count: placePredictionsCounter++,
      input,
      types,
    });
  }
};
