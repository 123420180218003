import { makeStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },

  disabled: {
    "& $inputIcon": {
      color: theme.palette.grey[250],
    },
  },

  option: {
    opacity: "1 !important",
  },

  optionPlaceholder: {
    "& $optionText, & $optionIcon": {
      opacity: 0.5,
    },
  },

  optionWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  optionLoader: {
    marginRight: theme.spacing(2),
    color: theme.palette.grey[300],
  },

  optionIcon: {
    marginRight: theme.spacing(2),
  },

  optionText: {
    "&:first-letter": {
      textTransform: "uppercase",
    },
  },

  optionPartsBold: {
    fontWeight: theme.typography.fontWeightBold,
  },

  inputRoot: {
    marginBottom: 0,
  },

  inputElem: {
    "&:first-letter": {
      textTransform: "uppercase",
    },
  },

  inputIcon: {},

  formControl: {
    marginBottom: 0,

    "& small": {
      marginTop: 0,
    },

    "& span": {
      minHeight: 0,
    },
  },

  formControFooter: {
    "& > small": {
      minHeight: 0,
    },
  },

  marginDense: {
    marginBottom: 0,
  },

  egText: {},

  popper: {
    marginTop: theme.spacing(-1),
  },
});

export default makeStyles(styles);
