import { useCallback, useMemo } from "react";

import { Link } from "@/ui";
import { IconArrowThin } from "@/icons";

import { megaNav } from "./constants";

import useStyles from "./styles";

const mainNavItems = {
  ...megaNav.locations.items,
  others: megaNav.categories,
};

const MobileMegaNav = ({ setRegionToShow, changeSlide }) => {
  const classes = useStyles();

  const handleNavItemChange = useCallback(
    (slideIndex, region) => (e) => {
      e.preventDefault();
      changeSlide(slideIndex);
      setRegionToShow(region);
    },
    [setRegionToShow, changeSlide]
  );

  const megaMenuItems = useMemo(
    () =>
      Object.keys(mainNavItems).map((key) => (
        <Link
          key={key}
          className={`${classes.link} ${classes.mobileArrowLink}`}
          href={mainNavItems[key].url}
          onClick={handleNavItemChange(2, key)}
        >
          <IconArrowThin viewBox="0 0 20 20" />
          {mainNavItems[key].title}
        </Link>
      )),
    [classes.link, classes.mobileArrowLink, handleNavItemChange]
  );

  return (
    <nav>
      <span
        onClick={handleNavItemChange(0)}
        className={`${classes.link} ${classes.backBtn}`}
      >
        <IconArrowThin variant="down" viewBox="0 0 20 20" />
        Back
      </span>

      {megaMenuItems}
    </nav>
  );
};

export default MobileMegaNav;
