const Edit = () => (
  <>
    <g>
      <path
        d="M189.37,25.079,170.916,6.612c-7.432-7.445-20.453-7.445-27.911,0L23.875,125.755,1.225,195.309l69.028-23.216,119.1-119.116a19.732,19.732,0,0,0,.013-27.9ZM25.808,162.36l8.484-26.03,25.438,25.452-25.82,8.681Zm16.586-36.514L114.646,53.6l27.767,27.753L70.148,153.613,42.395,125.847Zm81.55-81.564,9.589-9.589L161.3,62.448l-9.589,9.589L123.945,44.283Zm56.125-.605-9.47,9.47L142.833,25.395l9.47-9.484a6.73,6.73,0,0,1,9.3,0l18.454,18.467a6.569,6.569,0,0,1,.013,9.3Z"
        transform="translate(3.861 1.971)"
      />
    </g>
  </>
);

export default Edit;
