import { staticUrl } from "@/lib/utils";
import { Link, Image } from "@/ui";

import useStyles from "./styles";

const logo = staticUrl(require("@/static/img/logo-light.svg"));

const Brand = ({ shrink, classes: rootClasses }) => {
  const classes = useStyles({ classes: rootClasses });

  return (
    <div className={`${classes.root} ${shrink ? classes.shrink : ""}`}>
      <Link
        href="/"
        title="Atlas Guru - Itineraries by travelers for travelers"
        className={classes.link}
      >
        <Image
          className={classes.logo}
          alt="Atlas Guru"
          height={81}
          width={220}
          src={logo}
        />
      </Link>
    </div>
  );
};

export default Brand;
