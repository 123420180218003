const MyTrips = () => (
  <>
    <path
      d="M6 5.5V3.5C6 2.39543 6.89543 1.5 8 1.5H12C13.1046 1.5 14 2.39543 14 3.5V5.5M10 19.5H3C1.89543 19.5 1 18.6046 1 17.5V7.5C1 6.39543 1.89543 5.5 3 5.5H17C18.1046 5.5 19 6.39543 19 7.5V9.5M5 5.5V19.5M15 5.5V8.5M15.501 14.75C15.6389 14.7505 15.7504 14.8627 15.75 15.0006C15.7497 15.1386 15.6376 15.2502 15.4997 15.25C15.3617 15.2498 15.25 15.138 15.25 15C15.2496 14.9334 15.276 14.8695 15.3232 14.8225C15.3703 14.7755 15.4344 14.7494 15.501 14.75ZM12 15C12 13.067 13.567 11.5 15.5 11.5C17.433 11.5 19 13.067 19 15C19 16.6614 17.1435 18.4383 16.1339 19.2815C15.761 19.573 15.2375 19.5728 14.8647 19.2812C13.8561 18.4378 12 16.6612 12 15Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);

export default MyTrips;
