import Checkbox from "./Checkbox";

import { useController } from "react-hook-form";
import { forwardRef } from "react";

const CheckboxControlled = forwardRef(({ control, name, ...rest }, ref) => {
  const { field } = useController({
    name,
    control,
  });

  const { onChange, value, ...others } = field;

  return (
    <Checkbox
      {...rest}
      onChange={(e) => onChange(e.target.checked)}
      checked={value}
      {...others}
      ref={ref}
    />
  );
});

CheckboxControlled.displayName = "CheckboxControlled";

export default CheckboxControlled;
