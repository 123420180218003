const PinterestLight = () => (
  <>
    <g>
      <path d="M100,200A100,100,0,1,1,200,100,100.1,100.1,0,0,1,100,200ZM100,5a95,95,0,1,0,95,95A95.08,95.08,0,0,0,100,5Z" />
      <path d="M78.09,150.65a39.27,39.27,0,0,0,11.32-19.42c.76-2.88,3.85-14.67,3.85-14.67,2,3.85,7.9,7.09,14.1,7.09,18.58,0,32-17.08,32-38.39,0-20.39-16.62-35.63-38-35.63C74.73,49.63,60.63,67.51,60.63,87c0,9,4.81,20.3,12.5,23.88,1.15.57,1.79.3,2.06-.85.18-.88,1.24-5,1.72-7a1.87,1.87,0,0,0-.42-1.79c-2.54-3.09-4.6-8.78-4.6-14.06,0-13.6,10.29-26.78,27.81-26.78,15.13,0,25.73,10.3,25.73,25.09,0,16.69-8.42,28.27-19.37,28.27-6,0-10.59-5-9.11-11.15,1.75-7.34,5.11-15.25,5.11-20.52,0-4.72-2.51-8.66-7.81-8.66-6.2,0-11.13,6.39-11.13,15a22.64,22.64,0,0,0,1.81,9.15s-6.11,25.84-7.23,30.66c-1.24,5.33-.76,12.82-.21,17.69l.54,4.79Z" />
    </g>
  </>
);

export default PinterestLight;
