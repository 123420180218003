const styles = (theme) => ({
  root: {
    margin: "0 auto 3em",
    maxWidth: theme.breakpoints.values.lg,

    "&$fullWidth": {
      marginLeft: `-${theme.gutter}px`,
      marginRight: `-${theme.gutter}px`,
    },
  },

  fullWidth: {
    // width: "100vw",
    maxWidth: "100vw",
  },

  header: {
    marginBottom: "1.5em",
  },

  title: {},

  subtitle: {
    marginBottom: "1.5em",
    marginTop: "0.5em",
  },

  content: {},

  footer: {
    textAlign: "center",
  },

  button: {
    marginTop: "2em",
    minWidth: 155,
  },
});

export default styles;
