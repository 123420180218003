import { useEffect, useState, useRef } from "react";
import { connectSearchBox } from "react-instantsearch-dom";
import { SEARCH_DEBOUNCE_TIME } from "@/lib/constants";
import { IconClose, IconSearch } from "@/icons";
import { Input } from "@/ui";

import useStyles from "./styles";

const SearchInput = ({
  autoFocus,
  inputValue,
  onBlur,
  onChange,
  onFocus,
  onReset,
  onSearch,
  placeholder,
  refine,
  routerTerm,
  ...props
}) => {
  const debounceAlgoliaSearch = useRef();
  const searchIconToggleDebounce = useRef();
  const [filled, setFilled] = useState(false);
  const classes = useStyles({ classes: props.classes });
  const activeClass = inputValue ? "active" : "placeholder";
  const isSearchable = routerTerm && routerTerm === inputValue;

  const handleChange = ({ currentTarget }) => {
    onChange(currentTarget);

    clearTimeout(debounceAlgoliaSearch.current);

    debounceAlgoliaSearch.current = setTimeout(() => {
      refine(currentTarget.value);
    }, SEARCH_DEBOUNCE_TIME);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      onSearch();
    }
  };

  const renderIcon = () => (
    <div className={classes.icon}>
      {filled ? (
        <span onClick={onReset} role="presentation">
          <IconClose />
        </span>
      ) : (
        <span onClick={onSearch} role="presentation">
          <IconSearch viewBox="0 0 28 28" />
        </span>
      )}
    </div>
  );

  // This effect is set to deplay the toggle of close and search icons
  // those icons were not able to be clicked when
  // the inputValue state changed when input is blurred
  useEffect(() => {
    searchIconToggleDebounce.current = setTimeout(() => {
      setFilled(isSearchable);
    }, 100);

    return () => {
      clearTimeout(searchIconToggleDebounce.current);
    };
  }, [isSearchable]);

  return (
    <form
      className={classes.searchWrapper}
      noValidate
      action=""
      role="search"
      autoComplete="off"
    >
      <Input
        classes={{
          root: classes.searchInput,
          input: `${classes.inputWrap} ${classes[activeClass]}`,
          inputElem: classes.input,
          formControFooter: classes.formControFooter,
        }}
        autoFocus={autoFocus}
        endAdornment={renderIcon()}
        fullWidth
        margin="dense"
        name="Top Search"
        onChange={handleChange}
        onFocus={onFocus}
        onKeyDown={handleKeyDown}
        onBlur={onBlur}
        placeholder={placeholder}
        value={inputValue}
      />
    </form>
  );
};

SearchInput.defaultProps = {
  classes: {},
  inputValue: "",
  onBlur: () => {},
  onChange: () => {},
  onFocus: () => {},
  onKeyDown: () => {},
  onReset: () => {},
  onSearch: () => {},
  placeholder: "Search",
  refine: () => {},
  resetSearch: false,
  routerTerm: "",
};

export default connectSearchBox(SearchInput);
