const Add = () => (
  <>
    <path
      d="M11.2308 15.359H18.4103M14.8205 18.9487V11.7692M29 15C29 22.732 22.732 29 15 29C7.26801 29 1 22.732 1 15C1 7.26801 7.26801 1 15 1C22.732 1 29 7.26801 29 15Z"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);

export default Add;
