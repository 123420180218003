const Favorite = () => (
  <>
    <g transform="matrix(3.08575 0 0 3.07692 -.574 0)">
      <g
        data-name="Group 29858"
        transform="translate(17649.186 19410)"
        fill="none"
        strokeWidth="2"
      >
        <path
          data-name="Path 18594"
          d="M 0,0 H 28.027 V 32.5 L 14.014,20.313 0,32.5 Z"
          transform="translate(-17630.109 -19393.75)"
        />
        <g data-name="Ellipse 2950" transform="translate(-17649 -19410)">
          <circle cx="32.5" cy="32.5" r="32.5" stroke="none" />
          <circle cx="32.5" cy="32.5" r="32" />
        </g>
      </g>
    </g>
  </>
);

export default Favorite;
