const TwitterLight = () => (
  <>
    <g>
      <path d="M100,200A100,100,0,1,1,200,100,100.1,100.1,0,0,1,100,200ZM100,5a95,95,0,1,0,95,95A95.08,95.08,0,0,0,100,5Z" />
      <path d="M87,137.32c35.49,0,54.89-28.58,54.89-53.36,0-.81,0-1.62,0-2.43a38.3,38.3,0,0,0,9.62-9.72,38.92,38.92,0,0,1-11.07,3,18.92,18.92,0,0,0,8.48-10.38A39.53,39.53,0,0,1,136.66,69,19.53,19.53,0,0,0,122.57,63c-10.65,0-19.29,8.39-19.29,18.75a18.36,18.36,0,0,0,.5,4.28A55.28,55.28,0,0,1,64,66.45a18.28,18.28,0,0,0-2.62,9.43A18.62,18.62,0,0,0,70,91.49a19.45,19.45,0,0,1-8.74-2.35v.25a18.92,18.92,0,0,0,15.47,18.39,19.82,19.82,0,0,1-5.08.65,18.78,18.78,0,0,1-3.63-.34,19.24,19.24,0,0,0,18,13,39.43,39.43,0,0,1-24,8,38.92,38.92,0,0,1-4.6-.26A55.68,55.68,0,0,0,87,137.32Z" />
    </g>
  </>
);

export default TwitterLight;
