import { gql } from "@apollo/client";

export const ITINERARIES_REGION = gql`
  query ITINERARIES_REGION($regionName: String) {
    ItinerariesByRegion(regionName: $regionName) {
      featuredItineraries {
        itineraryId
        tripTitle
        slug
        numOfDays
        url
        tripPhotos {
          caption
          cloudId
          isHero
          photoUrl
        }
        locationsVisited {
          tripPhotos {
            caption
            cloudId
            isHero
            photoUrl
          }
          location {
            name
          }
        }
        countries {
          name
        }
        shortDescription
        regionName
        userProfile {
          screenName
          profilePicUrl
          cloudId
          userId
        }
      }
      recentItineraries {
        itineraryId
        tripTitle
        slug
        numOfDays
        url
        partner {
          url
          linkText
          headline
        }
        tripPhotos {
          caption
          cloudId
          photoUrl
          isHero
        }
        locationsVisited {
          location {
            name
          }
          tripPhotos {
            caption
            photoUrl
            cloudId
            isHero
          }
        }
        countries {
          name
        }
        shortDescription
        regionName
        userProfile {
          screenName
          profilePicUrl
          cloudId
          userId
        }
      }
    }
  }
`;

export const ITINERARIES_ONLY_FEATURED = gql`
  query ITINERARIES_ONLY_FEATURED($regionName: String) {
    ItinerariesFeaturedByRegion(regionName: $regionName) {
      featuredItineraries {
        itineraryId
        tripTitle
        slug
        numOfDays
        url
        tripPhotos {
          caption
          cloudId
          isHero
          photoUrl
        }
        locationsVisited {
          tripPhotos {
            caption
            cloudId
            isHero
            photoUrl
          }
          location {
            name
          }
        }
        countries {
          name
        }
        shortDescription
        regionName
        userProfile {
          screenName
          profilePicUrl
          cloudId
          userId
        }
      }
    }
  }
`;
