const styles = (theme) => ({
  root: {
    // CKEditor Custom Styles
    "& .ck": {
      // CK main
      "& .ck-editor": {
        borderRadius: "0 !important",

        "&__main > .ck-editor__editable:not(.ck-focused)": {
          borderColor: theme.palette.primary.main,
        },

        "&__editable:not(.ck-editor__nested-editable).ck-focused": {
          border: `1px solid ${theme.palette.common.black}`,
        },
      },

      // CK content
      "& .ck-content": {
        ...theme.typography.subtitle2,
        color: theme.palette.primary.dark,
        minHeight: "10em",
      },

      // CK toolbar
      "& .ck-toolbar": {
        borderColor: theme.palette.primary.main,
        justifyContent: "center",

        "&__separator": {
          "&:last-of-type": {
            marginLeft: "auto",
          },
        },
      },

      // CK Headings dropdown
      // .ck.ck-dropdown.ck-heading-dropdown .ck-dropdown__button .ck-button__label
      "& .ck-dropdown": {
        "&.ck-heading-dropdown .ck-dropdown": {
          "&__button .ck-button__label": {
            width: "6em",

            [theme.breakpoints.up("sm")]: {
              width: "8em",
            },
          },
        },
      },

      // CK Icons
      "& .ck-icon": {
        // color: theme.palette.primary.main,
        height: theme.spacing(1.8),
        width: theme.spacing(1.8),

        [theme.breakpoints.up("sm")]: {
          height: theme.spacing(3.2),
          width: theme.spacing(3.2),
        },
      },

      // CK Button
      "& .ck-button, & a.ck-button": {
        minHeight: theme.spacing(2),
        minWidth: theme.spacing(2),

        [theme.breakpoints.up("sm")]: {
          height: theme.spacing(3.5),
          width: theme.spacing(3.5),
        },
      },
    },
  },

  heading: {
    fontSize: "0.8em",
    marginBottom: "0.4em",
  },

  egText: {
    color: theme.palette.grey[750],
    lineHeight: "1.4",
    margin: "0.5em 0 0",
  },
});

export default styles;
