import parse from "autosuggest-highlight/parse";

import { Grid, Typography, Loading } from "@/ui";
import { IconPin } from "@/icons";

import useStyles from "./styles";

const Option = ({ option = {}, classes: rootClasses }) => {
  const classes = useStyles({ classes: rootClasses });
  const {
    main_text = "",
    secondary_text = "",
    main_text_matched_substrings: matches = [],
  } = option?.structured_formatting || {};
  const { loading, description } = option || {};
  const parts = parse(
    main_text || description,
    matches?.map((match) => [match.offset, match.offset + match.length])
  );

  return (
    <Grid
      container
      alignItems="center"
      className={loading ? classes.optionPlaceholder : ""}
    >
      <Grid item>
        <IconPin className={classes.optionIcon} viewBox="0 0 500 500" />
      </Grid>
      <Grid item xs>
        <div className={classes.optionWrapper}>
          <div className={classes.optionText}>
            <Typography>
              {parts.map((part, index) => (
                <span
                  key={index}
                  className={
                    part.highlight || loading ? classes.optionPartsBold : ""
                  }
                >
                  {part.text}
                </span>
              ))}
            </Typography>

            {secondary_text && (
              <Typography variant="body2" color="textSecondary">
                {secondary_text}
              </Typography>
            )}
          </div>

          {loading && <Loading className={classes.optionLoader} size={20} />}
        </div>
      </Grid>
    </Grid>
  );
};

export default Option;
