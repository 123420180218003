const Grid = () => (
  <>
    <g>
      <g transform="translate(-69 -571)">
        <path
          d="M0,175H200v25H0ZM0,87.5H200V150H0ZM87.5,0H200V62.5H87.5ZM0,0H62.5V62.5H0Z"
          transform="translate(69 571)"
        />
      </g>
    </g>
  </>
);

export default Grid;
