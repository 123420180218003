const ShareMobileLight = () => (
  <>
    <circle cx="17.5" cy="17.5" r="17" strokeWidth="1" fill="transparent" />
    <path d="M20.9006 11.2738L17.9444 8.35062L14.9882 11.2738L14.207 10.5014L17.9444 6.80579L21.6817 10.5014L20.9006 11.2738Z" />
    <path d="M17.3877 7.58252H18.501V19.1594H17.3877V7.58252Z" />
    <path d="M15.152 13.6462H12.3689C12.0321 13.6462 11.8123 13.8682 11.8123 14.1966V24.1223C11.8123 24.4553 12.0368 24.6727 12.3689 24.6727H23.5248C23.8616 24.6727 24.0815 24.4507 24.0815 24.1223V14.1966C24.0815 13.8636 23.8569 13.6462 23.5248 13.6462H20.3675V12.5454H23.5248C24.4744 12.5454 25.1994 13.2623 25.1994 14.2012V24.1269C25.1994 25.0658 24.4744 25.7827 23.5248 25.7827H12.3689C11.4194 25.7827 10.6943 25.0658 10.6943 24.1269V14.1966C10.6943 13.2577 11.4194 12.5408 12.3689 12.5408H15.152" />
  </>
);

export default ShareMobileLight;
