import { useInView } from "react-cool-inview";

const LazyInView = ({
  children = [],
  isActive = true,
  forceInView = false,
  className = "ag-lazyInView",
  ...settings
}) => {
  // observe overview component position
  const { observe, inView = true } = useInView(settings);

  return (
    <div ref={isActive ? observe : null} className={className}>
      {(inView || forceInView) && children}
    </div>
  );
};

export default LazyInView;
