import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "../Button";
import Grid from "../Grid";
import Link from "../Link";
import Typography from "../Typography";

import styles from "./styles";

const Section = ({
  classes,
  className,
  children,
  component,
  fullWidth,
  spacing,
  subtitle,
  headerAlign,
  title,
  viewMoreLink,
  viewMoreText,
  viewMoreVariant,
}) => {
  const Component = component || "section";
  return (
    <Component
      className={`${classes.root} ${className} ${
        fullWidth ? classes.fullWidth : ""
      }`}
    >
      {(title || subtitle) && (
        <header className={classes.header}>
          {title && (
            <Typography
              classes={{ root: classes.title }}
              component="h2"
              variant="subtitle1"
              align={headerAlign}
            >
              {title}
            </Typography>
          )}
          {subtitle && (
            <Typography
              classes={{ root: classes.subtitle }}
              component="h3"
              variant="subtitle2"
              align={headerAlign}
            >
              {subtitle}
            </Typography>
          )}
        </header>
      )}
      <Grid container className={classes.content} spacing={spacing}>
        {children}
        {viewMoreLink && (
          <Grid item xs={12} className={classes.footer} component="footer">
            <Link href={viewMoreLink}>
              <Button
                variant={viewMoreVariant}
                color="secondary"
                className={classes.button}
              >
                {viewMoreText}
              </Button>
            </Link>
          </Grid>
        )}
      </Grid>
    </Component>
  );
};

Section.defaultProps = {
  classes: {},
  className: "",
  fullWidth: false,
  spacing: 0,
  subtitle: "",
  headerAlign: "center",
  title: "",
  viewMoreLink: "",
  viewMoreText: "Explore More Itineraries",
  viewMoreVariant: "contained",
};

Section.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  fullWidth: PropTypes.bool,
  spacing: PropTypes.number,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  headerAlign: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  viewMoreLink: PropTypes.string,
  viewMoreText: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  viewMoreVariant: PropTypes.string,
};

export default withStyles(styles)(Section);
