const Tiktok = () => (
  <svg viewBox="0 0 99 99">
    <path d="M49.45 0C39.6701 1.99972e-07 30.1099 2.89994 21.9781 8.33313C13.8464 13.7663 7.50818 21.4887 3.76513 30.524C0.0220864 39.5592 -0.957769 49.5014 0.94946 59.0935C2.85669 68.6856 7.56539 77.4968 14.4801 84.4129C21.3948 91.329 30.205 96.0394 39.7967 97.9486C49.3884 99.8577 59.3308 98.8799 68.3668 95.1387C77.4028 91.3975 85.1265 85.0609 90.5613 76.9302C95.9962 68.7995 98.898 59.2399 98.9 49.46C98.9013 42.9653 97.6232 36.5339 95.1387 30.5332C92.6542 24.5325 89.012 19.08 84.42 14.4871C79.828 9.89417 74.3762 6.25083 68.376 3.76511C62.3758 1.27939 55.9447 -1.328e-07 49.45 0V0ZM49.45 94.4C40.5498 94.4 31.8496 91.7608 24.4494 86.8161C17.0492 81.8715 11.2814 74.8434 7.87548 66.6208C4.46954 58.3981 3.57836 49.3501 5.31469 40.6209C7.05103 31.8918 11.3369 23.8736 17.6302 17.5802C23.9236 11.2868 31.9418 7.00098 40.671 5.26465C49.4001 3.52831 58.4481 4.41947 66.6708 7.82541C74.8935 11.2314 81.9215 16.9991 86.8661 24.3993C91.8108 31.7995 94.45 40.4998 94.45 49.4C94.45 61.3347 89.709 72.7807 81.2698 81.2198C72.8307 89.659 61.3848 94.4 49.45 94.4V94.4Z" />
    <path d="M51.1101 26.28H59.5501C60.3801 33.5 64.5501 37.28 71.5501 38.28V46.58C67.2701 46.77 63.49 45.35 59.78 42.98C59.72 43.57 59.65 43.98 59.65 44.44C59.65 49.71 59.65 54.99 59.65 60.26C59.65 69.16 53.2301 75.66 43.9901 76.26C36.6101 76.74 29.2001 70.26 28.0401 62.39C27.7505 60.1247 27.9548 57.8234 28.639 55.6445C29.3232 53.4656 30.471 51.4607 32.0036 49.7675C33.5362 48.0743 35.4172 46.7331 37.5174 45.8359C39.6176 44.9387 41.8871 44.5068 44.1701 44.57C44.6901 44.57 45.1701 44.7 45.8001 44.78V53.19C45.3501 53.19 44.9 53.19 44.45 53.19C43.0078 53.0752 41.5645 53.3988 40.3093 54.1183C39.0542 54.8379 38.0456 55.9199 37.416 57.2225C36.7863 58.5251 36.5648 59.9876 36.7806 61.4182C36.9964 62.8488 37.6393 64.1809 38.6251 65.2398C39.611 66.2987 40.8938 67.0352 42.3054 67.3525C43.717 67.6699 45.1915 67.5534 46.5357 67.0183C47.8799 66.4832 49.0311 65.5545 49.8385 64.3539C50.6458 63.1533 51.0716 61.7368 51.0601 60.29C51.0601 49.74 51.0601 39.19 51.0601 28.64L51.1101 26.28Z" />
    <path d="M49.07 24.3201H57.51C58.33 31.5401 62.51 35.3201 69.51 36.3201V44.6101C65.22 44.8001 61.44 43.3801 57.73 41.0101C57.73 41.6101 57.61 42.0101 57.61 42.4701C57.61 47.7401 57.61 53.0201 57.61 58.2901C57.61 67.1901 51.19 73.6901 41.95 74.2901C34.56 74.7701 27.16 68.2901 25.95 60.4301C25.659 58.1648 25.8619 55.8634 26.5448 53.684C27.2277 51.5046 28.3744 49.4989 29.906 47.8047C31.4377 46.1106 33.3181 44.7682 35.4178 43.8698C37.5176 42.9713 39.787 42.5382 42.07 42.6001C42.59 42.6001 43.07 42.7301 43.7 42.8101V51.2201C43.25 51.2201 42.8 51.2201 42.36 51.2201C41.4035 51.1168 40.4359 51.2065 39.5147 51.4839C38.5934 51.7613 37.7372 52.2208 36.9968 52.8351C36.2564 53.4495 35.6467 54.2062 35.2041 55.0604C34.7614 55.9147 34.4947 56.849 34.4198 57.8082C34.3448 58.7674 34.4631 59.7319 34.7677 60.6445C35.0722 61.5572 35.5569 62.3994 36.1928 63.1213C36.8288 63.8433 37.6032 64.4302 38.4702 64.8474C39.3372 65.2646 40.279 65.5036 41.24 65.5501C42.2329 65.6312 43.2317 65.5035 44.1723 65.1755C45.1129 64.8474 45.9745 64.3261 46.7016 63.6452C47.4287 62.9643 48.0052 62.1387 48.3942 61.2216C48.7832 60.3045 48.9759 59.3161 48.96 58.3201C49.02 47.7701 48.96 37.2201 48.96 26.6701L49.07 24.3201Z" />
    <path d="M62.3601 33.64C59.9878 31.8367 58.3755 29.2114 57.8401 26.28H51.1101V28.65C51.1101 39.2 51.1101 49.75 51.1101 60.3C51.1247 61.2962 50.9306 62.2843 50.5405 63.2009C50.1503 64.1176 49.5726 64.9424 48.8445 65.6224C48.1165 66.3025 47.2542 66.8226 46.3131 67.1494C45.372 67.4762 44.3729 67.6024 43.3801 67.52C42.3019 67.4739 41.249 67.1792 40.3035 66.6588C39.3581 66.1383 38.5458 65.4063 37.9301 64.52C36.5545 63.6596 35.5103 62.3592 34.9673 60.8302C34.4243 59.3012 34.4145 57.6335 34.9394 56.0981C35.4643 54.5628 36.4931 53.2502 37.8585 52.3737C39.2239 51.4971 40.8456 51.1081 42.4601 51.27C42.9001 51.27 43.3501 51.27 43.8001 51.27V44.61C41.5477 44.6042 39.3201 45.0794 37.2662 46.0039C35.2123 46.9284 33.3794 48.2807 31.8903 49.9706C30.4011 51.6604 29.29 53.6488 28.6312 55.8027C27.9724 57.9565 27.7811 60.2263 28.0701 62.46C28.5535 65.6176 29.9431 68.5669 32.0701 70.95C34.8146 73.2878 38.3376 74.5085 41.9401 74.37C51.1801 73.76 57.5501 67.26 57.6001 58.37C57.6001 53.1 57.6001 47.82 57.6001 42.55C57.6001 42.12 57.6701 41.69 57.7201 41.09C61.4301 43.46 65.2101 44.88 69.5001 44.69V36.4C66.9271 36.0902 64.4725 35.1414 62.3601 33.64V33.64Z" />
  </svg>
);

export default Tiktok;
