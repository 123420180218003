// Dictionary of static pages

// TODO: update webpack to support expressions as path for imports
export const TOPICS = {
  // Travelers Favorite Hikes and Climbs in Europe
  "99027d9d-5f73-4e8f-a5f8-a1ba57652594": {
    slug: "travelers-favorite-self-guided-hiking-tours-in-europe",
    getData: () => import("./travelers-favorite-hiking-tours-europe"),
  },

  // 6 Best Cities for Street Art Around the World
  "6a7411c1-f395-4aba-8db6-058b01ec699a": {
    slug: "6-best-cities-for-street-art-around-the-world",
    getData: () => import("./best-cities-for-street-art"),
  },

  // 5 Favorite Destinations for Cheese and Wine Tasting
  "333697a5-ad20-4a79-b3bf-c132980eadb5": {
    slug: "5-favorite-destinations-for-cheese-and-wine-tasting",
    getData: () => import("./favorite-destinations-cheese-and-wine"),
  },

  // How to Eat Healthy While Traveling Aboard
  "0d5875f0-a6fb-445c-b18f-5bd6d6edef52": {
    slug: "how-to-eat-healthy-while-traveling-abroad",
    getData: () => import("./eat-healthy-while-traveling"),
  },

  // 14 Beautiful & Old Train Stations to See in the World
  "63226d07-5711-466d-872e-0fcd2afd763a": {
    slug: "14-beautiful-and-old-train-stations-to-see-in-the-world",
    getData: () => import("./beautiful-old-train-stations"),
  },
};
