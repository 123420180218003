const X = () => (
  <g>
    <circle cx="100" cy="100" r="97.5" fill="#729B56" />
    <path
      d="M14.3076 10.4686L21.3808 2H19.7046L13.563 9.3532L8.65769 2H3L10.4178 13.1193L3 22H4.67621L11.1619 14.2348L16.3423 22H22L14.3076 10.4686ZM12.0118 13.2173L11.2602 12.1101L5.28017 3.29968H7.85474L12.6807 10.4099L13.4323 11.5172L19.7054 20.7594H17.1309L12.0118 13.2173Z"
      fill="#FFF"
      transform="translate(36, 36) scale(5)"
    />
  </g>
);

export default X;
