import { staticUrl } from "../../../lib/utils";

const bgPalmDark = staticUrl(require("@/static/img/theme-bg-palm-dark.svg"));

// TODO: use transfor hex to rgba here
// const bgWithOpacity = "rgba(240, 240, 236, 0.96)";

const styles = (theme) => ({
  root: {
    // base containers
    "& .container": {
      flex: 1,
      margin: "0 auto",
      maxWidth: theme.breakpoints.values.lg,
    },

    // fullScreen
    "&$fullScreen": {
      "& $paper": {
        "&:after": {
          height: "50vw",
          width: "50vw",

          [theme.breakpoints.up("sm")]: {
            height: "30vw",
            width: "30vw",
          },
        },
      },
    },

    // variant={default}
    "&$default": {
      "& $paper": {
        // backgroundColor: bgWithOpacity,
        background: theme.palette.grey[100],
        border: `1px solid ${theme.palette.primary.main}`,
        boxShadow: "0 2px 8px 0px rgba(0,0,0,0.15)",
        margin: 0,

        "&:after, &:before": {
          content: "none",
        },
      },

      "& $row": {
        padding: `${theme.gutter * 2.6}px ${theme.gutter}px`,

        [theme.breakpoints.up("sm")]: {
          padding: `${theme.gutter * 2.6}px ${theme.gutter * 1.5}px`,
        },
      },

      "& $closeBtn": {
        color: theme.palette.secondary.dark,
      },
    },

    // variant={simple}
    "&$simple": {
      "& $paper": {
        // backgroundColor: bgWithOpacity,
        background: theme.palette.grey[100],
        boxShadow: "0 4px 12px 0px rgba(0,0,0,0.2)",
      },

      "& $innerContainer": {
        position: "relative",
        overflow: "hidden",

        "&:before": {
          [theme.breakpoints.up("sm")]: {
            ...theme.backgrounds.palmLeaf,
            height: "40vw",
            right: theme.gutter * -2,
            bottom: theme.gutter * -2,
            width: "40vw",
          },
        },
      },

      "& $row": {
        padding: `3em ${theme.gutter}px`,
      },
    },

    // variant={decorated}
    "&$decorated": {
      "& $paper": {
        backgroundPosition: "50% 100%",
        backgroundAttachment: "local",
        backgroundImage: `url(${bgPalmDark})`,
        backgroundSize: "85px",
        backgroundRepeat: "no-repeat",
      },

      "& $row": {
        marginBottom: 100,
        padding: `4.5em ${theme.gutter}px ${theme.gutter}px`,
        position: "relative",
      },
    },

    // variant={rounded}
    "&$rounded": {
      "& $paper": {
        boxShadow: "none",
        borderRadius: theme.spacing(2),
      },

      "& $row": {
        padding: `${theme.gutter * 2.6}px ${theme.gutter}px`,

        [theme.breakpoints.up("sm")]: {
          padding: `${theme.gutter * 2.6}px ${theme.gutter * 1.5}px`,
        },
      },
    },
  },

  // child elements
  paper: {
    // backgroundColor: theme.palette.grey[100],
    // border: `solid ${theme.palette.primary.main}`,
    // borderWidth: "17px 0 10px",
    width: "100%",

    // "&:after": {
    //   ...theme.backgrounds.palmLeaf,
    //   height: 230,
    //   left: 0,
    //   top: 0,
    //   transform: "rotate(10deg) scaleX(-1)",
    //   width: 230,
    // },
  },

  paperScrollPaper: {},

  mediaModalPaper: {},

  modalContainer: {
    position: "relative",
    zIndex: 1,
    height: "100%",
  },

  innerContainer: {},

  closeBtn: {
    color: theme.palette.primary.dark,
    position: "absolute",
    right: 0,
    top: 0,
    zIndex: 20,

    "&:hover svg > g": {
      color: theme.palette.primary.main,

      "& circle": {
        fill: theme.palette.common.highlight,
      },
    },

    "& svg": {
      color: theme.palette.secondary.dark,

      "& > g": {
        strokeWidth: 4,
      },

      "& circle": {
        fill: theme.palette.common.white,
      },
    },
  },

  content: {
    position: "relative",
    zIndex: 10,
    height: "100%",
  },

  row: {},

  header: {
    paddingTop: 0,
  },

  title: {
    fontSize: theme.atlasGuru.fontSizes.h2.xs,
    textAlign: "center",
    textTransform: "Capitalize",
  },

  // variant={default|simple|decorated}
  default: {},
  simple: {},
  decorated: {},
  rounded: {},

  // fullScreen={true}
  fullScreen: {},
});

export default styles;
