import { googleMapsApiKey, awsOrLocal } from "@/config";

import { staticUrl } from "../utils";
import { GMAP_MIN_ZOOM } from "../constants";
import staticMapStyles from "./staticStyles";

// determine if running on AWS
const runningInAws = awsOrLocal === "aws";

// static maps url
const mapsUrl = `https://maps.googleapis.com/maps/api/staticmap?key=${googleMapsApiKey}`;

const markerIcon = runningInAws
  ? staticUrl(require("@/static/img/map_marker_black_small.png"))
  : "https://site-assets-cdn-site-build-dev.atlasguru.io/static/img/map_marker_black_small.19ef76a152ec708d0e0873cbc700be21.png";

// draws a static map with markers and paths using the given locations/coordinates
export function createStaticMap(coords, { width, height, zoom }) {
  const style = staticMapStyles;
  const size = `size=${width}x${height}`;

  // when no coords are defined it returns the entire world
  if (!coords[0]) return `${mapsUrl}&zoom=1&${size}`;

  // markers
  const markers = coords
    .map((coord) => {
      const { lat = "", lng = "" } = coord || {};
      return `${[lat, lng].join(",")}`;
    })
    .join("|");

  // paths
  const pathLocations = coords
    .map((coord) => {
      const { lat = "", lng = "" } = coord || {};
      return `${[lat, lng].join(",")}`;
    })
    .join("|");

  const path = `path=color:0x000000FF|weight:1|${pathLocations}`;
  const markerPath = encodeURIComponent(markerIcon);
  const marker = `markers=anchor:center|icon:${markerPath}|${markers}`;
  const defaultZoom = coords.length < 2 ? GMAP_MIN_ZOOM + 2 : "";
  const mapZoom = `&zoom=${zoom || defaultZoom}`;

  return [mapsUrl, mapZoom, size, marker, path, style].join("&");
}
