import { useState, useEffect, useRef } from "react";

import {
  DEFAULT_TRANSFORMATIONS,
  CUSTOM_CROP_TRANSFORMATIONS,
} from "@/lib/constants";
import { buildUrl, checkIsVideoCloudId } from "@/lib/useCloudinary";

import Loading from "../Loading";
import Image from "../Image";
import Video from "../Video";

export const imageTransformations = {
  ...DEFAULT_TRANSFORMATIONS,
  height: 720,
  width: 960,
};

export const videoTransformations = {
  height: 720,
  width: 960,
};

const SlideMedia = ({
  index = "",
  classes = {},
  imgProps = {},
  setBrokenImageIndex = () => {},
  currSlide = 0,
}) => {
  const { alt, caption, cloudId, src } = imgProps;
  const [isLoading, setLoadingState] = useState(true);
  const videoRef = useRef(null);

  const imgCaption = alt || caption;
  const isCaptionValid = typeof imgCaption === "string";
  const altText = isCaptionValid ? imgCaption : "AtlasGuru Itinerary Photo";
  const isVideo = checkIsVideoCloudId(cloudId);
  const transformations = isVideo ? videoTransformations : imageTransformations;
  const imageSrc =
    src || buildUrl(cloudId, CUSTOM_CROP_TRANSFORMATIONS, transformations);

  const handleError = () => {
    setBrokenImageIndex((prev) => `${prev}:${index}`);
    setLoadingState(false);
  };

  const handleLoadComplete = () => {
    setLoadingState(false);
  };

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.pause();
    }
  }, [currSlide]);

  return (
    <div className={classes.imageWrapper}>
      {isVideo && (
        <div className={classes.videoContainer}>
          <Video
            theme="blue"
            ref={videoRef}
            cloudId={cloudId}
            transformations={{
              quality: "auto:best",
              crop: "limit",
              width: 1280,
              height: 720,
            }}
            classes={{
              root: classes.videoBox,
              video: classes.videoElem,
            }}
          />
        </div>
      )}

      {!isVideo && (
        <>
          {isLoading && <Loading className={classes.loading} size={50} />}

          <div
            className={classes.imageContainer}
            style={{ backgroundImage: `url(${imageSrc})` }}
          >
            <Image
              alt={altText}
              className={isLoading ? classes.imgPlaceholder : ""}
              onLoad={handleLoadComplete}
              onError={handleError}
              src={imageSrc}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default SlideMedia;
