const CloseV2 = () => (
  <>
    <g transform="matrix(3 0 0 3 3 3)" strokeWidth="3">
      <g data-name="Group 29862" transform="translate(-.314 -.389)">
        <g data-name="Group 29787" transform="translate(.315 .389)" fill="none">
          <line
            data-name="Line 1401"
            x2="28.116"
            y2="28.116"
            transform="translate(18.442 18.439)"
          />
          <line
            data-name="Line 1402"
            x1="28.116"
            y2="28.116"
            transform="translate(18.442 18.439)"
          />
        </g>
      </g>
    </g>
  </>
);

export default CloseV2;
