import { gql } from "@apollo/client";
import { USER_FRAGMENT } from "./user";

export const PROFILE_QUERY = gql`
  query PROFILE_QUERY($userId: ID!) {
    User(userId: $userId) {
      ...UserData
    }
    Itineraries(userId: $userId) {
      itineraryId
      published
      regionName
      slug
      tripTitle
      tripPhotos {
        isHero
        cloudId
        photoUrl
        caption
      }
      locationsVisited {
        tripPhotos {
          isHero
          cloudId
          photoUrl
          caption
        }
      }
    }
  }
  ${USER_FRAGMENT}
`;
