const Circle = () => (
  <>
    <g>
      <g transform="translate(-0.314 -0.389)">
        <g transform="translate(0.315 0.389)">
          <path
            d="M86.512,86.512"
            transform="translate(56.746 56.737)"
            fill="none"
            strokeWidth="6"
          />
          <g transform="translate(0 0)" fill="none" strokeWidth="6">
            <circle cx="100" cy="100" r="100" stroke="none" />
            <circle cx="100" cy="100" r="97.5" fill="none" />
          </g>
        </g>
      </g>
    </g>
  </>
);

export default Circle;
