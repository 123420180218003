import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Tabs as MuiTabs, Tab } from "@material-ui/core";
import { createSvgIcon } from "./icons";

const styles = (theme) => ({
  root: {
    "&$default, &$scrollable": {
      borderBottom: `1px solid ${theme.palette.grey[200]}`,

      "& $tab": {
        ...theme.typography.button,
        color: theme.palette.primary.main,
        margin: "0 1em 0 0",
        minWidth: 0,
        opacity: 1,
        padding: 0,

        [theme.breakpoints.up("sm")]: {
          margin: "0 2.5em 0 0",
        },

        [theme.breakpoints.up("md")]: {
          margin: "0 5em 0 0",
        },
      },

      "& $tabWrapper": {
        display: "flex",
        flexDirection: "row",
        padding: "1.8em 0.6em",
      },

      "& $indicator": {
        height: 7,
      },

      "& $icon": {
        margin: `0 ${theme.spacing(1)}px 0 0`,
      },
    },
  },

  // variant="default"
  default: {},

  // variant="scrollable"
  scrollable: {},

  // children
  tab: {},
  tabWrapper: {},
  tabSelected: {},
  indicator: {},
  icon: {},
});

const Tabs = ({ active, classes, tabs, onChange, variant, ...props }) => (
  <MuiTabs
    classes={{
      root: `${classes.root} ${classes[variant]}`,
      indicator: classes.indicator,
    }}
    onChange={onChange}
    variant={variant === "default" ? null : variant}
    value={active}
    {...props}
  >
    {tabs.map(({ label, icon, ...tabProps }, i) => {
      const Icon = createSvgIcon(icon);

      return (
        <Tab
          key={i}
          label={label}
          classes={{
            root: classes.tab,
            wrapper: classes.tabWrapper,
            selected: classes.tabSelected,
          }}
          icon={icon && <Icon className={classes.icon} />}
          {...tabProps}
        />
      );
    })}
  </MuiTabs>
);

Tabs.defaultProps = {
  active: 0,
  classes: {},
  onChange: () => {},
  variant: "default",
};

Tabs.propTypes = {
  active: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  classes: PropTypes.object,
  onChange: PropTypes.func,
  tabs: PropTypes.array.isRequired,
  variant: PropTypes.string,
};

export default withStyles(styles)(Tabs);
