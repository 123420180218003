const Upload = () => (
  <>
    <g>
      <path
        data-name="Path 18588"
        d="M87.432,64.446,76.356,48.879a2.347,2.347,0,0,0-3.592-.3l-6.287,6.137a2.347,2.347,0,0,1-3.592-.3L47.317,33.612a2.38,2.38,0,0,0-3.892.15l-25.3,40.265a2.351,2.351,0,0,0,2.1,3.592H78A36.113,36.113,0,0,1,87.432,64.446Z"
        transform="translate(5.072 0.516)"
        fill="#669d4c"
      />
      <circle
        data-name="Ellipse 2947"
        cx="8.831"
        cy="8.831"
        r="8.831"
        transform="translate(76.189 21.105)"
        fill="#669d4c"
      />
      <path
        data-name="Path 18589"
        d="M125.39,75.612V14.392A3.963,3.963,0,0,0,121.5,10.5H6.392A3.865,3.865,0,0,0,2.5,14.392V105.25a3.865,3.865,0,0,0,3.892,3.892h85.32A27.041,27.041,0,1,0,125.39,75.612ZM10.134,18.284H117.607V74.565h0a27.086,27.086,0,0,0-27.093,27.093H10.134ZM117.607,120.966a19.459,19.459,0,1,1,19.459-19.459A19.516,19.516,0,0,1,117.607,120.966Z"
        transform="translate(-2.5 -10.5)"
        fill="#669d4c"
      />
      <path
        data-name="Path 18590"
        d="M92.255,71.682h-4.79v-4.79A3.865,3.865,0,0,0,83.573,63a3.775,3.775,0,0,0-3.892,3.892v4.79h-4.79A3.865,3.865,0,0,0,71,75.573a3.775,3.775,0,0,0,3.892,3.892h4.79v4.79a3.865,3.865,0,0,0,3.892,3.892,3.775,3.775,0,0,0,3.892-3.892v-4.79h4.79a3.892,3.892,0,1,0,0-7.784Z"
        transform="translate(31.533 15.584)"
        fill="#669d4c"
      />
    </g>
  </>
);

export default Upload;
