import {
  IconRatingStarEmpty,
  IconRatingStarFull,
  IconRatingStarHalf,
} from "./icons";

const Rating = ({ ratingNumber, size = "xs", className }) => {
  const fullStars = Math.floor(ratingNumber);
  const hasHalf = ratingNumber % 1 !== 0;
  const emptyStars = 5 - fullStars - (hasHalf ? 1 : 0);

  const result = [];

  // Add full stars
  for (let i = 0; i < fullStars; i++) {
    result.push("full");
  }

  // Add half star if present
  if (hasHalf) {
    result.push("half");
  }

  // Add empty stars
  for (let i = 0; i < emptyStars; i++) {
    result.push("empty");
  }

  return (
    <span className={className}>
      {result.map((starType, index) =>
        ({
          full: () => (
            <IconRatingStarFull
              key={`${index}-${starType}`}
              size={size}
              viewBox="0 0 16 16"
            />
          ),
          half: () => (
            <IconRatingStarHalf
              key={`${index}-${starType}`}
              size={size}
              viewBox="0 0 16 16"
            />
          ),
          empty: () => (
            <IconRatingStarEmpty
              key={`${index}-${starType}`}
              size={size}
              viewBox="0 0 16 16"
            />
          ),
        }[starType]())
      )}
    </span>
  );
};

export default Rating;
