import { useController } from "react-hook-form";
import { forwardRef, memo } from "react";
import Input from "./Input";

const InputControlled = forwardRef(
  ({ control, name, rules = {}, ...rest }, ref) => {
    const { field } = useController({
      name,
      control,
      rules,
    });

    return <Input required={!!rules.required} {...rest} {...field} ref={ref} />;
  }
);

InputControlled.displayName = "InputControlled";

export default memo(InputControlled);
