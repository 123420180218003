import { Picture, Typography } from "@/ui";

import useStyles from "./styles";

const Hero = ({
  alt = "",
  covers = [],
  headline = "",
  loading = "lazy",
  fetchpriority = "low",
  classes: rootClasses,
}) => {
  const classes = useStyles({ classes: rootClasses });

  return (
    <div className={classes.hero}>
      {headline && (
        <Typography variant="h1" className={classes.headline}>
          {headline}
        </Typography>
      )}

      <Picture
        alt={alt}
        sizes={covers}
        className={classes.picture}
        transformations={{ quality: 80 }}
        fetchPriority={fetchpriority}
        loading={loading}
      />
    </div>
  );
};

export default Hero;
