import { useState, useLayoutEffect } from "react";

function getBrowserFullscreenElementProp() {
  let element;

  if (typeof document.fullscreenElement !== "undefined") {
    element = "fullscreenElement";
  } else if (typeof document.mozFullScreenElement !== "undefined") {
    element = "mozFullScreenElement";
  } else if (typeof document.msFullscreenElement !== "undefined") {
    element = "msFullscreenElement";
  } else if (typeof document.webkitFullscreenElement !== "undefined") {
    element = "webkitFullscreenElement";
  } else {
    throw new Error("fullscreenElement is not supported by this browser");
  }

  return element;
}

export default function useFullscreenStatus(elRef) {
  const [isFullscreen, setIsFullscreen] = useState(
    document[getBrowserFullscreenElementProp()] != null
  );

  const setFullscreen = () => {
    if (elRef.current == null) return;

    elRef.current
      .requestFullscreen()
      .then(() => {
        setIsFullscreen(document[getBrowserFullscreenElementProp()] != null);
      })
      .catch(() => {
        setIsFullscreen(false);
      });
  };

  useLayoutEffect(() => {
    document.onfullscreenchange = () =>
      setIsFullscreen(document[getBrowserFullscreenElementProp()] != null);

    // eslint-disable-next-line no-return-assign
    return () => (document.onfullscreenchange = undefined);
  });

  return [isFullscreen, setFullscreen];
}
