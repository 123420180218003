import { makeStyles } from "@material-ui/core/styles";
import { Button as MuiButton } from "@material-ui/core";
import Link from "./Link";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 0,
  },

  // rounded
  roundedStyle: {
    borderRadius: theme.spacing(),
  },

  // error
  errorStyle: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,

    "&:hover": {
      backgroundColor: theme.palette.error.dark,
    },
  },

  // size=large
  sizeLarge: {
    ...theme.typography.button,
    padding: "1.1em 3em",
  },

  // variant=contained
  contained: {
    boxShadow: "none",

    "& .icon circle": {
      fill: theme.palette.secondary.main,
    },

    "&:hover .icon circle": {
      fill: theme.palette.common.highlight,
    },
  },

  containedPrimary: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.highlight,
  },

  containedSecondary: {
    "&:hover": {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.common.highlight,
    },
  },

  // type=outlined
  outlinedPrimary: {
    color: theme.palette.common.highlight,
    borderColor: theme.palette.common.highlight,

    "&:hover": {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.common.highlight,
      borderColor: theme.palette.common.highlight,
    },
  },

  outlinedSecondary: {
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,

      "& .icon > svg": {
        color: `${theme.palette.common.white} !important`,
      },
    },
  },

  // children
  label: {
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: 1,
    lineHeight: 1,
    padding: `0.5em 0`,
  },

  text: {
    textDecoration: "underline",
  },

  textPrimary: {
    color: theme.palette.common.highlight,
  },
}));

const Button = ({
  classes: rootClasses = {},
  error = false,
  href = "",
  rounded = false,
  type = "button",
  color,
  ...props
}) => {
  const { roundedStyle, errorStyle, ...classes } = useStyles({
    classes: rootClasses,
  });

  return (
    <MuiButton
      classes={{
        ...classes,
        root: `${classes.root} ${rounded ? roundedStyle : ""} ${
          error ? errorStyle : ""
        }`,
      }}
      component={href ? Link : "button"}
      color={color}
      href={href}
      type={type}
      {...props}
    />
  );
};

export default Button;
