const Facebook = () => (
  <>
    <g>
      <path
        d="M100,0C61.912,0,28.776,21.329,10.916,54.545,4.305,66.775,0,82.888,0,100A100,100,0,1,0,100,0Zm14,73.8c7,0,14.4-.2,21.4.2-.2,7.4,0,15.2-.2,22.6H114v70H84v-70c-6.2-.2-12.8,0-19.2-.2V73.6h19c.2-6,0-11.4.4-16.2a26.078,26.078,0,0,1,4.2-12.2,26.891,26.891,0,0,1,21.4-12c8-.4,17,.2,25.6.2,0,7.6.2,15.4-.2,22.8-4.2.2-8.6-.4-12.2,0a9.307,9.307,0,0,0-7.8,5.4C113.4,65,114,69.2,114,73.8Z"
        transform="translate(-0.5 0.5)"
      />
    </g>
  </>
);

export default Facebook;
