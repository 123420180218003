const styles = (theme) => ({
  root: {
    // color="primary"
    "&$primary": {
      "& > a": {
        color: theme.palette.primary.main,
      },

      "& .icon > svg": {
        color: theme.palette.primary.main,
      },
    },

    // color="secondary"
    "&$secondary": {
      "& > a": {
        color: theme.palette.secondary.main,
      },

      "& .icon > svg": {
        color: theme.palette.secondary.main,
      },
    },

    // color="contrast"
    "&$contrast": {
      "& > a": {
        color: theme.palette.common.white,
      },

      "& .icon > svg": {
        color: theme.palette.common.white,
      },
    },

    // variant="keywords"
    "&$keywords": {
      "& > a": {
        borderRight: "5px solid transparent",
        display: "inline",
        textDecoration: "underline",

        "&:after": {
          content: "','",
        },

        "&:last-child:after": {
          content: "'.'",
        },
      },
    },
  },

  // {children}
  itemIcon: {},

  link: {
    position: "relative",
  },

  activeLink: {},

  text: {},

  tag: {
    fontSize: theme.atlasGuru.fontSizes.caption.xs,
    textTransform: "uppercase",
    margin: "0 0 0 0.4em",
    lineHeight: 1,
    minHeight: 0,

    "& span": {
      padding: "0.1em 0.8em",
    },
  },

  // color={primary|secondary|contrast}
  primary: {},
  secondary: {},
  contrast: {},

  // variant={default|keywords}
  default: {},
  keywords: {},
});

export default styles;
