import { removeEmpty } from "@/lib/utils";
import { useCallback, useEffect, useState } from "react";
import { USER_QUERY, USER_QUERY_NO_CACHE, USER_MUTATION } from "./gql";
import { initializeApollo } from "@/lib/apolloClient";

const fetchUser = async ({ client, options = {}, cached = true }) => {
  const response = await client.query({
    query: cached ? USER_QUERY : USER_QUERY_NO_CACHE,
    ...options,
  });
  return removeEmpty(response?.data?.User);
};

const upsertUser = async ({ client, options = {} }) => {
  const response = await client.mutate({
    mutation: USER_MUTATION,
    ...options,
  });
  return removeEmpty(response?.data?.upsertUser);
};

// TODO: clean, use apollo hooks
export const useUser = ({
  userId,
  screenName,
  email,
  cached = true,
  wait = false,
}) => {
  const [user, setUser] = useState(undefined);
  const [currUserId, setCurrUserId] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const client = initializeApollo();

  const fetch = useCallback(
    async (options = {}) => {
      setError("");
      setLoading(true);
      const { userId, screenName, email } = options.variables ?? {};
      if (!userId && !screenName && !email) {
        setLoading(false);
        return;
      }
      if (!cached) {
        options.fetchPolicy = "network-only";
        options.nextFetchPolicy = "cache-first";
      }
      try {
        const userData = await fetchUser({ client, options, cached });
        setUser(userData);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    },
    [cached, client]
  );

  // save/update
  const save = useCallback(
    async (input, options = {}) => {
      try {
        setError("");
        setLoading(true);
        options.variables = {
          input: { ...input, userId: currUserId },
        };
        const userData = await upsertUser({ client, options });
        setUser(userData);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    },
    [client, currUserId]
  );

  useEffect(() => {
    if (!userId && !screenName && !email) {
      if (!wait) {
        setLoading(false);
      }
      return;
    }

    setCurrUserId(userId);

    fetch({
      variables: {
        screenName,
        userId,
        email,
      },
    });
  }, [fetch, screenName, userId, email, wait]);

  return { data: user, loading, error, save, fetch };
};
