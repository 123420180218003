import { gql } from "@apollo/client";

// QUERY

export const CONTRIBUTORS_QUERY_NO_CACHE = gql`
  query CONTRIBUTORS_QUERY_NO_CACHE(
    $sortBy: String
    $sortDirection: String
    $offset: Int
    $max: Int
  ) {
    GetContributors(
      sortBy: $sortBy
      sortDirection: $sortDirection
      offset: $offset
      max: $max
    ) {
      contributors {
        userId
        screenName
        bio
        profilePicUrl
        displayOrder
        countries
      }
      paginationInfo {
        currentPage
        totalPages
        totalItems
      }
    }
  }
`;

// MUTATIONS

export const UPSERT_CONTRIBUTORS = gql`
  mutation($input: [ContributorInput]) {
    UpsertContributors(input: $input) {
      contributors {
        userId
        screenName
        displayOrder
      }
      paginationInfo {
        currentPage
        totalPages
        totalItems
      }
    }
  }
`;

export const REMOVE_CONTRIBUTOR = gql`
  mutation($screenName: String!) {
    RemoveContributor(screenName: $screenName) {
      contributors {
        userId
        screenName
        displayOrder
      }
      paginationInfo {
        currentPage
        totalPages
        totalItems
      }
    }
  }
`;
