import useViewport from "@/lib/useViewport";
import { Nav } from "@/ui";

import { headerNav } from "./constants";
import MobileNav from "./MobileNav";

import useStyles from "./styles";

const Navigation = ({ shrink, onClickNavItem, openMegaMenu }) => {
  const classes = useStyles();
  const { isTabletUp } = useViewport();

  return (
    <div className={`${classes.root} ${shrink ? classes.shrink : ""}`}>
      {!isTabletUp && <MobileNav />}

      <Nav
        classes={{
          root: classes.navigation,
          itemIcon: classes.itemIcon,
          activeLink: classes.activeMegaMenu,
          link: classes.link,
        }}
        navItems={headerNav}
        onClick={onClickNavItem}
        activeItem={openMegaMenu}
        color="contrast"
      />
    </div>
  );
};

export default Navigation;
