const styles = (theme) => ({
  root: {
    display: "flex",
    flexBasis: "100%",
    // flexWrap: "wrap",
    justifyContent: "center",
    flexFlow: "column",
    // maxWidth: "100%",

    [theme.breakpoints.up("sm")]: {
      flexFlow: "row",
      margin: `0 -${theme.gutter / 2}px`,
    },
  },

  column: {
    flex: "0 0 100%", // one columns

    [theme.breakpoints.up("sm")]: {
      flex: "0 0 50%", // three columns
      padding: `0 ${theme.gutter / 2}px`,
      // paddingLeft: theme.gutter,

      // "&:first-child": {
      //   paddingLeft: 0
      // }
    },

    [theme.breakpoints.up("md")]: {
      flex: "0 0 33.3%", // three columns
    },
  },

  tile: {
    marginBottom: theme.gutter,
  },
});

export default styles;
