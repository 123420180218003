const Arrow = () => (
  <>
    <g>
      <path
        d="M100,129.795,0,29.789,29.787,0,100,70.217,170.213,0,200,29.789Z"
        transform="translate(200.57 169.566) rotate(180)"
      />
    </g>
  </>
);

export default Arrow;
