const styles = (theme) => ({
  root: {
    // variant={description}
    "&$description": {
      textAlign: "center",

      "& button": {
        display: "block",
        margin: "0 auto",
      },

      "&, & $heading, & $content": {
        // fontSize: theme.atlasGuru.fontSizes.h6.xs
      },
    },

    // variant primary color
    "&$primary": {
      "& $legend": {
        color: theme.palette.primary.main,
      },
    },

    // variant secondary color
    "&$secondary": {
      "& $legend": {
        color: theme.palette.secondary.main,
      },
    },
  },

  legend: {
    color: "inherit",
    cursor: "pointer",
    display: "inline",
    padding: `0 ${theme.spacing(0.5)}px`,
    textDecoration: "underline",
    whiteSpace: "nowrap",
  },

  ellipsis: {
    display: "inline",

    "&$visible": {
      display: "none",
    },
  },

  heading: {
    display: "inline",
  },

  content: {
    display: "none",

    "&$visible": {
      display: "inline",
    },
  },

  visible: {},

  // variants reference
  description: {},
  primary: {},
  secondary: {},
});

export default styles;
