import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import ContentLoader from "react-content-loader";

const styles = () => ({
  root: {
    width: "100%",
    display: "flex",
    // marginBottom: theme.gutter / 2,

    "& rect": {
      width: "100%",
    },
  },
});

function Placeholder({
  borderRadius,
  classes,
  gutterBottom,
  rowSize,
  mediaPos,
  mediaSize,
  rows,
  size,
  viewBox,
  width,
  ...rest
}) {
  const isMediaLeft = mediaPos === "left";
  const rowsHeight = rowSize * 2 * rows - rowSize;
  const totalHeight = mediaSize > rowsHeight ? mediaSize : rowsHeight;
  const mediaElemSize = mediaPos !== "none" ? mediaSize || totalHeight : 0;
  const rowsPosX = isMediaLeft ? mediaElemSize + rowSize : 0;
  const mediaPosX = isMediaLeft ? 0 : size - mediaElemSize;
  const rowsWidth = mediaElemSize ? size - mediaElemSize - rowSize : size;
  const marginBottom = gutterBottom; // || rowSize * 2;

  const renderMedia = () => (
    <rect x={mediaPosX} y={0} width={mediaElemSize} height={mediaElemSize} />
  );

  const renderRows = () => {
    const rowsGroup = [];

    for (let i = 0; i < rows; i += 1) {
      rowsGroup.push(
        <rect
          key={i}
          height={rowSize}
          width={rowsWidth}
          x={rowsPosX}
          y={rowSize * i * 1.6}
          cx={5}
        />
      );
    }

    return rowsGroup;
  };

  return (
    <ContentLoader
      className={classes.root}
      height={totalHeight}
      viewBox={viewBox}
      width={size}
      style={{ borderRadius, marginBottom, width }}
      {...rest}
    >
      {mediaPos === "left" && renderMedia()}
      {renderRows()}
      {mediaPos === "right" && renderMedia()}
    </ContentLoader>
  );
}

Placeholder.defaultProps = {
  borderRadius: 0,
  classes: {},
  gutterBottom: 0,
  mediaPos: "none",
  mediaSize: null,
  rows: 1,
  rowSize: 8,
  size: 400,
  viewBox: null,
  width: null,
};

Placeholder.propTypes = {
  borderRadius: PropTypes.number,
  classes: PropTypes.object,
  gutterBottom: PropTypes.number,
  mediaPos: PropTypes.string,
  mediaSize: PropTypes.number,
  rows: PropTypes.number,
  rowSize: PropTypes.number,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  viewBox: PropTypes.string,
  width: PropTypes.number,
};

export default withStyles(styles)(Placeholder);
