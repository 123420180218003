const EmailLight = () => (
  <>
    <g id="Email">
      <path d="M100,200A100,100,0,1,1,200,100,100.1,100.1,0,0,1,100,200ZM100,5a95,95,0,1,0,95,95A95.08,95.08,0,0,0,100,5Z" />
      <path d="M66.72,82.94q1.56,1.11,9.41,6.55t12,8.38l2,1.4c1,.72,1.82,1.3,2.48,1.74S94,102,95,102.5a13.63,13.63,0,0,0,2.64,1.23,6.88,6.88,0,0,0,2.29.42H100a7.15,7.15,0,0,0,2.29-.42,14,14,0,0,0,2.64-1.23c.94-.55,1.72-1,2.39-1.49s1.48-1,2.48-1.74l2-1.4,21.49-14.93a21.6,21.6,0,0,0,5.59-5.65,12.11,12.11,0,0,0,2.25-6.92,7,7,0,0,0-2.18-5.19A7.08,7.08,0,0,0,133.74,63H66.16a6.53,6.53,0,0,0-5.44,2.39,9.26,9.26,0,0,0-1.91,6,10.74,10.74,0,0,0,2.52,6.27A23.13,23.13,0,0,0,66.72,82.94Z" />
      <path d="M136.51,87.85q-15.06,10.2-22.87,15.84-2.61,1.94-4.25,3a26.05,26.05,0,0,1-4.33,2.2A13.06,13.06,0,0,1,100,110h-.09a13.28,13.28,0,0,1-5-1.12,26.05,26.05,0,0,1-4.33-2.2c-1.09-.72-2.51-1.73-4.25-3q-6.21-4.55-22.82-15.84a24.84,24.84,0,0,1-4.64-4v36.45a7.36,7.36,0,0,0,7.34,7.34h67.58a7.37,7.37,0,0,0,7.34-7.34V83.86A24.29,24.29,0,0,1,136.51,87.85Z" />
    </g>
  </>
);

export default EmailLight;
