import theme from "@/lib/theme";
import { buildUrl } from "@/lib/useCloudinary";

// convert mediaquery to inline size query
const getMediaType = (media = "") => media.replace("@media ", "");

// convert images props to plain src path
const convertPropsToSrcset = (cloudId, transformations) => {
  const cloudSrc = buildUrl(cloudId, transformations);
  return `${cloudSrc} ${transformations?.width}w`;
};

// create the image cloud srcset property from a sizes onject
export const generateImageSrcsetProperty = (
  cloudId,
  propSizes,
  imgTransformations
) => {
  const sizesKeys = Object.keys(propSizes);

  const generateSrcset = (acc, breakpoint) => {
    const transformations = { ...imgTransformations, ...propSizes[breakpoint] };
    const selector = convertPropsToSrcset(cloudId, transformations);
    const sizes = acc ? `${acc},` : "";

    return `${sizes}${selector}`;
  };

  return sizesKeys.reduce(generateSrcset, "");
};

// convert images props to plain mediaquery
const convertPropsToSelector = (breakpoint, value) => {
  const mediaquery = breakpoint
    ? getMediaType(theme.breakpoints.up(breakpoint))
    : "";
  return `${mediaquery} ${value || 0}px`;
};

// creates the image sizes string property from a sizes object
export const generateImageSizesProperty = (propSizes = {}) => {
  const sizesKeys = Object.keys(propSizes);

  const generateSize = (acc, breakpoint, i) => {
    const propSizesKeysCounter = sizesKeys.length - 1;
    const sizesBreakPoint = i !== propSizesKeysCounter ? breakpoint : "";
    const selector = convertPropsToSelector(
      sizesBreakPoint,
      propSizes[breakpoint]?.width
    );
    const sizes = acc ? `${acc},` : "";
    return `${sizes}${selector}`;
  };

  return sizesKeys.reduce(generateSize, "");
};
