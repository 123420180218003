import { useState } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "../Button";
import Grid from "../Grid";
import List from "../List";

import styles from "./styles";

// TODO: real fetch instead of show
const ListLoadMore = ({ classes, items, variant, visible, ...props }) => {
  const [loaded, loadMore] = useState(visible);
  const allShown = items.length <= loaded;
  const listClases = {
    root: classes.list,
    listItem: classes.listItem,
    listItemText: classes.listItemText,
    title: classes.listTitle,
    subTitle: classes.listSubTitle,
    listItemIcon: classes.listItemIcon,
  };

  return (
    <Grid className={`${classes.root} ${classes[variant]}`} container>
      <Grid className={classes.row} xs={12} item>
        <List
          classes={listClases}
          items={items.slice(0, loaded)}
          variant={variant}
          {...props}
        />
      </Grid>
      {!allShown && (
        <Grid className={classes.row} xs={12} item>
          <Button
            className={classes.showMore}
            color="secondary"
            onClick={() => loadMore(loaded + visible)}
            variant="contained"
          >
            Show More
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

ListLoadMore.defaultProps = {
  classes: {},
  variant: "default",
  visible: 3,
};

ListLoadMore.propTypes = {
  classes: PropTypes.object,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  variant: PropTypes.string,
  visible: PropTypes.number,
};

export default withStyles(styles)(ListLoadMore);
