import useStyles from "./styles";
import { staticUrl } from "@/lib/utils";

import { Image } from "@/ui";

const aiBotUrl = staticUrl(require("@/static/img/ai-bot.svg"));

const AiBot = ({
  className = "",
  classes: rootClasses,
  width = 40,
  height = 60,
}) => {
  const classes = useStyles({ classes: rootClasses });

  return (
    <span className={`${className} ${classes.root}`}>
      <Image
        width={width}
        height={height}
        src={aiBotUrl}
        alt="AtlasGuru AiBot"
        className={classes.img}
      />
    </span>
  );
};

export default AiBot;
