import { staticUrl } from "@/lib/utils";
import { DEFAULT_TRANSFORMATIONS } from "@/lib/constants";
import { buildUrl } from "@/lib/useCloudinary";

import {
  generateImageSrcsetProperty,
  generateImageSizesProperty,
} from "./utils";

import useStyles from "./styles";

// Cloudinary url image structure
// https://res.cloudinary.com
// /<cloud_name>
// /<resource_type>
// /<type>
// /<transformations>
// /<version>
// /<public_id>
// .<format>

// Responsive Image Example on AtlasGuru
// Reference: https://atlasguru.atlassian.net/wiki/spaces/AWP/pages/1465745409/Responsive+Images
// <Image
//   sizes="(max-width: 679px) 664px, (max-width: 960px) 896px, 990px" // xs, md, lg
//   srcSet={`
//     http://fpoimg.com/664x373?text=xs&bg_color=ff0000 664w,
//     http://fpoimg.com/896x504?text=md&bg_color=00ff00 896w,
//     http://fpoimg.com/990x557?text=lg&bg_color=0000ff 990w,
//   `}
//   src="http://fpoimg.com/664x373?text=fallback&bg_color=000000"
//   alt="Responsive Implementation Example"
// />

const Image = ({
  className = "",
  cloudId = "",
  height = null,
  src = "",
  transformations = {},
  postTransformations = {},
  width = null,
  loading = "lazy",
  decoding = "async",
  sizes = [],
  alt = "",
  ...props
}) => {
  const classes = useStyles();
  const mainTransformations = {
    ...DEFAULT_TRANSFORMATIONS,
    crop: "fill",
    height,
    width,
    ...transformations,
  };
  const imgSrc = cloudId
    ? buildUrl(cloudId, mainTransformations, postTransformations)
    : staticUrl(src);
  const isResponsiveImage = Object.keys(sizes).length > 0;
  const responsiveProps = {};

  if (isResponsiveImage) {
    responsiveProps.sizes = generateImageSizesProperty(sizes);
    responsiveProps.srcSet = generateImageSrcsetProperty(
      cloudId,
      sizes,
      mainTransformations
    );
  }

  return (
    <img
      className={`${classes.root} ${className}`}
      src={imgSrc}
      height={height || transformations.height}
      width={width || transformations.width}
      decoding={decoding}
      loading={loading}
      alt={alt}
      {...responsiveProps}
      {...props}
    />
  );
};

// Image.propTypes = {
//   alt: PropTypes.string.isRequired,
//   className: PropTypes.string,
//   cloudId: PropTypes.string,
//   height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
//   src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
//   transformations: PropTypes.object,
//   width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
// };

export default Image;
